import { VENDOR_ONBOARD_REST_URLS } from '../../common-utilities/utils/end-points/vendor-onboard';
import { HTTP_METHODS, invokeApi } from './http-service';
import { clearOfflineData, setOfflineData } from './offline-services';
import { INTRANETHOSTNAME } from '../../common-utilities/utils/end-points';
import { getOfflineData } from './offline-services';
export const redirectToIntranetPortal = () => {
  clearOfflineData('user');
  clearOfflineData('tokens');
  setTimeout(() => {
    window.location.href = '/';
  }, 200);
};

export const getUserDetailsFromIntranet = (
  Authorization,
  customLogic,
  tokens,
  params
) => {
  invokeApi(
    HTTP_METHODS.POST,
    `${INTRANETHOSTNAME}${VENDOR_ONBOARD_REST_URLS.INTRANET_LOGIN}`,
    null,
    params,
    Authorization
  )
    .then((res) => {
      if (res?.message) {
        redirectToIntranetPortal();
        return;
      }
      if (tokens) {
        setOfflineData('tokens', tokens);
      }
      const role = res?.role?.toUpperCase();
      setOfflineData('user', { ...res, role });
      if (typeof customLogic === 'function') {
        customLogic(res);
      }
    })
    .catch(() => {
      redirectToIntranetPortal();
    });
};

export const getFileNameType = (url) => {
  if (url) {
    const urlSplits = url.split('/');
    if (urlSplits.length > 0) {
      const lastSplits = urlSplits[urlSplits.length - 1].split('.');
      return lastSplits[lastSplits.length - 1];
    }
  }

  return '';
};

export const getFileName = (url) => {
  if (url) {
    const urlSplits = url.split('/');
    if (urlSplits.length > 0) {
      return urlSplits[urlSplits.length - 1];
    }
  }

  return '';
};

export const isCreateAllowed = (type) => {
  const userDetails = getOfflineData('user') || {};
  const { userRights = {} } = userDetails;
  if (userRights[type]) {
    return userRights[type].create;
  }
  return false;
};

export const isUploadAllowed = (type) => {
  const userDetails = getOfflineData('user') || {};
  const { userRights = {} } = userDetails;
  if (userRights[type]) {
    return userRights[type].upload;
  }
  return false;
};

export const isDeleteAllowed = (type) => {
  const userDetails = getOfflineData('user') || {};
  const { userRights = {} } = userDetails;
  if (userRights?.[type]) {
    return userRights[type].delete;
  }
  return false;
};

export const isUpdateAllowed = (type) => {
  const userDetails = getOfflineData('user') || {};
  const { userRights = {} } = userDetails;
  if (userRights?.[type]) {
    return userRights[type].update;
  }
  return false;
};

export const isViewAllowed = (type) => {
  const userDetails = getOfflineData('user') || {};
  const { userRights = {} } = userDetails;
  if (userRights?.[type]) {
    return userRights[type].view;
  }
  return false;
};
