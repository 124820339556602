import { useEffect, useState } from 'react';
import { queryCfApprovalMappings } from './apis';
import { setProperty } from '../../../common-utilities/utils/misc';
import './index.scss';
import MuiTable from '../../../common-utilities/core/mui-table';
import { getHeaderConfig } from './config';
import { modalHandler } from './utils';
import EditCreateModal from './modals/edit-create';
import { modalConstants } from './config/constants';
import { Button } from '@mui/material';
import ConfirmModal from '../../../common-utilities/core/confirm-modal';
import { deleteMappingById } from './modals/apis';

const CfApprovalMapping = () => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: '-createdAt',
  });
  const [tableResults, setTableResults] = useState({});
  const [config, setConfig] = useState({
    modalDetails: null,
    refreshCategoryList: true,
  });
  const closeModal = () => {
    setProperty('modalDetails', null, setConfig);
  };
  useEffect(() => {
    queryCfApprovalMappings(filters, setTableResults);
  }, [filters]);
  const onModalSuccess = () => {
    closeModal();
    queryCfApprovalMappings(filters, setTableResults);
  };

  return (
    <div className="finance-cf-approval-mapping-container">
      <div className="end-btns">
        <Button
          onClick={() => {
            modalHandler(null, modalConstants.create, setConfig);
          }}
          variant="outlined"
        >
          Create Mapping
        </Button>
      </div>
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={tableResults?.results || []}
        pageCount={tableResults?.totalPages || 0}
        onChange={(page) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            page,
          }));
        }}
        filters={filters}
        onClick={(data, type) => {
          modalHandler(data, type, setConfig);
        }}
        nestedValue={true}
      />
      <EditCreateModal
        showModal={
          config?.modalDetails?.type === modalConstants.update ||
          config?.modalDetails?.type === modalConstants.create
        }
        onClose={closeModal}
        data={config?.modalDetails?.data}
        title={config?.modalDetails?.title}
        onSuccess={onModalSuccess}
      />
      {config?.modalDetails?.type === modalConstants.delete && (
        <ConfirmModal
          onYes={() => {
            deleteMappingById(
              config?.modalDetails?.data?.id || config?.modalDetails?.data?._id,
              onModalSuccess
            );
          }}
          onNo={closeModal}
        />
      )}
    </div>
  );
};

export default CfApprovalMapping;
