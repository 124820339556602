import { pick } from "../../../../common-utilities/utils";

export const cocoBuiltData = (results) => {
  return (
    results?.map?.((item) => {
      const mainKeys = pick(item, [
        "id",
        "storeType",
        "restaurantId",
        "state",
        "city",
        "cluster",
        "format",
      ]);

      const businessDetails = pick(item?.businessDetails || {}, [
        "openingDate",
        "location",
        "termOfLeaseYrs",
        "lockinPeriodYrs",
        "fitOutPeriod",
        "chargeableAreaSqft",
        "carpetAreaSqft",
        "noOfSeaters",
        "llInvestment",
        "franchiseFees",
        "lhiGc",
        "lhiCostPerSqFtInRs",
        "lhiBo",
        "decorRange",
        "fabrication",
        "boEquipments",
        "smallWare",
        "complianceHygiene",
        "posPrinterItPosist",
        "fitOutPeriodCostCam",
        "fitOutPeriodCostUtility",
        "freightCharges",
        "supplies",
        "licenseCost",
        "miscCost",
        "siteBrokerage",
        "openingKit",
        "designArchitectCost",
        "travelCostForSiteSelection",
        "lawyerFeesForDd",
        "stampDutyRegistration",
        "gensetDg",
        "servo",
        "preOpening",
        "totalInvtExclSd",
        "rSecDeposit",
        "totalInvtInclSd",
        "hardcost",
        "softcost",
        "mgYr1Permonth",
        "rsDineInYr1Percentage",
        "rsDelInYr1Percentage",
        "camYr1PmInRs",
        "otherPropertyChargesYr1Pm",
        "rSdInMonths",
        "nearestSubway1",
        "nearestSubway2",
        "yr1Adt",
        "yr1Apc",
        "yr1DelPercentage",
        "yr1AdsInRs",
        "monthSaleRs",
        "yearly1Revenue",
        "yearly1CostOfSales",
        "yearly1GrossProfit",
        "yearly1Royalty",
        "yearly1Occupancy",
        "yearly1Labour",
        "yearly1TechAllocation",
        "yearly1Utilities",
        "yearly1Commission",
        "yearly1Marketing",
        "yearly1OtherOpex",
        "yearly1StoreEbitda",
        "yearly1Depreciation",
        "yearly1StoreEbit",
        "rocePreTax",
        "paybackWithSdYears",
        "paybackWithSdYears",
        "paybackWithoutSdYears",
        "irr5Years",
        "monthlySalesYr1M1",
        "monthlySalesYr1M2",
        "monthlySalesYr1M3",
        "monthlySalesYr1M4",
        "monthlySalesYr1M5",
        "monthlySalesYr1M6",
        "monthlySalesYr1M7",
        "monthlySalesYr1M8",
        "monthlySalesYr1M9",
        "monthlySalesYr1M10",
        "monthlySalesYr1M11",
        "monthlySalesYr1M12",
        "yearlyAnnualSalesYr1",
        "monthlySalesYr2M1",
        "monthlySalesYr2M2",
        "monthlySalesYr2M3",
        "monthlySalesYr2M4",
        "monthlySalesYr2M5",
        "monthlySalesYr2M6",
        "monthlySalesYr2M7",
        "monthlySalesYr2M8",
        "monthlySalesYr2M9",
        "monthlySalesYr2M10",
        "monthlySalesYr2M11",
        "monthlySalesYr2M12",
        "yearlyAnnualSalesYr2",
        "yearlyAnnualSalesYr3",
        "yearlyAnnualSalesYr4",
        "yearlyAnnualSalesYr5",
        "yearlyAnnualSalesYr6",
        "yearlyAnnualSalesYr7",
        "yearlyAnnualSalesYr8",
        "yearlyAnnualSalesYr9",
        "yearlyAnnualSalesYr10",
        "monthlyEbitdaYr1M1",
        "monthlyEbitdaYr1M2",
        "monthlyEbitdaYr1M3",
        "monthlyEbitdaYr1M4",
        "monthlyEbitdaYr1M5",
        "monthlyEbitdaYr1M6",
        "monthlyEbitdaYr1M7",
        "monthlyEbitdaYr1M8",
        "monthlyEbitdaYr1M9",
        "monthlyEbitdaYr1M10",
        "monthlyEbitdaYr1M11",
        "monthlyEbitdaYr1M12",
        "yearlyEbitdaYr1",
        "monthlyEbitdaYr2M1",
        "monthlyEbitdaYr2M2",
        "monthlyEbitdaYr2M3",
        "monthlyEbitdaYr2M4",
        "monthlyEbitdaYr2M5",
        "monthlyEbitdaYr2M6",
        "monthlyEbitdaYr2M7",
        "monthlyEbitdaYr2M8",
        "monthlyEbitdaYr2M9",
        "monthlyEbitdaYr2M10",
        "monthlyEbitdaYr2M11",
        "monthlyEbitdaYr2M12",
        "yearlyEbitdaYr2",
        "yearlyEbitdaYr3",
        "yearlyEbitdaYr4",
        "yearlyEbitdaYr5",
        "yearlyEbitdaYr6",
        "yearlyEbitdaYr7",
        "yearlyEbitdaYr8",
        "yearlyEbitdaYr9",
        "yearlyEbitdaYr10",
      ]);

      return { ...mainKeys, ...businessDetails };
    }) || []
  );
};
