import CustomModal from "../../../common-utilities/core/modal";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { CF_APPROVAL_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { toast } from "react-toastify";

export const RejectApproval = ({ data, showModal, closeModal, onSuccess }) => {
  const rejectApproval = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${CF_APPROVAL_HOSTNAME}${REST_URLS.REJECT_APPROVAL}${data.id}`
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          return;
        }
        toast.success("Approval rejected successfully", { autoClose: 2000 });
        onSuccess && onSuccess();
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };
  return (
    <>
      {showModal && (
        <CustomModal title="Reject Approval" onClose={() => closeModal(null)}>
          <h6>Are you sure you want to reject approval?</h6>
          <Stack className="flexEnd" direction="row" spacing={2} sx={{ mt: 1 }}>
            <Button
              className="button-color"
              variant="outlined"
              onClick={() => closeModal(null)}
            >
              No
            </Button>
            <Button variant="contained" color="error" onClick={rejectApproval}>
              Yes
            </Button>
          </Stack>
        </CustomModal>
      )}
    </>
  );
};
