import React, { useState, useEffect } from "react";
import "./index.scss";
import { Button, Stack, Grid } from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AutoCompleteMultiSelect from "../../packages/common-utilities/core/autocomplete-multiselect";
import SaveIcon from "@mui/icons-material/Save";
const CreateApp = ({ closeModal, loadData }) => {
  const [appData, setAppData] = useState({
    name: "",
    link: "",
    roles: [],
  });
  const [roleList, setRoleList] = useState([]);
  const [roleValue, setRoleValue] = useState([]);
  const handleChange = (e) => {
    setAppData((prevVal) => ({ ...prevVal, [e.target.name]: e.target.value }));
  };
  const handleRoleChange = (event) => {
    const roleIdList = event.map((role) => role.value);
    setAppData((prevVal) => ({ ...prevVal, roles: roleIdList }));
    setRoleValue(event);
  };
  const getRoles = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_ROLES_AND_APPS}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const results = res.map((ele) => ({
            label: ele.name,
            value: ele._id,
          }));
          setRoleList(results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formSubmitHandler = () => {
    const payload = Object.fromEntries(
      Object.entries(appData).filter(([key, value]) => value)
    );
    invokeApi(
      HTTP_METHODS.POST,
      `${INTRANETHOSTNAME}${REST_URLS.CREATE_APP}`,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("New App Created Successfully", { autoClose: 2000 });
          loadData();
          closeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    getRoles();
  }, []);
  return (
    <div className="create-role-con">
      <div className="cancel-div">
        <h1>Create New App</h1>
        <CancelIcon
          color="error"
          onClick={() => closeModal(false)}
          sx={{ cursor: "pointer" }}
          fontSize="large"
        />
      </div>
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "space-between", mb: 2, mt: 2 }}
      >
        <Grid item md={5.8}>
          {" "}
          <TextField
            label="App Name"
            name="name"
            required
            className="new-textfield"
            fullWidth
            size="small"
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={5.8}>
          {" "}
          <TextField
            label="Link"
            name="link"
            required
            fullWidth
            className="new-textfield"
            size="small"
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item md={5.8}>
          <TextField
            label="Slug"
            name="slug"
            required
            className="new-textfield"
            fullWidth
            size="small"
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={5.8}>
          <AutoCompleteMultiSelect
            value={roleValue}
            onChange={(e, val) => {
              handleRoleChange(val);
            }}
            options={roleList}
            placeholder="Select Roles"
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={5} sx={{ my: 5 }}>
        <div className="common-btn-ctr">
          <div>
            <Button
              variant="contained"
              fullWidth
              startIcon={<SaveIcon />}
              className="common-modal-crt-btn"
              onClick={formSubmitHandler}
              disabled={
                !appData.name || appData.roles.length < 0 ? true : false
              }
            >
              Save
            </Button>
          </div>

          <div>
            <Button
              variant="outlined"
              className="common-modal-crt-btn"
              fullWidth
              color="error"
              onClick={() => closeModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default CreateApp;
