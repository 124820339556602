import { Button } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  convertToCamelCaseFromUnderScore,
  getUserRole,
  USER_ROLES,
  getValue,
} from "../../utils";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import { getOfflineData } from "../../utils/offline-services";
import moment from "moment";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

const CaseSummary = ({ caseDetails, onSuccess }) => {
  const {
    complaintType = {},
    fault = {},
    status = "",
    dc = {},
    assignedTo = {},
    vendor = {},
    adoptedBy = {},
    creditInfo = {},
    creditConfirmationByStore = false,
    mediation = {},
    isCaseGoneThroughMediation,
  } = caseDetails || {};

  const location = useLocation();
  const { products = [] } = complaintType || {};
  const roleDetail = getUserRole();
  const user = getOfflineData(null, "user");
  const navigate = useNavigate();
  const editCase = () => {
    navigate(`${location.pathname}/edit`);
  };

  const getAcceptedBy = () => {
    if (assignedTo?.id) {
      return `${assignedTo.name}${
        assignedTo.email ? ` - ${assignedTo.email}` : ""
      }`;
    }
    return "NA";
  };

  const getAdoptBy = () => {
    if (adoptedBy?.id) {
      return `${getValue(adoptedBy.name)} -${getValue(adoptedBy.email)}`;
    } else {
      return "NA";
    }
  };

  // const adoptCase = () => {
  //   invokeApi(
  //     HTTP_METHODS.POST,
  //     `${QNET_HOSTNAME}${REST_URLS.ADOPT_CASE}${caseDetails.id}`,
  //     {}
  //   ).then((response) => {
  //     if (response.status === "Done") {
  //       onSuccess();
  //     } else {
  //       toast.error("Adopt case is failed");
  //     }
  //   });
  // };

  const creditConfirmation = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${QNET_HOSTNAME}${REST_URLS.CREDIT_CONFIRMATION}${caseDetails.id}`,
      {}
    ).then((response) => {
      if (response.creditConfirmationByStore) {
        onSuccess();
        toast.info("Credit Confirmed");
      } else {
        toast.error("Credit Confirmation failed");
      }
    });
  };

  return (
    <>
      {(user?.role?.type === USER_ROLES.ADMIN ||
        user?.role?.type === USER_ROLES.RD) && (
        <div className="edit-button-container">
          <div>
            <Button
              disabled={status === "CLOSED"}
              variant="contained"
              onClick={editCase}
            >
              Edit
            </Button>
          </div>
        </div>
      )}

      <div className="case-summary-container">
        <div className="csc-l-s">
          <div className="s-title display-flex">
            <span>Summary</span>
            {/* {getAdoptBy() === "NA" && roleDetail.type == USER_ROLES.QA && (
              <Button
                variant="outlined"
                onClick={() => adoptCase()}
                startIcon={<VerifiedIcon />}
              >
                Adopt this case
              </Button>
            )} */}
          </div>
          <div className="info-container">
            <div className="info-row">
              <span>Restaurant number</span>
              <span>{caseDetails.store?.storeNumber}</span>
            </div>
            <div className="info-row">
              <span>Status</span>
              <span>
                {convertToCamelCaseFromUnderScore(caseDetails.status)}
              </span>
            </div>
            <div className="info-row">
              <span>Severity</span>
              <span>{caseDetails.severity}</span>
            </div>
            <div className="info-row">
              <span>Vendor</span>
              <span>{caseDetails?.vendor?.name}</span>
            </div>
            <div className="info-row">
              <span>Complaint Type</span>
              <span>{caseDetails?.complaintType?.name}</span>
            </div>
            <div className="info-row">
              <span>Complaint Notes</span>
              <span>{caseDetails?.complaintNotes}</span>
            </div>

            <div className="info-row">
              <span>Details</span>
              <span>{caseDetails?.details}</span>
            </div>
            {/* caseDetails */}

            <div className="info-row">
              <span>DC</span>
              <span>{caseDetails?.dc?.name}</span>
            </div>

            <div className="info-row">
              <span>Last Updated</span>
              <span>
                {(caseDetails.updatedAt &&
                  new Date(caseDetails.updatedAt).toLocaleString()) ||
                  ""}
              </span>
            </div>
            <div className="info-row">
              <span>Case Created</span>
              <span>
                {(caseDetails.createdAt &&
                  new Date(caseDetails.createdAt).toLocaleString()) ||
                  ""}
              </span>
            </div>
          </div>

          <div className="info-container">
            <div className="s-title">Restaurant Address</div>
            <div className="info-row">
              <span>Address 1</span>
              <span>{getValue(caseDetails?.store?.address)}</span>
            </div>
            <div className="info-row">
              <span>Address 2</span>
              <span>{getValue(caseDetails?.store?.address2)}</span>
            </div>
            <div className="info-row">
              <span>City</span>
              <span>{getValue(caseDetails?.store?.city)}</span>
            </div>
            <div className="info-row">
              <span>State</span>
              <span>{getValue(caseDetails?.store?.state)}</span>
            </div>
            <div className="info-row">
              <span>Country</span>
              <span>{getValue(caseDetails?.store?.country)}</span>
            </div>
          </div>

          <div className="info-container">
            <div className="s-title">Primary Contact</div>
            <div className="info-row">
              <span>Email</span>
              <span>{getValue(caseDetails?.store?.primaryContact?.email)}</span>
            </div>
            <div className="info-row">
              <span>Name</span>
              <span>{getValue(caseDetails?.store?.primaryContact?.name)}</span>
            </div>
            <div className="info-row">
              <span>Phone</span>
              <span>{getValue(caseDetails?.store?.primaryContact?.phone)}</span>
            </div>
          </div>

          <div className="info-container">
            <div className="s-title">Alternate Contact</div>
            <div className="info-row">
              <span>Email</span>
              <span>
                {getValue(caseDetails?.store?.alternateContact?.email)}
              </span>
            </div>
            <div className="info-row">
              <span>Name</span>
              <span>
                {getValue(caseDetails?.store?.alternateContact?.name)}
              </span>
            </div>
            <div className="info-row">
              <span>Phone</span>
              <span>
                {getValue(caseDetails?.store?.alternateContact?.phone)}
              </span>
            </div>
          </div>

          <div className="s-title">Case managers</div>
          <div className="info-container">
            <div className="info-row">
              <span>Assigned To</span>
              <span>{getAcceptedBy()}</span>
            </div>
            <div className="info-row">
              <span>Adopted By</span>
              <span>{getAdoptBy()}</span>
            </div>
          </div>
          <div className="s-title">Additional Information</div>
          <div className="info-container">
            {caseDetails?.caseDetails?.additionalInformation?.dcInvoice && (
              <div className="info-row">
                <span>DC Invoice</span>
                <span>
                  {caseDetails?.caseDetails?.additionalInformation?.dcInvoice ||
                    "NA"}
                </span>
              </div>
            )}
            {caseDetails?.caseDetails?.productInfo?.dc?.name && (
              <div className="info-row">
                <span>Distribution Center</span>
                <span>
                  {caseDetails?.caseDetails?.productInfo?.dc?.name || "NA"}
                </span>
              </div>
            )}
            {caseDetails?.caseDetails?.additionalInformation?.lotCode && (
              <div className="info-row">
                <span>Lot Code</span>
                <span>
                  {caseDetails?.caseDetails?.additionalInformation?.lotCode ||
                    "NA"}
                </span>
              </div>
            )}

            {caseDetails.type === "PRODUCT" && (
              <>
                {caseDetails?.caseDetails?.productInfo?.unitOfMeasure && (
                  <div className="info-row">
                    <span>Units of Measure</span>
                    <span>
                      {caseDetails?.caseDetails?.productInfo?.unitOfMeasure ||
                        "NA"}
                    </span>
                  </div>
                )}
                {caseDetails?.caseDetails?.productInfo?.unitOfMeasure && (
                  <div className="info-row">
                    <span>Units Affected</span>
                    <span>
                      {caseDetails?.caseDetails?.productInfo?.unitOfMeasure ||
                        "NA"}
                    </span>
                  </div>
                )}
              </>
            )}
            {caseDetails?.caseDetails?.additionalInformation && (
              <div className="info-row">
                <span>Delivery Date</span>
                <span>
                  {caseDetails?.caseDetails?.additionalInformation?.deliveryDate
                    ? moment(
                        caseDetails?.caseDetails?.additionalInformation
                          ?.deliveryDate
                      ).format("DD-MM-YYYY")
                    : "Not Available"}
                </span>
              </div>
            )}
            {caseDetails?.caseDetails?.additionalInformation && (
              <div className="info-row">
                <span>Expiration Date</span>
                <span>
                  {caseDetails?.caseDetails?.additionalInformation
                    ?.expirationDate
                    ? moment(
                        caseDetails?.caseDetails?.additionalInformation
                          ?.expirationDate
                      ).format("DD-MM-YYYY")
                    : "Not Available"}
                </span>
              </div>
            )}

            {caseDetails?.caseDetails?.additionalInformation?.packDate && (
              <div className="info-row">
                <span>Pack Date</span>
                <span>
                  {caseDetails?.caseDetails?.additionalInformation?.packDate
                    ? moment(
                        caseDetails?.caseDetails?.additionalInformation
                          ?.packDate
                      ).format("DD-MM-YYYY")
                    : "Not Available"}
                </span>
              </div>
            )}

            {caseDetails?.caseDetails?.additionalInformation
              ?.productionDate && (
              <div className="info-row">
                <span>Production Date</span>
                <span>
                  {caseDetails?.caseDetails?.additionalInformation
                    ?.productionDate
                    ? moment(
                        caseDetails?.caseDetails?.additionalInformation
                          ?.productionDate
                      ).format("DD-MM-YYYY")
                    : "Not Available"}
                </span>
              </div>
            )}
          </div>
          {caseDetails?.type === "PRODUCT" && (
            <>
              <div className="s-title">Product Information</div>
              <div className="info-container">
                {caseDetails?.productDetails?.name && (
                  <div className="info-row">
                    <span>Product Name</span>
                    <span>{caseDetails?.productDetails?.name || "NA"}</span>
                  </div>
                )}
                {caseDetails?.productDetails?.code && (
                  <div className="info-row">
                    <span>Product Code</span>
                    <span>{caseDetails?.productDetails?.code || "NA"}</span>
                  </div>
                )}

                {Number.isInteger(
                  parseInt(caseDetails?.caseDetails?.productInfo?.unitsAffected)
                ) && (
                  <div className="info-row">
                    <span>Units Affected</span>
                    <span>
                      {caseDetails?.caseDetails?.productInfo?.unitsAffected !==
                      0
                        ? caseDetails?.caseDetails?.productInfo?.unitsAffected
                        : "NA"}
                    </span>
                  </div>
                )}

                {caseDetails?.caseDetails?.productInfo?.unitOfMeasure && (
                  <div className="info-row">
                    <span>Units Affected</span>
                    <span>
                      {caseDetails?.caseDetails?.productInfo?.unitOfMeasure ||
                        "NA"}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="csc-r-s">
          <div className="s-title">Fault Details</div>
          <div className="info-container">
            <div className="info-row">
              <span>Cause</span>
              <span>{fault.cause || "NA"}</span>
            </div>
            <div className="info-row">
              <span>Corrective Action</span>
              <span>{fault.correctiveAction || "NA"}</span>
            </div>
            <div className="info-row">
              <span>Notes</span>
              <span>{fault.notes || "NA"}</span>
            </div>
            <div className="info-row">
              <span>Credit</span>
              <span>{fault.credit || "NA"}</span>
            </div>
            <div className="info-row">
              <span>Contacted Store</span>
              <span>
                {typeof fault?.conversationWithStore?.isContacted === "boolean"
                  ? fault?.conversationWithStore?.isContacted
                    ? "YES"
                    : "NO"
                  : " NA"}
              </span>
            </div>
            <div className="info-row">
              <span>Contacted Details</span>
              <span>{fault?.conversationWithStore?.contact || "NA"}</span>
            </div>
            {fault?.conversationWithStore?.isContacted && (
              <>
                <div className="info-row">
                  <span>Contacted Person</span>
                  <span>
                    {fault?.conversationWithStore?.contactedPerson || "NA"}
                  </span>
                </div>
                <div className="info-row">
                  <span>Contact Date</span>
                  <span>
                    {fault?.conversationWithStore?.contactDate || "NA"}
                  </span>
                </div>
              </>
            )}
          </div>
          {creditInfo.details && (
            <div>
              <div className="s-title">
                <span>Credit Info </span>

                {!creditConfirmationByStore &&
                  (status === "REVIEW_BY_ADMIN" ||
                    status === "CREDIT_ISSUED") &&
                  (roleDetail?.type == USER_ROLES.STORE_OWNER ||
                    roleDetail?.type == USER_ROLES.STORE) && (
                    <Button
                      variant="outlined"
                      onClick={() => creditConfirmation()}
                      startIcon={<VerifiedIcon />}
                    >
                      Confirm Credit
                    </Button>
                  )}
              </div>

              <div className="info-container">
                <div className="info-row">
                  <span>Invoice Number</span>
                  <span>{creditInfo?.details?.invoiceNumber || "NA"}</span>
                </div>

                <div className="info-row">
                  <span>Credit Note Number</span>
                  <span>{creditInfo?.details?.creditNoteNumber || "NA"}</span>
                </div>
                <div className="info-row">
                  <span>Credit Method</span>
                  <span>{creditInfo?.details?.creditMethod || "NA"}</span>
                </div>
                <div className="info-row">
                  <span>Credit Amount</span>
                  <span>{creditInfo?.details?.creditAmount || "NA"}</span>
                </div>
                <div className="info-row">
                  <span>Credit Currency</span>
                  <span>{creditInfo?.details?.creditCurrency || "NA"}</span>
                </div>

                <div className="info-row">
                  <span>Credit Notes</span>
                  <span>{creditInfo?.details?.creditNotes || "NA"}</span>
                </div>
              </div>
            </div>
          )}
          {isCaseGoneThroughMediation && (
            <>
              <div className="s-title">Mediation</div>
              <div className="info-container">
                <div className="info-row">
                  <span>Final Fault Determination</span>
                  <span>{mediation.finalFaultDetermination || "NA"}</span>
                </div>
                <div className="info-row">
                  <span>Final Credit Assignment</span>
                  <span>{mediation.finalCreditAssignment || "NA"}</span>
                </div>
                <div className="info-row">
                  <span>Mediation Notes</span>
                  <span>{mediation.mediationNotes || "NA"}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CaseSummary;
