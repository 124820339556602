import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getHeaderConfig } from "./config";
import { invokeApi } from "../../utils/http-service";
import { HTTP_METHODS } from "../../../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { toast } from "react-toastify";
import CustomModal from "../../../common-utilities/core/modal";
import { Button, Grid, TextField, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import { exportToExcel } from "../../../common-utilities/utils";

const InwardSales = () => {
  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    page: 1,
    limit: 10,
  });
  const [data, setData] = useState({});
  const [storeNo, setStoreNo] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [isCreateNew, setIsCreateNew] = useState(false);
  const [createData, setCreateData] = useState({});
  const [storeList, setStoreList] = useState([]);
  const [viewData, setViewData] = useState({
    isOpen: false,
    data: {},
  });
  const getData = (filters, isDownload = false) => {
    const payload = { ...filters };
    if (startDate && !filters?.startDate) {
      payload["startDate"] = startDate;
    }
    if (endDate && !filters?.endDate) {
      payload["endDate"] = endDate;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.GET_SALES_DATA}`,
      null,
      payload
    )
      .then((res) => {
        if (res.message) {
          toast.message(res.message);
        } else {
          if (isDownload) {
            const result = res?.results?.map((ele) => {
              return {
                "Store Number": ele?.storeNum,
                Date: ele?.date,
                "Vendor Id": ele?.vendorId,
                "Vendor Name": ele?.vendorName,
                "Vendor Country": ele?.vendorCountry,
                "Customer Type": ele?.customerType,
                "Hours Opened": ele?.hoursOpened,
                "Hours Worked": ele?.hoursWorked,
                Sales: ele?.sales,
                "Drink Sales": ele?.drinkSales,
                "Breakfast Sales": ele?.breakfastSales,
                "Party Sales": ele?.partySales,
                "School Lunch Sales": ele?.schoolLunchSales,
                "tc by Sales": ele?.tcbySales,
                "mmil Sales": ele?.mmilSales,
                "misc Sales": ele?.miscSales,
                "Drink Costs": ele?.drinkCosts,
                "cou Amount": ele?.couAmount,
                "cgs Amount": ele?.cgsAmount,
                "Number Of Foot longs": ele?.numberOfFootlongs,
                "Number Of Six Inch": ele?.numberOfSixInch,
                "Number Of Four Inch": ele?.numberOfFourInch,
                "Number Of Salad Plates": ele?.numberOfSaladPlates,
                "Number Of Drinks": ele?.numberOfDrinks,
                "Number Of Giant Subs": ele?.numberOfGiantSubs,
                "Number Of Platters": ele?.numberOfPlatters,
                "Number Of Party Breads": ele?.numberOfPartyBreads,
                "Number Of Free Units": ele?.numberOfFreeUnits,
                "Number Of Transactions": ele?.numberOfTransactions,
              };
            });
            exportToExcel(result || [], "Inward Sales");
          } else {
            setData(res);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const viewDetails = (data, type) => {
    if (type === "view") {
      setViewData({
        isOpen: true,
        data: data,
      });
    }
  };
  const getStoreList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.GET_STORE_LIST}`
    )
      .then((res) => {
        setStoreList(res);
      })
      .catch((err) => console.log(err));
  };
  const searchHandler = () => {
    let filter = { ...filters, page: 1 };
    setFilters((prevVal) => ({ ...prevVal, page: 1 }));
    if (storeNo) {
      filter["storeNum"] = storeNo;
    }
    if (startDate) {
      filter["startDate"] = startDate;
    }
    if (endDate) {
      filter["endDate"] = endDate;
    }
    getData(filter);
  };
  const downloadReport = () => {
    let filter = { ...filters, page: 1, limit: data.totalResults };
    if (storeNo) {
      filter["storeNum"] = storeNo;
    }
    if (startDate) {
      filter["startDate"] = startDate;
    }
    if (endDate) {
      filter["endDate"] = endDate;
    }
    const today = new Date();
    if (!startDate && !endDate && !storeNo) {
      const lastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      filter["startDate"] = lastMonth.toISOString().split("T")[0];
      filter["endDate"] = today.toISOString().split("T")[0];
    }
    getData(filter, true);
  };
  const submitHandler = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.CREATE_EXCEPTION}`,
      null,
      createData
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Exception Created Successfully", { autoClose: 2000 });
          setIsCreateNew(false);
          setCreateData({});
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData(filters);
    getStoreList();
  }, []);
  return (
    <div>
      <Grid
        container
        md={12}
        spacing={2}
        sx={{ display: "flex", alignItems: "center", mt: 1 }}
      >
        <Grid item md={2}>
          <Autocomplete
            options={storeList}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Store No." />
            )}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setStoreNo(newInputValue);
              }
            }}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ my: 1 }}
            label="Start Date"
            onChange={(e) => setStartDate(e?.target?.value)}
            value={startDate}
            size="small"
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ my: 1 }}
            label="End Date"
            onChange={(e) => setEndDate(e?.target?.value)}
            value={endDate}
            size="small"
          />
        </Grid>
        <Grid item md={1}>
          <Button
            variant="outlined"
            onClick={searchHandler}
            startIcon={<SearchIcon />}
            sx={{ borderRadius: 3 }}
          >
            Search
          </Button>
        </Grid>
        <Grid
          item
          md={4.5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={() => setIsCreateNew(true)}
            sx={{ borderRadius: 3 }}
          >
            Create Exception
          </Button>
        </Grid>
        {data?.results?.length > 0 && (
          <Grid item md={0.5}>
            <DownloadBtn className="mb-5" onClick={downloadReport} />
          </Grid>
        )}
      </Grid>
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={data.results || []}
        filters={filters}
        pageCount={data.totalPages}
        onClick={viewDetails}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          getData({
            ...filters,
            page,
          });
        }}
      ></MuiTable>
      {viewData.isOpen && (
        <CustomModal
          title="View Details"
          onClose={() => setViewData({ isOpen: false, data: {} })}
        >
          <tr className="modal-table-data">
            <td>Hours Opened</td>
            <td>{viewData.data.hoursOpened}h</td>
          </tr>
          <tr className="modal-table-data">
            <td>Hours Worked</td>
            <td>{viewData.data.hoursWorked}h</td>
          </tr>
          <tr className="modal-table-data">
            <td>Breakfast Sales</td>
            <td>{viewData.data.breakfastSales}</td>
          </tr>
          <tr className="modal-table-data">
            <td>School Lunch Sales</td>
            <td>{viewData.data.schoolLunchSales}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Number Of Giant Subs</td>
            <td>{viewData.data.numberOfGiantSubs}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Number Of Platters</td>
            <td>{viewData.data.numberOfPlatters}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Number Of Party Breads</td>
            <td>{viewData.data.numberOfPartyBreads}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Number Of Free Units</td>
            <td>{viewData.data.numberOfFreeUnits}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Drink Sales</td>
            <td>{viewData.data.drinkSales}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Party Sales</td>
            <td>{viewData.data.partySales}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Misc Sales</td>
            <td>{viewData.data.miscSales}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Number Of Foot longs</td>
            <td>{viewData.data.numberOfFootlongs}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Number Of Salad Plates</td>
            <td>{viewData.data.numberOfSaladPlates}</td>
          </tr>
          <tr className="modal-table-data">
            <td>Number Of Drinks</td>
            <td>{viewData.data.numberOfDrinks}</td>
          </tr>
        </CustomModal>
      )}
      {isCreateNew && (
        <CustomModal
          title="Create Exception"
          onClose={() => {
            setIsCreateNew(false);
            setCreateData({});
          }}
        >
          <TextField
            fullWidth
            sx={{ my: 1 }}
            label="Store No."
            onChange={(e) =>
              setCreateData((prevVal) => ({
                ...prevVal,
                storeNum: e?.target?.value,
              }))
            }
            value={createData?.storeNum || ""}
            size="small"
          />
          <TextField
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ my: 1 }}
            label="Date"
            onChange={(e) =>
              setCreateData((prevVal) => ({
                ...prevVal,
                date: e?.target?.value,
              }))
            }
            value={createData?.date || ""}
            size="small"
          />
          <Grid item md={12} sx={{ mb: 3 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={submitHandler}
              sx={{ float: "right", mt: 1 }}
              disabled={!createData?.storeNum || !createData?.date}
            >
              Save
            </Button>
          </Grid>
        </CustomModal>
      )}
    </div>
  );
};

export default InwardSales;
