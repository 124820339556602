import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Autocomplete, Button, TextField } from "@mui/material";
import CustomModal from "../../../../common-utilities/core/modal";
import "./index.scss";
import { exportToExcel } from "../../../../common-utilities/utils";
import { toast } from "react-toastify";
import { isCreateAllowed } from "../../../utils/common-utils";
import ConfirmModal from "../../../../common-utilities/core/confirm-modal";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../common-components/upload-template";
import { uploadTemplates } from "../constants";
import {
  defaultLists,
  formatDownloadReports,
  getCreatedUpdatePayload,
  locationTypes,
  statusTypes,
} from "./utils";
import { getCityDropdownList } from "../../../../common-utilities/utils/region-utils";
import { setProperty } from "../../../../common-utilities/utils/misc";

const Locations = () => {
  const [budgetModal, setBudgetModal] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [subcategoryList, setSubcategoryList] = useState({});
  const [lists, setLists] = useState(defaultLists);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (filters) => {
    let params = {
      ...filters,
    };
    if (searchKey) {
      params.searchKey = searchKey;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (params?.page > response?.totalPages) {
            setProperty("page", response?.totalPages, setFilters);
          }
          setSubcategoryList(response || {});
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}`,
      formDetails
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        if (response.code) {
          toast.error("Failed to create");
        } else {
          setBudgetModal(false);
          setLists(defaultLists);
          loadData(filters);
          setFormDetails({});
        }
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    let payload = getCreatedUpdatePayload(formDetails);
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        setLists(defaultLists);
        setBudgetModal(false);
        loadData(filters);
        setFormDetails({});
      })
      .catch((err) => {});
  };

  const editRecord = (data) => {
    setBudgetModal(true);
    if (data?.state) {
      const state = lists.stateList.find((item) => item?.value === data?.state);
      if (!state) {
        setProperty(
          "stateList",
          [
            ...lists.stateList,
            {
              label: data?.state,
              value: data?.state,
            },
          ],
          setLists
        );
      }
    }

    if (data?.city) {
      const citiList = getCityDropdownList("India", data?.state);
      const city = citiList.find((item) => item?.value === data?.city);
      if (!city) {
        citiList.push({
          label: data?.city,
          value: data?.city,
        });
      }
      setProperty("cityList", citiList, setLists);
    }

    setFormDetails({ ...data });
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}`,
      null,
      { page: 1, limit: subcategoryList.totalResults || 1000 }
    )
      .then((response) => {
        if (response.message) {
        } else {
          exportToExcel(formatDownloadReports(response.results), "site-list");
        }
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.LOCATIONS}/${deleteDetails.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        loadData(filters);
        setDeleteDetails(null);
      })
      .catch((err) => {});
  };

  return (
    <div className="company-list">
      <div className="cl-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                page: 1,
                limit: 10,
              })
            }
          >
            Search
          </Button>
        </div>
        <div className="left-container">
          {isCreateAllowed("master_data") && (
            <>
              <FileUpload
                buttonBackground="#fff"
                buttonColor="#1756f3"
                mainButtonClass="subway-finance-bulk-upload"
                borderColor="rgba(0, 138, 82, 0.5)"
                uploadText="BULK UPLOAD"
                clsName="finance-master-data-uploader"
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}locations`}
                callBack={() =>
                  loadData({
                    page: filters.page,
                    limit: 10,
                  })
                }
                formDataKey="file"
              />
              <DownloadTemplateButton type={uploadTemplates.location} />
            </>
          )}

          <Button
            variant="outlined"
            disabled={!subcategoryList.totalResults}
            onClick={downloadData}
          >
            Download
          </Button>
          {isCreateAllowed("master_data") && (
            <Button
              variant="outlined"
              onClick={() => {
                setFormDetails({});
                setBudgetModal(true);
              }}
            >
              Create
            </Button>
          )}
        </div>
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editRecord, setDeleteDetails)}
        dataList={subcategoryList.results || []}
        filters={filters}
        pageCount={subcategoryList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />

      {budgetModal && (
        <CustomModal
          title={formDetails.id ? "Update Location " : "Create  Location"}
          disabledOutsideClick={true}
          onClose={() => {
            setLists(defaultLists);
            setBudgetModal(false);
          }}
        >
          <div className="create-form">
            <TextField
              value={formDetails.name}
              name="name"
              onChange={onInputChange}
              size="small"
              label="Location Name"
              fullWidth
            />

            <Autocomplete
              options={statusTypes}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Status" />
              )}
              value={
                statusTypes.find(
                  (item) => item.value === formDetails?.status
                ) || null
              }
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  status: val?.value || null,
                });
              }}
            />

            <Autocomplete
              options={locationTypes}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Location Type" />
              )}
              value={
                locationTypes.find(
                  (item) => item.value === formDetails?.locationType
                ) || null
              }
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  locationType: val?.value || null,
                });
              }}
            />

            <TextField
              value={formDetails.address}
              name="address"
              onChange={onInputChange}
              size="small"
              label="Location Address"
              fullWidth
            />

            <TextField
              value={formDetails?.code || ""}
              name="code"
              onChange={onInputChange}
              size="small"
              label="Location Code"
              fullWidth
            />

            <TextField
              value={formDetails?.gstNumber || ""}
              name="gstNumber"
              onChange={onInputChange}
              size="small"
              label="GSTIN"
              fullWidth
            />

            <Autocomplete
              options={lists.stateList}
              renderInput={(params) => (
                <TextField {...params} size="small" label="State" />
              )}
              value={
                lists.stateList.find(
                  (item) => item.value === formDetails?.state
                ) || null
              }
              onChange={(e, val) => {
                const cityList = getCityDropdownList("India", val?.value);
                setProperty("cityList", cityList, setLists);
                setFormDetails({
                  ...formDetails,
                  state: val?.value || null,
                  city: null,
                });
              }}
            />

            <Autocomplete
              options={lists.cityList}
              renderInput={(params) => (
                <TextField {...params} size="small" label="City" />
              )}
              value={
                lists.cityList.find(
                  (item) => item.value === formDetails?.city
                ) || null
              }
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  city: val?.value || null,
                });
              }}
            />

            {formDetails.id ? (
              <Button fullWidth variant="contained" onClick={onUpdate}>
                Update
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={onSave}>
                Create
              </Button>
            )}
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal onYes={onDelete} onNo={() => setDeleteDetails(null)} />
      )}
    </div>
  );
};

export default Locations;
