/** @format */

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import "./index.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getOfflineData } from "../../utils/offline-services";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, qnetEndPoint, REST_URLS } from "../../utils/endpoints";
import { IconButton, TextField, TextareaAutosize, Button } from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CustomModal from "../../../common-utilities/core/modal";
import OtherDetails from "./other-details";
import TakeAction from "./take-action";
import {
  closeModal,
  convertToCamelCaseFromUnderScore,
  getUserRole,
  USER_ROLES,
} from "../../utils";
import { SEVERITY_OPTIONS } from "./helper";
import Select from "react-select";
import { toast } from "react-toastify";
import SubwayFileUpload from "../../../common-utilities/core/file-uploader/subway-file-upload";
import { appSlugs, tokenMainKeys } from "../../../../utils/constants";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

const ViewCase = () => {
  const { caseId } = useParams();
  const roleDetails = getUserRole() || {};
  const navigate = useNavigate();
  const [caseDetails, setCaseDetails] = useState({});
  const [comment, setComment] = useState("");
  const [severity, setSeverity] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [modalDetails, setModalDetails] = useState({
    isOpen: false,
    title: "",
  });

  const handleClick = (event) => {
    // setAnchorEl(anchorEl ? null : event.currentTarget);
    // const isCaseClosed = caseDetails?.status === "CLOSED";
    // if (isCaseClosed) {

    //   return;
    // }
    setModalDetails({
      isOpen: true,
      title: "Add Case Comment",
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const loadData = useCallback(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.FETCH_CASE}${caseId}`
    ).then((response) => {
      if (Number.isInteger(parseInt(response.id))) {
        setCaseDetails((prevDetails) => ({ ...prevDetails, ...response }));
      }
    });
  }, []);

  const loadCaseStatus = useCallback(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.CASE_STATUS}`
    ).then((response) => {
      if (response && Array.isArray(response)) {
        setStatusOptions(response);
      }
    });
  }, []);

  useEffect(() => {
    if (!getOfflineData(null, "user")) {
      navigate("/");
    } else {
      loadData();
      loadCaseStatus();
    }
  }, [loadData, navigate]);

  const addComment = () => {
    let payload = {
      caseId: caseId,
      comment: comment,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${QNET_HOSTNAME}${REST_URLS.ADD_COMMENT}`,
      payload
    ).then((response) => {
      if (response.message) {
        if (response.message === "Comment added") {
          toast.success("Comment Added Successfully", { autoClose: 2000 });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }
        loadData();
        setAnchorEl(null);
        closeModal(setModalDetails);
        setComment("");
      }
    });
  };

  const updateSeverity = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${QNET_HOSTNAME}${REST_URLS.CHANGE_SEVERITY}`,
      {
        caseId,
        severity: severity.value,
      }
    ).then((response) => {
      if (response.id) {
        setSeverity("");
        loadData();
      } else if (response.message) {
        toast.error(response.message);
      }
    });
  };

  return (
    <div className="view-case-container">
      <div className="vc-header">
        <div className="l-c">
          <IconButton onClick={() => navigate(`${qnetEndPoint}/cases`)}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
          <span className="label">Case {caseId}</span>
        </div>
        <div className="r-c">
          <Select
            options={SEVERITY_OPTIONS}
            placeHolder="Severity"
            isDisabled={caseDetails?.status === "CLOSED"}
            value={
              severity ||
              SEVERITY_OPTIONS.find((s) => s.value === caseDetails.severity)
            }
            onChange={(value) => setSeverity(value)}
          />
          {severity && (
            <>
              <IconButton
                disabled={!severity}
                color="primary"
                onClick={updateSeverity}
              >
                <CheckCircleOutlineIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  setSeverity("");
                }}
              >
                <HighlightOffIcon />
              </IconButton>{" "}
            </>
          )}

          <span className="info-label">
            Store No : {caseDetails?.store?.storeNumber}
          </span>
          <span className="info-label">Case Type : {caseDetails?.type}</span>
          <span className="info-label">
            Current Status :{" "}
            {convertToCamelCaseFromUnderScore(caseDetails?.status || "")}
          </span>
          <IconButton onClick={handleClick}>
            <AddCommentIcon color="primary" fontSize="large" />
          </IconButton>
        </div>
      </div>
      <div className="vc-body-container">
        <div>
          <OtherDetails caseDetails={caseDetails} onSuccess={loadData} />
        </div>
        {roleDetails?.type !== USER_ROLES.STORE_OWNER &&
          roleDetails?.type !== USER_ROLES.STORE &&
          caseDetails?.status !== "CLOSED" &&
          caseDetails && (
            <div>
              <TakeAction
                caseDetails={caseDetails}
                statusOptions={statusOptions}
                onSuccess={loadData}
              />
            </div>
          )}

        {(roleDetails?.type === USER_ROLES.STORE_OWNER ||
          (roleDetails?.type === USER_ROLES.STORE &&
            caseDetails.status !== "CLOSED")) && (
          <div className="take-action-container-for-store">
            <div className="take-action">Take Action</div>
            <div className="input-container">
              <SubwayFileUpload
                tokenKey={tokenMainKeys.qnet}
                slug={appSlugs.qnet}
                title="Attachment"
                url={`${QNET_HOSTNAME}${REST_URLS.UPLOAD}/${caseId}`}
                clsName="take-action-attachments"
                callBack={(newFile) => {
                  loadData();
                }}
              />
            </div>
          </div>
        )}
      </div>

      <Popper id={id} open={open} anchorEl={anchorEl} placement="left-start">
        <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
          <TextField
            label="Add Case Comment"
            size="small"
            className="mi-input"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <IconButton disabled={!comment} color="primary" onClick={addComment}>
            <CheckCircleOutlineIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              setAnchorEl(null);
              setComment("");
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Popper>
      {modalDetails.isOpen && (
        <CustomModal
          title={modalDetails.title}
          onClose={() => {
            closeModal(setModalDetails);
            setComment("");
          }}
        >
          <div className="close-case-modal">
            <TextareaAutosize
              minRows={6}
              size="small"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              // name="details"
              className="comment-text-area"
            />
            <div className="btn-container">
              <Button variant="outlined" onClick={addComment}>
                Add
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  closeModal(setModalDetails);
                  setComment("");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ViewCase;
