import React from "react";
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";
import { appSlugs, tokenMainKeys } from "../../../utils/constants";
import { getOfflineData } from "../../common-utilities/utils/offline-services";
import { useState, useEffect } from "react";
import { SideMenuConfig } from "../core/header/helper";
import { userStates } from "../../common-utilities/utils/constants";
import {
  checkAndGrantUserAccess,
  makeTokenObject,
} from "../../common-utilities/utils/common-utils";
import MainContainer from "../../common-utilities/container";
import { redirectToIntranetPortal, setUserToken } from "../utils/common-utils";

const PrivateRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(userStates.isProcessing);
  const access = searchParams.get("access");
  const refresh = searchParams.get("refresh");
  const accessTokenExpiry = searchParams.get("accessTokenExpiry");
  const refreshTokenExpiry = searchParams.get("refreshTokenExpiry");
  const navigate = useNavigate();
  const setUserRemoveParams = (userDetails) => {
    if (userDetails && !userDetails?.message) {
      setUser(userDetails);
    }
    setSearchParams({});
  };

  useEffect(() => {
    const areTokensPresent = !!access && !!refresh;
    const userDetails = getOfflineData(tokenMainKeys.qnet, "user");
    if (areTokensPresent) {
      const tokens = makeTokenObject(
        access,
        accessTokenExpiry,
        refresh,
        refreshTokenExpiry
      );
      checkAndGrantUserAccess(
        tokenMainKeys.qnet,
        appSlugs.qnet,
        tokens,
        setUserRemoveParams,
        setUserToken
      );
    } else if (userDetails) {
      setUser(userDetails);
    } else {
      redirectToIntranetPortal();
    }
  }, [access, refresh]);

  return user && user !== userStates.isProcessing ? (
    <MainContainer
      headerProps={{
        tokenKey: tokenMainKeys.qnet,
        sideMenuProps: { options: SideMenuConfig(navigate) },
      }}
    >
      <Outlet />
    </MainContainer>
  ) : (
    <></>
  );
};

export default PrivateRoute;
