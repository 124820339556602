import { isValidEmail } from "../../../common-utilities/utils";
import { VENDOR_DOCUMENT_TYPES } from "../vendor-request-details/helper";

export const PHONE_NO_REG_EX = /^\d{10}$/;
export const ALPHA_NUMERIC = /^[a-zA-Z0-9_.-]*$/;
export const PAN_CARD_REG_EX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}/;
export const isFormValid = (formDetails) => {
  const {
    nameOfLegalEntity,
    email,
    mobile = "",
    gstStatus = "",
    gst = "",
    pan = "",
    cancelledCheque = "",
    focalPointOfContact = "",
    ifsc = "",
    bankName = "",
    line = "",
    city = "",
    pincode = "",
    country = "",
    vendorTenure = "",
    panCard,
  } = formDetails;
  let error = {};

  if (!isValidEmail(email)) {
    error.email = true;
  }

  if (!mobile || !mobile.match(PHONE_NO_REG_EX)) {
    error.mobile = true;
  }

  if (!ifsc || !ifsc.match(ALPHA_NUMERIC) || ifsc?.length != 11) {
    error.ifsc = true;
  }

  if (!pincode || !pincode.match(ALPHA_NUMERIC)) {
    error.pincode = true;
  }

  if (!bankName) {
    error.bankName = true;
  }
  if (!vendorTenure) {
    error.vendorTenure = true;
  }
  if (!nameOfLegalEntity) {
    error.nameOfLegalEntity = true;
  }

  if (!line) {
    error.line = true;
  }

  if (!city || !city.match(ALPHA_NUMERIC)) {
    error.city = true;
  }

  if (!country || !country.match(ALPHA_NUMERIC)) {
    error.country = true;
  }

  if (gstStatus === "Yes" && gst.length !== 15) {
    error.gst = true;
  }
  if (!panCard) {
    error.panCard = true;
  }

  if (
    !pan ||
    !pan.match(ALPHA_NUMERIC) ||
    pan.length !== 10 ||
    !pan.match(PAN_CARD_REG_EX)
  ) {
    error.pan = true;
  }

  if (!focalPointOfContact) {
    error.focalPointOfContact = true;
  }

  if (!cancelledCheque) {
    error.cancelledCheque = true;
  }
  return error;
};

export const NATURE_OF_COMPLAINT = [
  {
    value: "Service",
    label: "Service",
  },
  {
    value: "Supplies",
    label: "Supplies",
  },
];

export const GST_STATUS = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const formatPayload = (formDetails) => {
  const {
    nameOfLegalEntity,
    email,
    mobile = "",
    alternativeMobile,
    gstStatus = "",
    gst,
    pan = "",
    cancelledCheque = "",
    focalPointOfContact = "",
    typeOfVendor,
    msme,
    msmeNumber,
    accountNumber,
    bankName = "",
    ifsc,
    line,
    street,
    city,
    country,
    pincode,
    state = {},
    panCard = "",
    msmeDocument = "",
    gstDocument = "",
    status,
    formId,
    msmeType,
    department = {},
    creditPeriod,
    attachment,
    vendorTenure,
  } = formDetails;
  const attachments = [];

  if (gstDocument) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.GST_DOC,
      url: gstDocument,
    });
  }

  if (panCard) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.PAN_CARD,
      url: panCard,
    });
  }

  if (msmeDocument) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.MSME_DOC,
      url: msmeDocument,
    });
  }
  if (attachment) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.ATTACHMENT,
      url: attachment,
    });
  }

  const payload = {
    email: email,
    bankDetails: {
      accountNumber: accountNumber,
      bankName: bankName,
      ifsc: ifsc,
    },
    address: {
      line: line,
      street: street,
      city: city,
      state: state.value,
      country: country,
      pincode: pincode,
    },
    nameOfLegalEntity: nameOfLegalEntity,
    pan: pan,
    focalPointOfContact: focalPointOfContact,
    mobile: mobile,
    gstStatus: gstStatus,
    gst: gstStatus === "Yes" ? gst : undefined,
    msme: msme,
    msmeNumber: msmeNumber,
    attachments: attachments,
    typeOfVendor: typeOfVendor,
    cancelledCheque: cancelledCheque.url,
    status,
    formId,
    msmeType,
    department: department.value,
    vendorTenure,
  };
  if (alternativeMobile) {
    payload.alternativeMobile = alternativeMobile;
  }

  if (typeof creditPeriod === "number" || creditPeriod) {
    payload.creditPeriod = creditPeriod;
  }

  return payload;
};

export const formateSubmittedDetails = (submittedDetails) => {
  const { cancelledCheque = "", attachments = [] } = submittedDetails;
  let details = {
    ...submittedDetails,
  };
  if (cancelledCheque) {
    details.cancelledCheque = {
      url: cancelledCheque,
      blobName: "Cancled Cheque",
    };
  }
  if (attachments.length > 0) {
    const gstDoc = attachments.find((a) => a.type === "GST_DOC");
    if (gstDoc) {
      details.gstDoc = {
        url: gstDoc.url,
        blobName: gstDoc.type,
      };
    }

    const panDoc = attachments.find((a) => a.type === "PAN_CARD");
    if (panDoc) {
      details.panDoc = {
        url: panDoc.url,
        blobName: panDoc.type,
      };
    }
  }

  return details;
};
