import { useState } from "react";
import CustomModal from "../../packages/common-utilities/core/modal";
import { Button, TextField, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { getOfflineData, setOfflineData } from "../../utils/offline-services";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ResetPasswordModal = ({
  showChangePasswordModal,
  setShowChangePasswordModal,
}) => {
  const location = useLocation();
  useEffect(() => {
    const user = getOfflineData("user");
    if (!user) {
      return;
    }
    // There are two cases for updating password
    // 1. When changePasswordOnFirstLogin is true
    // 2. When changePasswordOnFirstLogin is undefined, property is not present in the user data, since its newly added property in the model
    const hasToResetPassword =
      !!user?.changePasswordOnFirstLogin ||
      user?.changePasswordOnFirstLogin === undefined;
    if (hasToResetPassword) {
      setShowChangePasswordModal(true);
    }
  }, [location]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const setPasswordHandler = () => {
    const payload = {
      password: password,
      changePasswordOnFirstLogin: false,
    };
    if (!password) {
      toast.error("Please enter password", { autoClose: 2000 });
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password and confirm password do not match", {
        autoClose: 2000,
      });
    } else {
      const id = getOfflineData("user")?.id;
      invokeApi(
        HTTP_METHODS.PUT,
        `${INTRANETHOSTNAME}${REST_URLS.UPDATE_INTRANET_USER}${id}`,
        payload
      )
        .then((res) => {
          if (res?.message) {
            toast.error(res?.message, { autoClose: 2000 });
          }
          if (res.changePasswordOnFirstLogin === false) {
            toast.success("Password Set successfully", { autoClose: 2000 });
            setOfflineData("user", res);
            setShowChangePasswordModal(false);
          }

          setPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          toast.error(err, { autoClose: 2000 });
        });
      // setShowChangePasswordModal(false);
    }
  };
  return (
    <>
      {showChangePasswordModal && (
        <CustomModal
          doNotClose={true}
          title="Change Password"
          contentClassName={{ headerBackgroundColor: "#1756f3" }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="password"
              type="password"
              style={{ marginBottom: "2em" }}
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              variant="outlined"
              value={password}
              size="small"
            />
            <TextField
              fullWidth
              id="confirmPassword"
              style={{ marginBottom: "1em" }}
              label="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              variant="outlined"
              value={confirmPassword}
              type="password"
              size="small"
            />

            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={setPasswordHandler}
                fullWidth
              >
                Confirm
              </Button>
              {/* <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setShowChangePasswordModal(false);
                  const user = getOfflineData("user");
                  if (user) {
                    setOfflineData("user", { ...user, doNotShowDialog: true });
                  }
                }}
                fullWidth
              >
                Cancel
              </Button> */}
            </Stack>
          </Box>
        </CustomModal>
      )}
    </>
  );
};
