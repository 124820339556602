import React, { useState } from "react";
import {
  Button,
  TextareaAutosize,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Select from "react-select";
import SubwayFileUpload from "../../../common-utilities/core/file-uploader/subway-file-upload";
import { useNavigate } from "react-router-dom";
import {
  allCasesStatues,
  CASE_MAP_FILTER,
  getLabelsAndValuesForSelect,
  getUserRole,
  getValue,
  USER_ROLES,
} from "../../utils";
import { HOSTNAME, qnetEndPoint, REST_URLS } from "../../utils/endpoints";
import { useParams } from "react-router-dom";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import CustomRadioGroup from "../../../common-utilities/core/radio-group";
import {
  FAULT_RESPONSE_OPTIONS,
  formatFaultPayload,
  CREDIT_OPTIONS,
  isSaveDisabled,
  formatAssignToPayload,
  formatCreditInfo,
  REJECTED_TO,
  pendingCreditMethods,
  creditCurrency,
} from "./helper";
import { toast } from "react-toastify";
import CustomModal from "../../../common-utilities/core/modal";
import { useEffect } from "react";
import { ChangeVendorModal } from "./modal";
import { getOfflineData } from "../../utils/offline-services";
import { appSlugs, tokenMainKeys } from "../../../../utils/constants";
import { getSelectDcList } from "../../utils/dc";
import { getSelectVendorList } from "../../utils/vendor";
import { ContactedStoreOption } from "./common-components";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

const TakeAction = ({ caseDetails, onSuccess, statusOptions }) => {
  const navigate = useNavigate();
  const userIsVegetableVendor = getOfflineData(null, "user")?.isVegetableVendor;
  const { caseId } = useParams();
  const roleDetails = getUserRole();
  const [users, setUsers] = useState([]);
  const [rejectedAgainstList, setRejectedAgainstList] = useState([]);
  const [closeCase, setCloseCase] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [closeAgainstStore, setCloseAgainstStore] = useState(false);
  const [closingMessage, setClosingMessage] = useState("");
  const [formDetails, setFormDetails] = useState({});
  const [faultDeterminationList, setFaultDeterminationList] = useState([]);
  const [changeStatusDetails, setChangeStatusDetails] = useState({});
  const modalTypes = {
    changeVendor: "Change Vendor",
  };
  const [modalType, setModalType] = useState(false);

  const closeChangeStatusModal = () => {
    setChangeStatusDetails({});
    setChangeStatus(false);
  };
  const { status = "" } = caseDetails || {};

  useEffect(() => {
    if (!closeCase) {
      setCloseAgainstStore(false);
    }
  }, [closeCase]);

  const finalCreditAssignmentList = getLabelsAndValuesForSelect(
    ["DC", "None"],
    ["DC", "None"]
  );

  const saveNewCaseStatus = () => {
    invokeApi(HTTP_METHODS.POST, `${QNET_HOSTNAME}${REST_URLS.CHANGE_STATUS}`, {
      caseId,
      status: changeStatusDetails.status.bl,
    })
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          return;
        }
        onSuccess();
        closeChangeStatusModal();
        toast.success("Case Status updated successfully", { autoClose: 2000 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const { vendor, dc, store } = caseDetails;
    const labels = [];
    const values = [];
    if (store) {
      labels.push("Store");
      values.push("Store");
    }
    if (vendor) {
      labels.push(`Vendor - ${vendor?.name}`);
      values.push("Vendor");
    }
    if (dc) {
      labels.push(`DC - ${dc?.name}`);
      values.push("DC");
    }
    const finalFaultDeterminationList = getLabelsAndValuesForSelect(
      labels,
      values
    );
    setFaultDeterminationList(finalFaultDeterminationList);
  }, [caseDetails]);

  useEffect(() => {
    const { dc = {}, vendor = {} } = caseDetails || {};
    let options = [REJECTED_TO[0]];
    if (
      Number.isInteger(parseInt(vendor.id)) &&
      roleDetails.belongsTo !== USER_ROLES.VENDOR
    ) {
      REJECTED_TO[1].label = `${vendor.email} - ${vendor.name}`;
      options.push(REJECTED_TO[1]);
    }

    if (
      Number.isInteger(parseInt(dc.id)) &&
      roleDetails.belongsTo !== USER_ROLES.DC
    ) {
      REJECTED_TO[2].label = `${dc.email} - ${dc.name}`;
      options.push(REJECTED_TO[2]);
    }

    setRejectedAgainstList(options);
  }, [caseDetails]);

  const saveCaseDetails = (payload, curStatus) => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${QNET_HOSTNAME}${REST_URLS.UPDATE_CASE}${caseId}`,
      payload
    ).then((response) => {
      if (response.id) {
        onSuccess();
        setClosingMessage("");
        switch (curStatus) {
          case "PENDING_CREDIT":
            toast.success("Credit issued successfully", { autoClose: 2000 });
            navigate(`${qnetEndPoint}/cases`);
            break;
          case allCasesStatues.adminReview:
            toast.success("Case now in Admin Review", { autoClose: 2000 });
            break;
          case "PENDING_SUPPLIER_ASSIGNED":
            if (formDetails.type.bl === "ASSIGNED_TO_VENDOR") {
              toast.success("Case assigned to Vendor", { autoClose: 2000 });
            } else {
              toast.success("Case assigned to DC", { autoClose: 2000 });
            }
            break;
          case "CLOSED":
            toast.success("Case closed successfully", { autoClose: 2000 });
            break;
          default:
            break;
        }
      } else if (response.message) {
        toast.error(response.message);
        if (response?.message === "Invalid Request") {
          navigate(`${qnetEndPoint}/cases`);
        }
      }
    });
  };

  const handleMediation = () => {
    const { finalFaultDetermination, finalCreditAssignment, mediationNotes } =
      formDetails;
    invokeApi(
      HTTP_METHODS.POST,
      `${QNET_HOSTNAME}${REST_URLS.HANDLE_MEDIATION}`,
      {
        finalFaultDetermination: finalFaultDetermination?.value
          ? finalFaultDetermination?.value
          : finalFaultDetermination,
        finalCreditAssignment: finalCreditAssignment?.value
          ? finalCreditAssignment.value
          : finalCreditAssignment,
        mediationNotes,
        caseId: caseDetails.id,
      }
    ).then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      onSuccess();
      setClosingMessage("");
      toast.success("Case updated successfully", { autoClose: 2000 });
    });
  };

  const faultAction = (payload) => {
    const user = getOfflineData(null, "user");
    const isuserActionTaker = user?.role?.type === USER_ROLES.ACTION_TAKER;
    invokeApi(
      HTTP_METHODS.POST,
      `${QNET_HOSTNAME}${REST_URLS.FAULT_RESPONSE}`,
      payload
    ).then((response) => {
      if (response.id) {
        if (response.pendingResearch) {
          toast.success("Case now in pending reasearch", { autoClose: 2000 });
        } else if (formDetails.status === "FAULT_ACCEPTED") {
          toast.success("Fault Accepted successfully", { autoClose: 2000 });
          if (formDetails.credit?.value === "NONE" || isuserActionTaker) {
            navigate(`${qnetEndPoint}/cases`);
          }
        } else if (formDetails.status === "FAULT_REJECTED") {
          toast.success("Fault rejected successfully", { autoClose: 2000 });
          navigate(`${qnetEndPoint}/cases`);
        }
        onSuccess();
        setClosingMessage("");
        setFormDetails({});
      } else if (response.message) {
        toast.error(response.message);
        if (response?.message === "Case was assigned to another vendor") {
          navigate(`${qnetEndPoint}/cases`);
        }
        if (response?.message === "Invalid Request") {
          navigate(`${qnetEndPoint}/cases`);
        }
      }
    });
  };

  const getSaveButtonByStatus = () => {
    switch (status) {
      case "PENDING_SUPPLIER_ASSIGNED":
        if (
          roleDetails?.type !== USER_ROLES.ADMIN &&
          roleDetails?.type !== USER_ROLES.RD
        ) {
          return "";
        }
        return (
          <Button
            variant="contained"
            disabled={isSaveDisabled(status, formDetails, caseDetails)}
            onClick={() => {
              saveCaseDetails(
                formatAssignToPayload(formDetails),
                "PENDING_SUPPLIER_ASSIGNED"
              );
            }}
          >
            Save
          </Button>
        );
      case "ASSIGNED_TO_DC":
      case "ASSIGNED_TO_VENDOR":
        if (
          roleDetails?.type === USER_ROLES.ADMIN ||
          roleDetails?.type === USER_ROLES.RD
        ) {
          return "";
        }
        return (
          <Button
            variant="contained"
            disabled={isSaveDisabled(status, formDetails)}
            onClick={() =>
              faultAction(formatFaultPayload(formDetails, caseDetails))
            }
          >
            Save
          </Button>
        );

      case "PENDING_CREDIT":
        if (
          roleDetails?.type === USER_ROLES.ADMIN ||
          roleDetails?.type === USER_ROLES.RD ||
          (roleDetails?.belongsTo === USER_ROLES.VENDOR &&
            !userIsVegetableVendor)
        ) {
          return "";
        }
        return (
          <Button
            variant="contained"
            disabled={isSaveDisabled(status, formDetails)}
            onClick={() =>
              saveCaseDetails(
                formatCreditInfo(formDetails, caseDetails),
                "PENDING_CREDIT"
              )
            }
          >
            Submit Credit info
          </Button>
        );
      case allCasesStatues.mediation:
        if (
          roleDetails?.type !== USER_ROLES.ADMIN &&
          roleDetails?.type !== USER_ROLES.RD
        ) {
          return "";
        }
        return (
          <Button
            variant="contained"
            disabled={isSaveDisabled(status, formDetails)}
            onClick={() => {
              handleMediation();
            }}
          >
            Save
          </Button>
        );

      default:
        return null;
    }
  };

  //
  const loadAssignToData = async (details) => {
    if (details.bl === "ASSIGNED_TO_VENDOR" || details.value === "VENDOR") {
      const vendorList = await getSelectVendorList(null, "ul", "bl");
      setUsers(vendorList);
    }
    if (details.bl === "ASSIGNED_TO_DC") {
      const dcList = await getSelectDcList(null, "ul", "bl");
      setUsers(dcList);
    }
  };

  const getInputFormByStatus = () => {
    switch (status) {
      case "PENDING_SUPPLIER_ASSIGNED":
        if (
          roleDetails?.type !== USER_ROLES.ADMIN &&
          roleDetails?.type !== USER_ROLES.RD
        ) {
          return "";
        }
        return (
          <div>
            <div className="input-container">
              <Select
                options={statusOptions.filter((s) =>
                  (CASE_MAP_FILTER[caseDetails.status] || []).includes(s.bl)
                )}
                placeholder="Assign To"
                classNamePrefix="subway-select"
                getOptionLabel={(op) => op.ul}
                getOptionValue={(op) => op.bl}
                value={formDetails.type}
                onChange={(value) => {
                  loadAssignToData(value);
                  setFormDetails({
                    type: value,
                  });
                }}
              />
            </div>
            <div className="input-container">
              {((formDetails?.type?.bl === "ASSIGNED_TO_DC" &&
                !caseDetails.dc) ||
                (formDetails?.type?.bl === "ASSIGNED_TO_VENDOR" &&
                  !caseDetails.vendor)) && (
                <Select
                  options={users}
                  placeholder={
                    formDetails?.type?.bl === "ASSIGNED_TO_DC"
                      ? "Selected  DC "
                      : "Select vendor"
                  }
                  classNamePrefix="subway-select"
                  getOptionLabel={(op) =>
                    `${getValue(op.name)} - ${getValue(op.email)}`
                  }
                  getOptionValue={(op) => op.id}
                  value={formDetails.details || ""}
                  onChange={(value) => {
                    setFormDetails({
                      ...formDetails,
                      details: value,
                    });
                  }}
                />
              )}
            </div>
          </div>
        );
      case "ASSIGNED_TO_DC":
      case "ASSIGNED_TO_VENDOR":
        if (
          roleDetails.type === USER_ROLES.ADMIN ||
          roleDetails.type === USER_ROLES.RD
        ) {
          return "";
        }
        return (
          <div className="input-container">
            <CustomRadioGroup
              title="Fault Response"
              options={FAULT_RESPONSE_OPTIONS(caseDetails)}
              value={formDetails.status || ""}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  status: e.target.value,
                });
              }}
            />
            {formDetails.status === "FAULT_ACCEPTED" && (
              <>
                <TextField
                  label="Cause"
                  size="small"
                  className="mi-input"
                  value={formDetails.cause}
                  onChange={(e) => {
                    setFormDetails({
                      ...formDetails,
                      cause: e.target.value,
                    });
                  }}
                />
                <TextField
                  label="Corrective Action"
                  size="small"
                  className="mi-input"
                  value={formDetails.correctiveAction}
                  onChange={(e) => {
                    setFormDetails({
                      ...formDetails,
                      correctiveAction: e.target.value,
                    });
                  }}
                />
                <Select
                  placeholder="Credit"
                  options={CREDIT_OPTIONS}
                  value={formDetails.credit}
                  onChange={(value) => {
                    setFormDetails({
                      ...formDetails,
                      credit: value,
                    });
                  }}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 3 }),
                  }}
                />
                <ContactedStoreOption
                  formDetails={formDetails}
                  setFormDetails={setFormDetails}
                />
              </>
            )}

            {formDetails.status === "FAULT_REJECTED" && (
              <>
                <Select
                  options={rejectedAgainstList}
                  placeholder="Rejected To"
                  classNamePrefix="subway-select"
                  // getOptionLabel={(op) => `${op.name} - ${op.email}`}
                  // getOptionValue={(op) => op.id}
                  value={formDetails.rejectedAgainst || ""}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 3 }),
                  }}
                  onChange={(value) => {
                    setFormDetails({
                      ...formDetails,
                      rejectedAgainst: value,
                    });
                  }}
                />
                <ContactedStoreOption
                  formDetails={formDetails}
                  setFormDetails={setFormDetails}
                />
              </>
            )}

            <TextareaAutosize
              placeholder={
                formDetails.status === "PENDING_ADDITION_RESEARCH"
                  ? "Pending additional research notes"
                  : formDetails.status === "FAULT_REJECTED"
                  ? "Rejection Notes"
                  : formDetails.status === "FAULT_ACCEPTED"
                  ? "Faulty Notes"
                  : ""
              }
              minRows={6}
              value={formDetails.notes || ""}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  notes: e.target.value,
                });
              }}
            />
          </div>
        );
      case "PENDING_CREDIT":
        if (
          roleDetails?.type === USER_ROLES.ADMIN ||
          roleDetails?.type === USER_ROLES.RD ||
          (roleDetails?.belongsTo === USER_ROLES.VENDOR &&
            !userIsVegetableVendor)
        ) {
          return "";
        }

        return (
          <div className="input-container">
            <Select
              placeholder="Credit Method"
              options={pendingCreditMethods}
              value={formDetails.creditMethod}
              onChange={(value) => {
                setFormDetails({
                  ...formDetails,
                  creditMethod: value,
                });
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />

            <TextField
              size="small"
              type="date"
              label="Credit Notes Issuance Date"
              className="mi-input"
              value={formDetails.creditNotesIssuanceDate || null}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  creditNotesIssuanceDate: e.target.value,
                });
              }}
            />

            <TextField
              label="Invoice Number"
              size="small"
              className="mi-input"
              type="number"
              value={formDetails.invoiceNumber}
              onChange={(e) => {
                if (e?.target?.value?.length > 9) {
                  return;
                }
                setFormDetails({
                  ...formDetails,
                  invoiceNumber: e.target.value,
                });
              }}
            />

            <TextField
              label="Credit Note Number"
              size="small"
              type="number"
              className="mi-input"
              value={formDetails.creditNoteNumber || ""}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  creditNoteNumber: e.target.value,
                });
              }}
            />

            <TextField
              label="Credit Amount"
              type="number"
              size="small"
              className="mi-input"
              value={formDetails.creditAmount}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  creditAmount: e.target.value,
                });
              }}
            />

            <Select
              placeholder="Credit Currency"
              options={creditCurrency}
              value={formDetails.creditCurrency}
              onChange={(value) => {
                setFormDetails({
                  ...formDetails,
                  creditCurrency: value,
                });
              }}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />

            <TextareaAutosize
              minRows={4}
              placeholder="Credit Notes"
              size="small"
              className="mi-input"
              value={formDetails.creditNotes}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  creditNotes: e.target.value,
                });
              }}
            />
          </div>
        );

      case allCasesStatues.mediation:
        if (
          roleDetails?.type !== USER_ROLES.ADMIN &&
          roleDetails?.type !== USER_ROLES.RD
        ) {
          return "";
        }
        return (
          <div className="input-container">
            <Select
              options={faultDeterminationList}
              placeholder="Final Fault Determination"
              classNamePrefix="subway-select"
              value={formDetails.finalFaultDetermination}
              onChange={(value) => {
                setFormDetails({
                  ...formDetails,
                  finalFaultDetermination: value,
                });
              }}
            />
            <Select
              options={finalCreditAssignmentList}
              placeholder="Final Credit Assignment"
              classNamePrefix="subway-select"
              value={formDetails.finalCreditAssignment}
              onChange={(value) => {
                setFormDetails({
                  ...formDetails,
                  finalCreditAssignment: value,
                });
              }}
            />
            <TextareaAutosize
              minRows={4}
              placeholder="Mediation Notes"
              size="small"
              className="mi-input"
              value={formDetails.mediationNotes}
              onChange={(e) => {
                setFormDetails({
                  ...formDetails,
                  mediationNotes: e.target.value,
                });
              }}
            />
          </div>
        );
    }
  };

  const getCloseCaseInput = () => {
    return (
      <div className="close-case-container">
        {status !== "PENDING_SUPPLIER_ASSIGNED" && (
          <Button
            variant="contained"
            disabled={status === "CLOSED"}
            onClick={() => setChangeStatus(true)}
          >
            Change Status
          </Button>
        )}

        {/* <Button
          variant="contained"
          disabled={status === "CLOSED"}
          onClick={() => setModalType(modalTypes.changeVendor)}
        >
          Change Vendor
        </Button> */}

        <Button
          variant="contained"
          disabled={status === "CLOSED"}
          onClick={() => setCloseCase(true)}
        >
          Close Case
        </Button>
      </div>
    );
  };

  return (
    <div className="take-action-container">
      <div className="take-action">Take Action</div>
      {getInputFormByStatus()}

      <div className="input-container">
        {getSaveButtonByStatus()}
        {status !== "CLOSED" && (
          <SubwayFileUpload
            tokenKey={tokenMainKeys.qnet}
            slug={appSlugs.qnet}
            title="Attachment"
            url={`${QNET_HOSTNAME}${REST_URLS.UPLOAD}/${caseId}`}
            clsName="take-action-attachments"
            callBack={(newFile) => {
              onSuccess();
            }}
          />
        )}

        {(roleDetails?.type === USER_ROLES.ADMIN ||
          roleDetails?.type === USER_ROLES.RD) &&
          getCloseCaseInput()}
        {(roleDetails?.type === USER_ROLES.ADMIN ||
          roleDetails?.type === USER_ROLES.RD) &&
          status === allCasesStatues.creditIssed && (
            <div className="flexCenter">
              <Button
                variant="contained"
                onClick={() =>
                  saveCaseDetails(
                    { status: allCasesStatues.adminReview },
                    allCasesStatues.adminReview
                  )
                }
              >
                Review by Admin
              </Button>
            </div>
          )}
      </div>
      {closeCase && (
        <CustomModal
          onClose={() => {
            setCloseCase(false);
          }}
        >
          <div className="close-case-modal">
            <div>
              <strong>Are you sure?</strong>{" "}
            </div>
            <div>
              Do you want to close against store?
              <FormControlLabel
                value="start"
                checked={closeAgainstStore}
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => {
                      setCloseAgainstStore(!closeAgainstStore);
                    }}
                  />
                }
                label=""
                labelPlacement="start"
              />
            </div>

            <TextareaAutosize
              minRows={3}
              value={closingMessage}
              onChange={(e) => setClosingMessage(e.target.value)}
              placeholder="Closing message"
            ></TextareaAutosize>
            <div className="btn-container">
              <Button
                variant="outlined"
                disabled={!closingMessage?.trim()}
                onClick={() => {
                  setCloseCase(false);

                  saveCaseDetails(
                    {
                      status: "CLOSED",
                      closeAgainstStore,
                      closingMessage: closingMessage.trim(),
                    },
                    "CLOSED"
                  );
                }}
              >
                Yes
              </Button>
              <Button variant="contained" onClick={() => setCloseCase(false)}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {changeStatus && (
        <CustomModal onClose={closeChangeStatusModal}>
          <div className="close-case-modal">
            <div className="input-container">
              <Select
                options={statusOptions.filter((s) =>
                  (CASE_MAP_FILTER["PENDING_SUPPLIER_ASSIGNED"] || []).includes(
                    s.bl
                  )
                )}
                placeholder="Assign To"
                classNamePrefix="subway-select"
                getOptionLabel={(op) => op.ul}
                getOptionValue={(op) => op.bl}
                value={changeStatusDetails.status}
                onChange={(value) => {
                  // if (value) {
                  //   if (value?.bl === "ASSIGNED_TO_DC" && !caseDetails.dc) {
                  //     loadUsers()
                  //   }
                  // }
                  setChangeStatusDetails((prevVal) => {
                    delete prevVal.newAssignedToUser;
                    return { ...prevVal, status: value };
                  });
                }}
              />
            </div>
            {/* {
              <div className="input-container">
                {((changeStatusDetails?.status?.bl === "ASSIGNED_TO_DC" &&
                  !caseDetails.dc) ||
                  (changeStatusDetails?.status?.bl === "ASSIGNED_TO_VENDOR" &&
                    !caseDetails.vendor)) && (
                  <Select
                    options={changeStatusUserList}
                    placeholder={
                      changeStatusDetails?.status?.bl === "ASSIGNED_TO_DC"
                        ? "Selected  DC "
                        : "Select vendor"
                    }
                    classNamePrefix="subway-select"
                    getOptionLabel={(op) =>
                      `${getValue(op.name)} - ${getValue(op.email)}`
                    }
                    getOptionValue={(op) => op.id}
                    value={changeStatusDetails.newAssignedToUser || ""}
                    onChange={(value) => {
                      setChangeStatusDetails((prevVal) => ({
                        ...prevVal,
                        newAssignedToUser: value,
                      }));
                    }}
                  />
                )}
              </div>
            } */}
            <div className="btn-container">
              <Button
                variant="outlined"
                // disabled={
                //   !changeStatusDetails.status ||
                //   (changeStatusDetails.status?.bl === "ASSIGNED_TO_DC"
                //     ? !caseDetails.dc
                //       ? !changeStatusDetails.newAssignedToUser
                //       : false
                //     : !caseDetails.vendor
                //     ? !changeStatusDetails.newAssignedToUser
                //     : false)
                // }
                disabled={!changeStatusDetails.status}
                onClick={saveNewCaseStatus}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => closeChangeStatusModal()}
              >
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {modalTypes.changeVendor === modalType && (
        <ChangeVendorModal closeModal={setModalType} onSuccess={onSuccess} />
      )}
    </div>
  );
};

export default TakeAction;
