import { pick } from "../../../common-utilities/utils";
import {
  CF_APPROVAL_HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { storeTypes } from "../../utils/constants";
import { plainApi, invokeApi } from "../../utils/http-service";
import { HTTP_METHODS } from "../../utils/http-service";
import { downloadReports } from "./utils";
import { cocoAcquiredData } from "./utils/coco-acquired.data";
import { cocoBuiltData } from "./utils/coco-built.data";

export const getStoreNumberList = (searchTerm, setStoreOptions) => {
  let timeoutId = null;
  clearTimeout(timeoutId);
  setTimeout(() => {
    plainApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
      null,
      {
        storeNumber: searchTerm,
      }
    )
      .then((res) => {
        const stores =
          res?.results?.reduce?.((uniqueMap, item) => {
            if (item?.storeNumber) {
              uniqueMap[item?.storeNumber] = true;
            }
            return uniqueMap;
          }, {}) || {};
        setStoreOptions(Object.keys(stores));
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);
};

export const getData = (filters, setDataFunc, download) => {
  const params = pick(
    filters,
    [
      "limit",
      "page",
      "sortBy",
      "storeType",
      "cluster",
      "state",
      "city",
      "restaurantId",
    ],
    true
  );
  if (params.cluster === "All") {
    delete params.cluster;
  }

  params.nofmtChg = true;
  params.noRejected = true;
  invokeApi(
    HTTP_METHODS.GET,
    `${CF_APPROVAL_HOSTNAME}${REST_URLS.REPORTS}`,
    null,
    params
  )
    .then((res) => {
      if (res?.message) {
        return;
      }

      const results =
        params?.storeType === storeTypes.cocoBuild
          ? cocoBuiltData(res?.results)
          : cocoAcquiredData(res?.results);
      if (typeof setDataFunc === "function") {
        setDataFunc({ ...res, results });
      }
      if (download) {
        downloadReports(filters, results);
      }
    })

    .catch((err) => {
      console.log(err);
    });
};
