import React, { useEffect, useState } from "react";
import { Badge, Box, Tab, Tabs } from "@mui/material";
import "./index.scss";
import InvoiceDataList from "./invoice-data-list";
import { useSearchParams } from "react-router-dom";
import InvoiceApprovalList from "./invoice-approval-list";
import { checkUserRight } from "../../utils/utils";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { FINANCE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const InvoicesData = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("subIndex");
  const mainTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const [noOfPendingPayment, setNoOfPendingPayment] = useState(0);
  const [noOfReconsiderPayment, setNoOfReconsiderPayment] = useState(0);
  const loadPendingData = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      {
        approvalType: "invoice_approval",
        invoiceType: "advance_invoice",
        status: "approval_in_progress",
      }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setNoOfPendingPayment(response?.length || 0);
        }
      })
      .catch((err) => {});
  };
  const getReconsiderData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.INVOICE_DATA}`,
      null,
      {
        invoiceType: "advance_invoice",
        status: "reconsider",
      }
    )
      .then((response) => {
        if (!response.message) {
          setNoOfReconsiderPayment(response?.results?.length || 0);
        }
      })
      .catch((err) => {});
  };
  const handleChange = (event, newValue) => {
    setSearchParams({
      index: mainTabIndex || 0,
      subIndex: newValue,
    });
    setValue(newValue);
  };
  useEffect(() => {
    loadPendingData();
    getReconsiderData();
  }, []);
  return (
    <div className="budget-list">
      <div className="tabs-section">
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          <Tab label="My Open Advance Payment Details" />
          <Tab label="My Approved  Advance Payment Details" />
          <Tab
            label={
              <Badge badgeContent={noOfReconsiderPayment} color="primary">
                My Reconsidered Advance Payment Details
              </Badge>
            }
          />
          {/* <Tab label="My Reconsidered Advance Payment Details" /> */}
          <Tab label="My Rejected  Advance Payment Details" />
          <Tab
            label={
              <Badge badgeContent={noOfPendingPayment} color="primary">
                My Pending actions
              </Badge>
            }
          />
          {/* <Tab label="My Pending actions" /> */}
          <Tab label="All Advance Payment" />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <InvoiceDataList status="approval_in_progress" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InvoiceDataList status="approved" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <InvoiceDataList status="reconsider" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InvoiceDataList status="rejected" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        {checkUserRight("advancePayment.approveReject") ? (
          <InvoiceApprovalList
            approvalType="invoice_approval"
            usedIn="INVOICE_DATA"
            status="approval_in_progress"
            invoiceType="advance_invoice"
          />
        ) : (
          "Not authorized to take action on Advance Payment Details"
        )}
      </TabPanel>
      <TabPanel value={value} index={5}>
        <InvoiceDataList status="all" />
      </TabPanel>
    </div>
  );
};

export default InvoicesData;
