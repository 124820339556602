import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Autocomplete, Button, TextField } from "@mui/material";
import CustomModal from "../../../../common-utilities/core/modal";
import "./index.scss";
import { exportToExcel } from "../../../../common-utilities/utils";
import { toast } from "react-toastify";
import { isCreateAllowed } from "../../../utils/common-utils";
import ConfirmModal from "../../../../common-utilities/core/confirm-modal";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../common-components/upload-template";
import { uploadTemplates } from "../constants";
import { companyList, formatDownloadReports } from "./utils";
import { setProperty } from "../../../../common-utilities/utils/misc";

const Company = ({}) => {
  const [budgetModal, setBudgetModal] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [subcategoryList, setSubcategoryList] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [modalLists, setModalLists] = useState({
    companyList: [],
  });

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (filters) => {
    let params = {
      ...filters,
    };
    if (searchKey) {
      params.searchKey = searchKey;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.COMPANY}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (params?.page > response?.totalPages) {
            setProperty("page", response?.totalPages, setFilters);
          }
          setSubcategoryList(response || {});
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.COMPANY}`,
      formDetails
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to create");
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
        }
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    let payload = {
      name: formDetails?.name,
      gstin: formDetails?.gstin,
      companyAddress: formDetails?.companyAddress,
      parent: formDetails?.parent || null,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.COMPANY}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to update");
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
        }
      })
      .catch((err) => {});
  };

  const editRecord = (data) => {
    setBudgetModal(true);
    setFormDetails({ ...data, parent: data?.parent?.id || null });
    const parent = [];

    companyList(
      (list) => {
        if (data?.parent) {
          const parentId = data?.parent?.id;
          const parentData = list.find(
            (item) => parseInt(item.id) === parentId(parentId)
          );

          if (!parentData) {
            list.push({
              name: data?.parent?.name,
              id: data?.parent?.id,
            });
          }
        }
        setProperty("companyList", list, setModalLists);
      },
      { parentNull: true }
    );
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.COMPANY}`,
      null,
      { page: 1, limit: subcategoryList.totalResults || 1000 }
    )
      .then((response) => {
        if (response.code) {
          toast.error(response.message || "Failed to download data");
        } else {
          const results = formatDownloadReports(response.results);
          if (results?.length) {
            exportToExcel(
              formatDownloadReports(response.results),
              "company-list"
            );
          }
        }
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.COMPANY}/${deleteDetails.id}`
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          loadData(filters);
          setDeleteDetails(null);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="company-list">
      <div className="com-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                page: 1,
                limit: 10,
              })
            }
          >
            Search
          </Button>
        </div>
        <div className="left-container">
          {isCreateAllowed("master_data") && (
            <>
              <FileUpload
                buttonBackground="#fff"
                buttonColor="#1756f3"
                mainButtonClass="subway-finance-bulk-upload"
                borderColor="rgba(0, 138, 82, 0.5)"
                uploadText="BULK UPLOAD"
                borderRadius={3}
                clsName="finance-master-data-uploader"
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}company`}
                callBack={() =>
                  loadData({
                    page: filters.page,
                    limit: 10,
                  })
                }
                formDataKey="file"
              />
              <DownloadTemplateButton type={uploadTemplates.company} />
            </>
          )}
          <Button
            variant="outlined"
            disabled={!subcategoryList.totalResults}
            onClick={downloadData}
          >
            Download
          </Button>

          {isCreateAllowed("master_data") && (
            <Button
              variant="outlined"
              onClick={() => {
                companyList(
                  (list) => {
                    setProperty("companyList", list, setModalLists);
                  },
                  { parentNull: true }
                );
                setFormDetails({});
                setBudgetModal(true);
              }}
            >
              Create
            </Button>
          )}
        </div>
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editRecord, setDeleteDetails)}
        dataList={subcategoryList.results || []}
        filters={filters}
        nestedValue={true}
        pageCount={subcategoryList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />

      {budgetModal && (
        <CustomModal
          title={formDetails.id ? "Updated Company" : "Create  Company"}
          disabledOutsideClick={true}
          onClose={() => {
            setBudgetModal(false);
            setFormDetails({});
          }}
        >
          <div className="create-form">
            <TextField
              value={formDetails.name}
              name="name"
              onChange={onInputChange}
              size="small"
              label="Name"
              fullWidth
            />
            <Autocomplete
              options={modalLists.companyList}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Parent Category" />
              )}
              value={
                formDetails?.parent
                  ? modalLists.companyList.find(
                      (item) => item.value == formDetails.parent
                    )
                  : null
              }
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  parent: val?.value || null,
                });
              }}
            />
            <TextField
              value={formDetails.gstin}
              name="gstin"
              onChange={onInputChange}
              size="small"
              label="gstin"
              fullWidth
            />

            <TextField
              value={formDetails.companyAddress}
              name="companyAddress"
              onChange={onInputChange}
              size="small"
              label="Address"
              fullWidth
            />

            {formDetails.id ? (
              <Button fullWidth variant="contained" onClick={onUpdate}>
                Update
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={onSave}>
                Create
              </Button>
            )}
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal onYes={onDelete} onNo={() => setDeleteDetails(null)} />
      )}
    </div>
  );
};

export default Company;
