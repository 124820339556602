import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { TextField, Checkbox } from "@mui/material";
import "./index.scss";
import { HeaderConfig, STORE_TYPES } from "./config";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { invokeApi, plainApi } from "../../utils/http-service";
import { technetEndPoint } from "../../../common-utilities/utils/end-points/tech-net";
import {
  SERVICE_REQUEST_HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import ViewTicket from "./view-ticket";
import { Grid } from "@mui/material";
import { tokenMainKeys } from "../../../../utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { userTypes } from "../../utils/constants";
import { checkTicketCreation, ticketActions, actionHandler } from "./utils";
import ClearIcon from "@mui/icons-material/Clear";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DateRangePicker } from "rsuite";
import { ListModal } from "../../../common-utilities/core/modal/List Modal";
import MuiTable from "../../../common-utilities/core/mui-table";
import AutocompleteComponent, {
  StyledAutocomplete,
} from "../../../common-utilities/core/styled-autocomplete";
import CommonBtn from "../../../common-utilities/core/common-button";

import SearchIcon from "@mui/icons-material/Search";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";
export const PastTickets = () => {
  const navigate = useNavigate();
  const userDetails = getOfflineData(tokenMainKeys.technet, "user");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  const [createTicketOption, setCreateTicketOption] = useState(false);
  const [searchResId, setSearchResId] = useState("");
  const [storeOptions, setStoreOptions] = useState([]);
  const [ticketId, setTicketId] = useState("");
  const [openTickets, setOpenTickets] = useState(false);
  const [storeType, setStoreType] = useState("");
  const [pastTickets, setPastTickets] = useState({
    results: [],
  });
  const [modalDetails, setModalDetails] = useState(null);
  const [viewTicket, setViewTicket] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [filtersAfterClickingOnApply, setFiltersAfterClickingOnApply] =
    useState({
      page: 1,
      limit: 20,
      sortBy: "-createdAt",
    });
  useEffect(() => {
    if (!getOfflineData(tokenMainKeys.technet, "user")) {
      navigate("/");
    } else {
      loadData(filters);
    }
  }, [navigate]);

  useEffect(() => {
    if (!userDetails) {
      return;
    }

    if (userDetails?.type === userTypes.STORE_OWNER) {
      setCreateTicketOption(true);
    } else {
      checkTicketCreation(setCreateTicketOption);
    }
  }, []);
  const loadData = (filters) => {
    const allFilters = { ...filters };
    addCorrectStartEndDate(allFilters);
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.TICKETS}`,
      null,
      allFilters
    )
      .then((response) => {
        if (response.results) {
          setPastTickets(response);
        }
      })
      .catch((err) => console.log(err));
  };

  const onRowClick = (data, type) => {
    switch (type) {
      case ticketActions.commentModal:
        actionHandler(data, type, setModalDetails);
        break;
      default:
        navigate(`${technetEndPoint}/details/${data.id}`);
        break;
    }
  };

  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
        null,
        {
          storeNumber: searchTerm,
        }
      ).then((res) => {
        const storeList = res?.results?.map((ele) => ({
          storeNo: ele?.storeNumber,
          id: ele?.id,
        }));
        setStoreOptions(storeList);
      });
    }, 300);
  };
  const inputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
    }
  };
  const searchHandler = () => {
    const payload = {
      page: 1,
      limit: 20,
      sortBy: "-createdAt",
    };
    if (searchResId) {
      payload["store"] = parseInt(searchResId);
    }
    if (ticketId) {
      payload["_id"] = parseInt(ticketId);
    }
    if (openTickets) {
      payload.status = "OPENED";
    }

    if (dateRange.startDate) {
      payload.startDate = dateRange.startDate;
    }
    if (dateRange.endDate) {
      payload.endDate = dateRange.endDate;
    }
    if (storeType) {
      payload["storeType"] = storeType;
    }
    setFiltersAfterClickingOnApply(payload);
    loadData(payload);
  };
  const closeModal = () => {
    setModalDetails(null);
  };
  return (
    <>
      <div className="past-tickets-container">
        {createTicketOption && (
          <div className="createTicketButtonContainer">
            <CommonBtn
              onClick={() => navigate(`${technetEndPoint}/create`)}
              variant="contained"
              color="primary"
              size="small"
            >
              Create new
            </CommonBtn>
          </div>
        )}
        <Grid
          container
          md={12}
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item md={2}>
            <StyledAutocomplete
              getOptionLabel={(option) => option?.storeNo?.toString()}
              className="poper-select styled-text-field"
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setSearchResId("");
                    setStoreOptions([]);
                  }}
                />
              }
              options={storeOptions}
              renderInput={(params) => (
                <TextField
                  type="number"
                  {...params}
                  size="small"
                  label="Restaurant ID"
                />
              )}
              onInputChange={inputChange}
              onChange={(event, value) => {
                if (value) {
                  setSearchResId(value?.id);
                }
              }}
            />
          </Grid>
          <Grid item md={1.5}>
            <TextField
              label="Ticket ID"
              size="small"
              className="new-textfield"
              type="number"
              onChange={(e) => {
                setTicketId(e.target.value);
              }}
            />
          </Grid>

          <Grid item md={4}>
            <DateRangePicker
              cleanable={false}
              className="new-textfield"
              placeholder="Select Date Range"
              onChange={dateRangeHandler}
            />
          </Grid>
          <Grid item md={2.5}>
            <AutocompleteComponent
              options={STORE_TYPES}
              label="Select Store Type"
              noValue={true}
              onChange={(e, val) => {
                setStoreType(val?.value || "");
              }}
            />
          </Grid>
          <Grid item md={1.5}>
            <FormControlLabel
              label="Open Tickets"
              control={
                <Checkbox
                  checked={!!openTickets}
                  onChange={() => {
                    setOpenTickets(!openTickets);
                  }}
                />
              }
            />
          </Grid>
          <Grid item md={0.5}>
            <SearchIcon onClick={searchHandler} sx={{ cursor: "pointer" }} />
          </Grid>
        </Grid>

        <ListModal
          showModal={modalDetails?.type === ticketActions.commentModal}
          title={modalDetails?.title}
          closeModal={closeModal}
          data={modalDetails?.data}
        />

        <div>
          <MuiTable
            columnsList={HeaderConfig}
            dataList={pastTickets.results || []}
            filters={filters}
            pageCount={pastTickets.totalPages}
            onClick={onRowClick}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filtersAfterClickingOnApply,
                ...filters,
                page,
              });
            }}
          ></MuiTable>
        </div>

        <Drawer anchor="right" open={!!viewTicket}>
          <ViewTicket
            details={viewTicket || {}}
            onClose={() => setViewTicket(null)}
          />
        </Drawer>
      </div>
    </>
  );
};
