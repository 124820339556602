import { toast } from "react-toastify";
import { pick } from "../../../common-utilities/utils";
import { getStateDropdownList } from "../../../common-utilities/utils/region-utils";
import { getValue } from "../../utils";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { clearFormDetails } from "./config";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

export const getProductByCode = (details, setFormDetails) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${QNET_HOSTNAME}${REST_URLS.PRODUCT_BY_FILTER}`,
    null,
    {
      code: details?.productInfo?.code,
    }
  )
    .then((res) => {
      if (res?.message) {
        setFormDetails(details);
        return;
      }
      const allDetails = { ...details };
      allDetails.productInfo.code = {
        label: `${res.name} - ${res.code}`,
        value: res.code,
      };
      setFormDetails(allDetails);
    })
    .catch((err) => {
      console.log(err);
      setFormDetails(details);
    });
};

export const getStoreDataByStoreId = (
  id,
  resetDC,
  setRestaurantDetails,
  setFormDetails,
  complaintType,
  setStateList
) => {
  if (Number.isInteger(parseInt(id))) {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.GET_STORE_BY_ID}${id}`
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          return;
        }

        const allrestaurantDetails = pick({ ...res }, [
          "dc",
          "city",
          "country",
          "primaryContact",
          "alternateContact",
          "address",
          "address2",
          "pinCode",
          "state",
          "id",
          "storeNumber",
        ]);

        const { dc } = res;
        delete res.dc;
        delete allrestaurantDetails.dc;
        delete allrestaurantDetails.updatedAt;
        allrestaurantDetails.id = {
          label: allrestaurantDetails.storeNumber,
          value: allrestaurantDetails.id,
        };

        if (allrestaurantDetails.city) {
          allrestaurantDetails.city = allrestaurantDetails.city;
        }
        if (allrestaurantDetails?.country) {
          const stateList = getStateDropdownList(allrestaurantDetails.country);
          setStateList(stateList);
          allrestaurantDetails.country = {
            label: allrestaurantDetails.country,
            value: allrestaurantDetails.country,
          };
        }
        if (allrestaurantDetails?.state) {
          allrestaurantDetails.state = {
            label: allrestaurantDetails?.state,
            value: allrestaurantDetails?.state,
          };
        }
        setRestaurantDetails({ ...allrestaurantDetails });
        if (resetDC) {
          let dcLabelValue = null;
          if (dc) {
            dcLabelValue = { label: getValue(dc?.name, true), value: dc.id };
          }
          setFormDetails((prevDetails) => {
            if (complaintType === "PRODUCT") {
              if (!prevDetails?.productInfo) {
                prevDetails.productInfo = {};
              }
              prevDetails.productInfo.dc = dcLabelValue;
            }

            return { ...prevDetails, dc: dcLabelValue };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const createUpdateCase = (
  caseId,
  casePayload,
  id,
  isEditingCase,
  loadData,
  setCurPage,
  setFormDetails,
  setFiles,
  navigate,
  curPageStatuses,
  location
) => {
  const method = Number.isInteger(parseInt(caseId))
    ? HTTP_METHODS.PUT
    : HTTP_METHODS.POST;
  const url = Number.isInteger(parseInt(caseId))
    ? `${QNET_HOSTNAME}${REST_URLS.UPDATE_BASIC_CASE_DETAILS}${caseId}`
    : `${QNET_HOSTNAME}${REST_URLS.CREATE_CASE}`;

  const payload = Number.isInteger(parseInt(caseId))
    ? {
        ...casePayload,
      }
    : {
        ...casePayload,
        store: id,
      };

  if (isEditingCase) {
    delete payload.dc;
  }
  const successMessage = Number.isInteger(parseInt(caseId))
    ? "Case updated successfully"
    : "Case created successfully";
  invokeApi(method, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      toast.success(successMessage, { autoClose: 2000 });
      if (method === HTTP_METHODS.POST) {
        if (typeof loadData === "function") {
          loadData();
        }
        if (curPageStatuses && setCurPage) {
          setCurPage(curPageStatuses.home);
        }
      } else {
        navigate(`${location.pathname.split("/").slice(0, -1).join("/")}`);
      }
      if (!Number.isInteger(parseInt(caseId))) {
        clearFormDetails(setFormDetails, setFiles);
      }
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
    });
};

const setParametersForCreateorUpdateCaseAndcreateOrUpdateCase = (
  haveToUpdateCase,
  createUpdateCaseParams
) => {
  if (haveToUpdateCase && createUpdateCaseParams) {
    const {
      caseId,
      casePayload,
      id,
      isEditingCase,
      loadData,
      setCurPage,
      setFormDetails,
      setFiles,
      navigate,
      curPageStatuses,
      location,
    } = createUpdateCaseParams;
    createUpdateCase(
      caseId,
      casePayload,
      id,
      isEditingCase,
      loadData,
      setCurPage,
      setFormDetails,
      setFiles,
      navigate,
      curPageStatuses,
      location
    );
  }
};

export const updateStoreDetailsById = (
  id,
  storePayload,
  setRestaurantDetails,
  caseId,
  haveToUpdateCase,
  createUpdateCaseParams
) => {
  invokeApi(
    HTTP_METHODS.PUT,
    `${QNET_HOSTNAME}${REST_URLS.UPDATE_STORE_BY_ID}${id}`,
    storePayload
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }

      if (!Number.isInteger(parseInt(caseId))) {
        setRestaurantDetails({});
      }
      setParametersForCreateorUpdateCaseAndcreateOrUpdateCase(
        haveToUpdateCase,
        createUpdateCaseParams
      );
      // toast.success("Restaurant Details updated successfully", {
      //   autoClose: 2000,
      // });
    })
    .catch((err) => {
      // setParametersForCreateorUpdateCaseAndcreateOrUpdateCase(
      //   haveToUpdateCase,
      //   createUpdateCaseParams
      // );
      toast.error(err?.message, { autoClose: 2000 });
    });
};
