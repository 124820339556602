import {
  formatedPercentageRender,
  getConfigFields,
  getSlicedNum,
} from "../../utils";

export const storeDetailsConfig = (filters) => {
  const config = [
    {
      label: "Format",
      id: "format",
    },
    {
      label: "Term of Lease (Yrs)",
      id: "termOfLeaseYrs",
    },
    {
      label: "Lockin Period (Yrs)",
      id: "lockinPeriodYrs",
    },
    {
      label: "Fit-out Period",
      id: "fitOutPeriod",
    },
    {
      label: "Chargeable Area (Sqft)",
      id: "chargeableAreaSqft",
    },
    {
      label: "Carpet Area (Sq ft)",
      id: "carpetAreaSqft",
    },
    {
      label: "No. of Seaters",
      id: "noOfSeaters",
    },
  ];

  return getConfigFields(config, filters, "storeDetails");
};

export const investmentConfig = (filters) => {
  let config = [
    {
      label: "LL Investment",
      id: "llInvestment",
    },
    {
      label: "Franchise Fees",
      id: "franchiseFees",
    },
    {
      label: "LHI GC",
      id: "lhiGc",
    },
    {
      label: "LHI cost per Sq. Ft. in Rs",
      id: "lhiCostPerSqFtInRs",
      render: (data) => getSlicedNum(data, "lhiCostPerSqFtInRs"),
    },
    {
      label: "LHI BO",
      id: "lhiBo",
    },
    {
      label: "Décor Range",
      id: "decorRange",
    },
    {
      label: "Fabrication",
      id: "fabrication",
    },
    {
      label: "BO Equipments",
      id: "boEquipments",
    },
    {
      label: "Small ware",
      id: "smallWare",
    },
    {
      label: "Compliance & Hygiene",
      id: "complianceHygiene",
    },
    {
      label: "POS + Printer + IT + POSIST",
      id: "posPrinterItPosist",
    },
    {
      label: "Fit-Out period cost - CAM , if any",
      id: "fitOutPeriodCostCam",
    },
    {
      label: "Fit-Out period cost - Utility , if any",
      id: "fitOutPeriodCostUtility",
    },
    {
      label: "Freight Charges",
      id: "freightCharges",
    },
    {
      label: "Supplies",
      id: "supplies",
    },
    {
      label: "License cost",
      id: "licenseCost",
    },
    {
      label: "Misc Cost",
      id: "miscCost",
    },
    {
      label: "Site Brokerage",
      id: "siteBrokerage",
    },
    {
      label: "Opening kit",
      id: "openingKit",
    },
    {
      label: "Design + Architect cost",
      id: "designArchitectCost",
    },
    {
      label: "Travel cost for site selection",
      id: "travelCostForSiteSelection",
    },
    {
      label: "Lawyer Fees for DD",
      id: "lawyerFeesForDd",
    },
    {
      label: "Stamp duty & Registration",
      id: "stampDutyRegistration",
    },
    {
      label: "Genset/DG",
      id: "gensetDg",
    },
    {
      label: "Servo",
      id: "servo",
    },
    {
      label: "Pre Opening",
      id: "preOpening",
      render: (data) => getSlicedNum(data, "preOpening"),
    },
    {
      label: "Total Invt Excl SD",
      id: "totalInvtExclSd",
      render: (data) => getSlicedNum(data, "totalInvtExclSd"),
    },
    {
      label: "R. Sec Deposit",
      id: "rSecDeposit",
    },
    {
      label: "Total Invt Incl SD",
      id: "totalInvtInclSd",
    },
    {
      label: "Hardcost",
      id: "hardcost",
    },
    {
      label: "Softcost",
      id: "softcost",
    },
  ];

  return getConfigFields(config, filters, "investment");
};


export const occupancy1Config = (filters) => {
  const config = [
    {
      label: "MG Yr1 permonth",
      id: "mgYr1Permonth",
    },
    {
      label: "RS Dine In Yr1 %",
      id: "rsDineInYr1Percentage",
      render: (data) => formatedPercentageRender(data, "rsDineInYr1Percentage"),
      percentageConversion: true,
    },
    {
      label: "RS Del In Yr1 %",
      id: "rsDelInYr1Percentage",
      render: (data) => formatedPercentageRender(data, "rsDelInYr1Percentage"),
      percentageConversion: true,
    },
    {
      label: "CAM Yr1 pm in Rs",
      id: "camYr1PmInRs",
    },
    {
      label: "Other Property charges Yr1 pm",
      id: "otherPropertyChargesYr1Pm",
    },
  ];

  return getConfigFields(config, filters, "occupanyYear1");
};
