/** @format */

import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { getHeaderConfig } from "./config";
import { invokeApi } from "../../utils/http-service";
import { userTypes } from "../../utils/constants";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { FormControlLabel, Checkbox } from "@mui/material";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import { tokenMainKeys } from "../../../../utils/constants";
import CustomModal from "../../../common-utilities/core/modal";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import { CategoryDoc } from "./sub-components/category-doc.subcomponent";
import { NewTicketCatFields } from "./sub-components/new-fields";

const TicketCategory = () => {
  const [TicketCategory, setTicketCategory] = useState({});
  const [allticketCategoriesList, setallticketCategoriesList] = useState([]);
  const [addTicketCategory, setAddTicketCategory] = useState(false);
  const [showCreateEscalation, setShowCreateEscalation] = useState(false);
  const [escalationObj, setEscalationObj] = useState({
    sla: "",
  });
  const [departmentList, setDepartmentList] = useState([]);
  const [escalationList, setEscalationList] = useState([]);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [dept, setDept] = useState({ label: "", value: null });
  const [escalteTo, setEscalteTo] = useState({ label: "", value: null });
  const [escalationToUsersList, setEscalationToUsersList] = useState([]);
  const [createNewParent, setcreateNewParent] = useState(false);
  const [parent, setParent] = useState({ name: "", id: null });
  const [parentError, setParentError] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [adminAndTechNetUsersList, setadminAndTechNetUsersList] = useState([]);
  const [crtData, setCrtData] = useState({});
  const [primaryContact, setPrimaryContact] = useState({
    label: "",
    value: null,
  });
  const [secondaryContact, setSecondaryContact] = useState({
    label: "",
    value: null,
  });
  const [tertiaryContact, setTertiaryContact] = useState({
    label: "",
    value: null,
  });
  const [primaryContactList, setPrimaryContactList] = useState([]);
  const [secondaryContactList, setSecondaryContactList] = useState([]);
  const [tertiaryContactList, setTertiaryContactList] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });
  const [showCreateTicketCategoryButton, setshowCreateTicketCategoryButton] =
    useState(true);

  const [slaLevelValue, setslaLevelValue] = useState({
    label: "",
    value: null,
  });

  const slaLevelLabels = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
  ];
  const navigate = useNavigate();
  const loadData = useCallback(
    (params) => {
      const filterParams = params ? { ...params } : { ...filters };
      invokeApi(
        HTTP_METHODS.GET,
        `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_CATEGORY}`,
        null,
        filterParams
      )
        .then((response) => {
          const maxLen = 25;
          if (response) {
            const results = response.results.map((ele) => ({
              ...ele,
              dept: ele.dept.name,
              deptId: ele.dept.id,
              desc:
                ele.desc.length > maxLen
                  ? `${ele.desc.substring(0, maxLen)}...`
                  : ele.desc,
              fullDesc: ele.desc,
            }));

            setTicketCategory({ ...response, results });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [filters]
  );

  useEffect(() => {
    const userCredentials = getOfflineData(tokenMainKeys.technet, "user");
    if (!userCredentials) {
      navigate("/login");
    } else {
      const { type } = userCredentials;
      if (type === userTypes.STORE_OWNER) {
        setshowCreateTicketCategoryButton(false);
      } else {
        setshowCreateTicketCategoryButton(true);
      }
      loadData(filters);
    }
  }, [filters, navigate, loadData]);
  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`
    )
      .then((response) => {
        if (response) {
          response = response.map((dept) => ({
            label: dept.name,
            value: dept.id,
          }));
        }
        setDepartmentList(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.ADMINANDTECHNETUSERSLIST}`
    )
      .then((response) => {
        if (response?.message) {
          return;
        }

        const allTechNetAndAdminUsers = response.map((item) => {
          return { label: item.name, value: item._id };
        });
        setadminAndTechNetUsersList(allTechNetAndAdminUsers);
        setPrimaryContactList(allTechNetAndAdminUsers);
        setSecondaryContactList(allTechNetAndAdminUsers);
        setTertiaryContactList(allTechNetAndAdminUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getAllCategoriesWithParent = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.TICKET_CATEGORY_WITHOUT_PARENT}`,
      null
    )
      .then((response) => {
        if (response) {
          const allcategorieswithparent = response.map((item) => {
            const { id, name } = item;
            return { value: id.toString(), label: name };
          });
          setallticketCategoriesList(allcategorieswithparent);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const params = { type: "TECH_NET_USER" };

    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.GET_USERS_WITH_FILTER}`,
      null,
      params
    )
      .then((response) => {
        if (response) {
          const allTechNetUsers = response.map((item) => {
            const { name, _id } = item;
            return { label: name, value: _id };
          });
          setEscalationToUsersList(allTechNetUsers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addCategoryFormHandler = () => {
    setAddTicketCategory(true);
  };

  const addNameHandler = (event) => {
    setName(event.target.value);
  };
  const addDescHandler = (event) => {
    setDesc(event.target.value);
  };
  const addDeptHandler = (event) => {
    setDept((prevVal) => ({
      ...prevVal,
      label: event.label,
      value: event.value,
    }));
  };

  const changeParentHandler = (event) => {
    setParent((prevVal) => ({
      ...prevVal,
      name: event.label,
      id: event.value,
    }));
  };

  const selectEscalationTo = (event) => {
    const { label, value } = event;
    setEscalteTo({ label, value });
  };

  const escalationListHandler = () => {
    escalationList.push({
      ...escalationObj,
      escalateTo: parseInt(escalteTo.value),
      no: slaLevelValue.value,
    });
    setEscalationObj((prevVal) => ({
      ...prevVal,
      sla: "",
    }));
    setShowCreateEscalation(false);
  };
  const payload = {
    name: name,
    dept: dept.value,
    desc: desc,
    escalationMatrix: escalationList,
    parent: parent.id,
    primaryContact: primaryContact.value,
    secondaryContact: secondaryContact.value,
    tertiaryContact: tertiaryContact.value,
    ...crtData,
    storeType: crtData?.storeType?.map?.((item) => item.value) || [],
  };

  //Add New category
  const formSubmitHandler = () => {
    if (createNewParent && parent.id == null) {
      setParentError(true);
      return;
    }
    if (name === "") {
      toast.error("Name is required", { autoClose: 2000 });
    } else if (dept.value === null) {
      toast.error("Department is required", { autoClose: 2000 });
    } else if (desc === "") {
      toast.error("Description is required", { autoClose: 2000 });
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.CREATE_CATEGORY}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message);
          } else {
            toast.success("Ticket Category created successfully");
            loadData(filters);
            setName("");
            setDept({ label: "", value: null });
            setDesc("");
            setPrimaryContact({
              label: "",
              value: null,
            });
            setSecondaryContact({
              label: "",
              value: null,
            });
            setTertiaryContact({
              label: "",
              value: null,
            });
            setEscalationList([]);
            setAddTicketCategory(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  //get Ticket Category

  const setDropDownValuesOfOtherDropDownsOnSelection = (
    value,
    setotherDropdownListFunction1,
    setotherDropDownListFunction2,
    otherValue1,
    otherValue2
  ) => {
    const otherList1 = adminAndTechNetUsersList.filter((item) => {
      return item.value !== value && item.value !== otherValue2;
    });

    const otherList2 = adminAndTechNetUsersList.filter((item) => {
      return item.value !== value && item.value !== otherValue1;
    });
    setotherDropdownListFunction1(otherList1);
    setotherDropDownListFunction2(otherList2);
  };

  const setAllContactsToInitialState = () => {
    setPrimaryContact({ label: "", value: null });
    setSecondaryContact({ label: "", value: null });
    setTertiaryContact({ label: "", value: null });
    setPrimaryContactList(adminAndTechNetUsersList);
    setSecondaryContactList(adminAndTechNetUsersList);
    setTertiaryContactList(adminAndTechNetUsersList);
  };

  const selectDropDownStyles = { menu: (styles) => ({ ...styles, zIndex: 2 }) };
  const onEscalationClose = () => {
    setEscalteTo({ label: "", value: null });
    setShowCreateEscalation(false);
    setslaLevelValue({
      label: "",
      value: null,
    });
    setEscalationObj((prevVal) => ({
      ...prevVal,
      sla: "",
    }));
  };

  return (
    <>
      <div className="store-container">
        <div className="button-div">
          <div className="search-field">
            <TextField
              fullWidth
              sx={{ my: 1 }}
              label="Search by category name"
              onChange={(e) => setCategoryName(e?.target?.value)}
              value={categoryName}
              size="small"
              className="new-textfield"
            />
            <SearchIcon
              className="main-search-icon"
              onClick={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                  categoryName,
                }));
              }}
            />
          </div>
          <div>
            {showCreateTicketCategoryButton && (
              <CommonBtn
                variant="contained"
                className="button createcategorybutton"
                onClick={addCategoryFormHandler}
              >
                Create Ticket Category
              </CommonBtn>
            )}
          </div>
        </div>
        <MuiTable
          columnsList={getHeaderConfig(
            loadData,
            departmentList,
            adminAndTechNetUsersList,
            escalationToUsersList
          )}
          dataList={TicketCategory.results || []}
          filters={filters}
          pageCount={TicketCategory.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            loadData({
              ...filters,
              page,
            });
          }}
        ></MuiTable>
      </div>
      {addTicketCategory && (
        <CustomModal
          title="New Category"
          classesToPreventClosing={["MuiAutocomplete-option"]}
          contentClassName={{ headerBackgroundColor: "#1756f3" }}
          onClose={() => {
            onEscalationClose();
            setAllContactsToInitialState();
            setParentError(false);
            setParent({ name: "", id: null });
            setcreateNewParent(false);
            setAddTicketCategory(false);
            setEscalteTo({ label: "", value: null });
            setName("");
            setDept({ label: "", value: null });
            setDesc("");
            setPrimaryContact({
              label: "",
              value: null,
            });
            setSecondaryContact({
              label: "",
              value: null,
            });
            setTertiaryContact({
              label: "",
              value: null,
            });
            setEscalationList([]);
          }}
        >
          {showCreateEscalation === false && (
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                id="name"
                sx={{ my: 1 }}
                label="Name"
                multiline
                onChange={addNameHandler}
                value={name}
                size="small"
                className="new-textfield"
              />

              <FormControlLabel
                value="start"
                checked={createNewParent}
                className="createNewParentlabel"
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => {
                      const showSelectSubcategoryDropdown = !createNewParent;
                      if (showSelectSubcategoryDropdown) {
                        getAllCategoriesWithParent();
                      }
                      setParentError(false);
                      setParent({ name: "", id: null });
                      setcreateNewParent(!createNewParent);
                    }}
                  />
                }
                label="Is this a Subcategory ?"
                labelPlacement="start"
              />

              {createNewParent && (
                <>
                  <AutocompleteComponent
                    value={
                      parent?.id
                        ? { label: parent.name, value: parent.id }
                        : null
                    }
                    options={allticketCategoriesList}
                    className="select-dropdown"
                    onChange={(e, val) => {
                      changeParentHandler(val);
                    }}
                    label="Select Main Category"
                    disableClear={true}
                  />

                  {parentError && (
                    <span className="error-msg">Parent is required</span>
                  )}
                </>
              )}

              <TextField
                fullWidth
                id="description"
                sx={{ my: 1 }}
                multiline
                rows={4}
                label="Description"
                onChange={addDescHandler}
                value={desc}
                type="desc"
                size="small"
                className="new-textfield"
              />
              <AutocompleteComponent
                value={dept?.value ? dept : null}
                options={departmentList}
                className="select-dropdown"
                onChange={(e, val) => {
                  addDeptHandler(val);
                }}
                label="Department"
                disableClear={true}
              />
              <span className="create-escalation">
                <p>Create Escalation</p>{" "}
                <AddCircleOutlineIcon
                  onClick={() => setShowCreateEscalation(true)}
                  style={{ cursor: "pointer" }}
                />
              </span>
              <div className="delete-escalation-div">
                {escalationList.length > 0 &&
                  escalationList.map((esc, index) => {
                    return (
                      <div className="delete-escalation">
                        <p>{`SLA - ${esc.sla}h `}</p>
                        <p>{`Level - L${esc.no} `}</p>
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            escalationList.splice(index, 1);
                            setEscalationList((prevVal) => [...prevVal]);
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
              <AutocompleteComponent
                value={primaryContact?.value ? primaryContact : null}
                options={primaryContactList}
                className="select-dropdown"
                onChange={(e, event) => {
                  setPrimaryContact({ label: event.label, value: event.value });
                  setDropDownValuesOfOtherDropDownsOnSelection(
                    event.value,
                    setSecondaryContactList,
                    setTertiaryContactList,
                    secondaryContact.value,
                    tertiaryContact.value
                  );
                }}
                label="Primary Contact"
                disableClear={true}
              />

              <AutocompleteComponent
                value={secondaryContact?.value ? secondaryContact : null}
                className="select-dropdown"
                options={secondaryContactList}
                onChange={(e, event) => {
                  setSecondaryContact({
                    label: event.label,
                    value: event.value,
                  });
                  setDropDownValuesOfOtherDropDownsOnSelection(
                    event.value,
                    setPrimaryContactList,
                    setTertiaryContactList,
                    primaryContact.value,
                    tertiaryContact.value
                  );
                }}
                label="Secondary Contact"
                disableClear={true}
              />

              <AutocompleteComponent
                value={tertiaryContact?.value ? tertiaryContact : null}
                options={tertiaryContactList}
                className="select-dropdown"
                onChange={(e, event) => {
                  setTertiaryContact({
                    label: event.label,
                    value: event.value,
                  });
                  setDropDownValuesOfOtherDropDownsOnSelection(
                    event.value,
                    setPrimaryContactList,
                    setSecondaryContactList,
                    primaryContact.value,
                    secondaryContact.value
                  );
                }}
                label="Tertiary Contact"
                disableClear={true}
              />
              <NewTicketCatFields data={crtData} mainSetData={setCrtData} />
              <CategoryDoc
                showModal={addTicketCategory}
                mainSetData={setCrtData}
                data={crtData}
              />

              <Stack
                alignItems="flex-end"
                justifyContent="flex-end"
                direction="row"
                spacing={2}
                sx={{ my: 2 }}
              >
                <CommonBtn
                  className="createcategorybutton"
                  variant="contained"
                  color="primary"
                  onClick={formSubmitHandler}
                  fullWidth
                >
                  Create
                </CommonBtn>
                <CommonBtn
                  className="cancelcategorybutton"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setAddTicketCategory(false);
                    setName("");
                    setDept({ label: "", value: null });
                    setDesc("");
                    setPrimaryContact({
                      label: "",
                      value: null,
                    });
                    setSecondaryContact({
                      label: "",
                      value: null,
                    });
                    setTertiaryContact({
                      label: "",
                      value: null,
                    });
                    setEscalationList([]);
                  }}
                  fullWidth
                >
                  Cancel
                </CommonBtn>
              </Stack>
            </Box>
          )}
          {showCreateEscalation === true && (
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                type="number"
                id="sla"
                sx={{ my: 1 }}
                label="SLA in hours"
                onChange={(event) =>
                  setEscalationObj((prevVal) => ({
                    ...prevVal,
                    sla: event.target.value,
                  }))
                }
                value={escalationObj.sla}
                size="small"
                className="new-textfield"
              />

              <AutocompleteComponent
                value={slaLevelValue?.value ? slaLevelValue : null}
                options={slaLevelLabels}
                className="select-dropdown"
                onChange={(e, event) => {
                  const { label, value } = event;
                  setslaLevelValue({ label, value });
                }}
                label="Escalation Level"
                disableClear={true}
              />
              <AutocompleteComponent
                value={escalteTo?.value ? escalteTo : null}
                options={escalationToUsersList}
                className="select-dropdown"
                onChange={(e, val) => {
                  selectEscalationTo(val);
                }}
                getOptionLabel={(op) => op.label}
                getOptionValue={(op) => op.value}
                label="Escalate to"
                disableClear={true}
              />

              <Stack
                alignItems="flex-end"
                justifyContent="flex-end"
                direction="row"
                spacing={2}
                sx={{ my: 2 }}
              >
                <CommonBtn
                  variant="contained"
                  color="primary"
                  onClick={escalationListHandler}
                  fullWidth
                  disabled={
                    escalationObj.sla === "" ||
                    escalteTo.value === null ||
                    slaLevelValue.value === null
                  }
                >
                  Add
                </CommonBtn>
                <CommonBtn
                  variant="outlined"
                  color="primary"
                  onClick={onEscalationClose}
                  fullWidth
                >
                  Cancel
                </CommonBtn>
              </Stack>
            </Box>
          )}
        </CustomModal>
      )}
    </>
  );
};

export default TicketCategory;
