/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import { ProductCategoryConfig } from "./config";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { Button, TextField } from "@mui/material";
import CustomModal from "../../../common-utilities/core/modal";
import ConfirmModal from "../../../common-utilities/core/confirm-modal";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import MaterialUITable from "../../../common-utilities/core/material-ui-table";
import MuiTable from "../../../common-utilities/core/mui-table";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";
const ProductCategory = () => {
  const [products, setProducts] = useState({});
  const [productDetails, setProductDetails] = useState(null);
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
    name: "",
  });

  const [filtersAfterClickingOnApply, setFiltersAfterClickingOnApply] =
    useState({
      name: "",
    });

  const loadData = (filtersObj, usePreviouslyAppliedFilters) => {
    const isFilterPresent = typeof filtersObj === "object" && filters;
    const userFilters = isFilterPresent ? { ...filtersObj } : { ...filters };
    if (!userFilters.limit) {
      userFilters.limit = filters.limit;
    }
    if (!userFilters.sortBy) {
      userFilters.sortBy = filters.sortBy;
    }
    if (!userFilters.page) {
      userFilters.page = filters.page;
    }

    if (!userFilters.name) {
      userFilters.name = filters.name;
    }

    if (usePreviouslyAppliedFilters) {
      userFilters.name = filtersAfterClickingOnApply.name;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.PRODUCT_CATEGORY}`,
      null,
      userFilters
    )
      .then((response) => {
        if (response.results) {
          setProducts(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadData(filters);
  }, []);

  const onRowClick = (details, actionType) => {
    if (actionType === "DELETE") {
      setDeleteDetails(details);
    } else {
      setProductDetails(details);
    }
  };

  const onInputChange = (event) => {
    event.persist();
    setProductDetails({
      ...productDetails,
      [event.target.name]: event.target.value,
    });
  };

  const createRecord = () => {
    const payload = { ...productDetails };
    invokeApi(
      HTTP_METHODS.POST,
      `${QNET_HOSTNAME}${REST_URLS.PRODUCT_CATEGORY}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        toast.success("Product Category created successfully", {
          autoClose: 2000,
        });
        setProductDetails(null);
        loadData(filters);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateRecord = (details) => {
    const method = details?.archive ? HTTP_METHODS.DELETE : HTTP_METHODS.PUT;
    const convertedDetails = { ...details };

    invokeApi(
      method,
      `${QNET_HOSTNAME}${REST_URLS.PRODUCT_CATEGORY}/${details.id}`,
      convertedDetails
    ).then((response) => {
      if (method === HTTP_METHODS.DELETE) {
        if (response === "ok") {
          toast.success("Product Category deleted successfully", {
            autoClose: 2000,
          });
          setProductDetails(null);
          setDeleteDetails(null);
          loadData(filters);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      }
      if (method === HTTP_METHODS.PUT) {
        if (!response?.message) {
          toast.success("Product Category updated successfully", {
            autoClose: 2000,
          });
          setProductDetails(null);
          setDeleteDetails(null);
          loadData(filters);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      }
    });
  };

  const isDisabled = () => {
    const { name } = productDetails;
    return !name;
  };

  return (
    <div className="products-category-container">
      <div className="main-search-field">
        <div className="search-field">
          <TextField
            placeholder="Search by name"
            variant="outlined"
            color="primary"
            size="small"
            value={filters.name || ""}
            name="name"
            onChange={(event) => {
              event.persist();
              setFilters((prevFilters) => ({
                ...prevFilters,
                name: event?.target?.value,
              }));
            }}
          />
          <SearchIcon
            className="main-search-icon"
            onClick={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page: 1,
              }));
              setFiltersAfterClickingOnApply((prevFilters) => ({
                ...prevFilters,
                name: filters.name,
              }));
              loadData({ ...filters, page: 1 });
            }}
          />
        </div>

        <Button variant="contained" onClick={() => setProductDetails({})}>
          Create
        </Button>
      </div>
      <MuiTable
        columnsList={ProductCategoryConfig()}
        dataList={products.results || []}
        filters={filters}
        pageCount={products.totalPages}
        onClick={onRowClick}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>
      {/* <MaterialUITable
        onChange={(_, page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
        onClick={onRowClick}
        columnsList={ProductCategoryConfig()}
        dataList={products.results || []}
        pageCount={products.totalPages}
        filters={filters}
      /> */}

      {productDetails && (
        <CustomModal
          title={
            productDetails.id ? "Edit Product Category" : "Add Product Category"
          }
          onClose={() => setProductDetails(null)}
        >
          <div className="form-container">
            <TextField
              size="small"
              label="Name"
              value={productDetails.name || ""}
              onChange={onInputChange}
              name="name"
            />
            {/* <TextField
              size="small"
              label="Type"
              value={productDetails.type || ""}
              onChange={onInputChange}
              name="type"
            /> */}

            <Button
              variant="contained"
              fullWidth
              disabled={isDisabled()}
              onClick={() => {
                {
                  productDetails.id
                    ? updateRecord(productDetails)
                    : createRecord();
                }
              }}
            >
              Save
            </Button>
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal
          onNo={() => setDeleteDetails(null)}
          onYes={() => updateRecord({ ...deleteDetails, archive: true })}
        />
      )}
    </div>
  );
};

export default ProductCategory;
