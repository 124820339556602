import { pick } from "../../../common-utilities/utils";
import { CF_APPROVAL_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../utils";
import { invokeApi } from "../../utils/http-service";

export const getFieldList = (filter, setDataFunc) => {
  const finalFilter = pick(
    filter,
    ["fieldName", "fieldValue", "storeType"],
    true
  );
  invokeApi(
    HTTP_METHODS.GET,
    `${CF_APPROVAL_HOSTNAME}${REST_URLS.FIELD_NAME}`,
    null,
    finalFilter
  )
    .then((res) => {
      if (res?.message) {
        setDataFunc([]);
        return;
      }
      const selectVals = getLabelsAndValuesForSelect(res, res, "value", false);
      setDataFunc(selectVals);
    })
    .catch((err) => {
      setDataFunc([]);
    });
};
