/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import SiTable from "../../../common-utilities/core/table";
import { HeaderConfig } from "./config";
import { getOfflineData } from "../../utils/offline-services";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, qnetEndPoint, REST_URLS } from "../../utils/endpoints";
import { DateRangePicker } from "rsuite";
import { ResetPasswordModal } from "../../../common-utilities/components/custom-modals/reset-password.modal";
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  TextField,
} from "@mui/material";
import { getUserRole, USER_ROLES } from "../../utils";
import { CASE_TYPES } from "../create-case/helper";
import ComplaintForm from "../form";
import { getValue } from "../../utils";
import {
  caseStatuses,
  DateRangePickerLabels,
  getAllYearLabels,
  monthLabels,
  sortDateLabels,
  storeTypeLabels,
} from "../../utils/labels";
import CancelIcon from "@mui/icons-material/Cancel";
import { SEVERITY_OPTIONS } from "../view-case/helper";
import CheckBoxSelect from "../../../common-utilities/core/checkbox-select";
import { setCaseFilterForQueryingData } from "../../utils/setcaseFilter";
import { checkForUserRoles } from "../../utils/select-dropwdown-conditions";
import { appSlugs, tokenMainKeys } from "../../../../utils/constants";
import { getSelectDcList } from "../../utils/dc";
import { getSelectVendorList } from "../../utils/vendor";
import Select from "react-select";
import MuiTable from "../../../common-utilities/core/mui-table";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";
const Cases = () => {
  const navigate = useNavigate();
  const [caseList, setCaseList] = useState({});
  const [open, setOpen] = useState(false);
  const [curPage, setCurPage] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const currentUserRoleChecker = checkForUserRoles();
  const [storeOptions, setStoreOptions] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [dcList, setDCList] = useState([]);
  const [complaintTypeList, setComplaintTypeList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productCategoryKeys, setProductCategoryKeys] = useState({});
  const [productList, setProductList] = useState([]);
  const [yearLabels, setYearLabels] = useState([]);
  const [caseFilter, setCaseFilter] = useState({
    startDate: null,
    endDate: null,
    complaintStatus: [],
    severity: [],
    store: [],
    vendor: [],
    dc: [],
    complaintType: [],
    storeType: [],
    productCategoryList: [],
    productList: [],
    complaintId: "",
    month: [],
    year: [],
    sortingOrder: sortDateLabels[1],
    storeId: null,
  });

  const curPageStatuses = {
    home: "",
    complaintForm: "complaintForm",
  };

  useEffect(() => {
    const user = getOfflineData(null, "user");
    const yearLabelList = getAllYearLabels();
    setYearLabels(yearLabelList);
    let additionalDistritorFilter = {};
    const isUserActionTakerOrCreditIssuer =
      currentUserRoleChecker.isDCActionTaker ||
      currentUserRoleChecker.isDCCreditIssuer;
    if (isUserActionTakerOrCreditIssuer) {
      additionalDistritorFilter.getOnlyDistributors = true;
    }
    if (!user) {
      navigate("/");
      return;
    }
    const role = getUserRole();
    const currentUserIsNotStore = role?.type !== USER_ROLES.STORE;
    const isUserStoreOwner = role?.type === USER_ROLES.STORE_OWNER;
    if (currentUserIsNotStore) {
      const params = isUserStoreOwner ? { owner: user?.id } : {};
      invokeApi(
        HTTP_METHODS.GET,
        `${QNET_HOSTNAME}${REST_URLS.STORE_LIST}`,
        null,
        params
      )
        .then((res) => {
          if (!res?.message) {
            const results = res.map((item) => {
              return {
                label: `${item.name}`,
                value: parseInt(item.id),
              };
            });
            setStoreOptions(results);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    (async () => {
      const dc = await getSelectDcList();
      setDCList(dc);
      const vendor = await getSelectVendorList();
      setVendorList(vendor);
    })();

    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.COMPLAINT_TYPE_LIST}`
    )
      .then((res) => {
        if (!res?.message) {
          const results = res.map((item) => {
            return { label: getValue(item.name, true), value: item.id };
          });
          setComplaintTypeList(results);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.GET_ALL_PRODUCT_CATEGORY_NAMES}`
    )
      .then((res) => {
        if (!res?.message) {
          setProductCategoryList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event, complaint) => {
    if (complaint) {
      setComplaintType(complaint);
      setCurPage(curPageStatuses.complaintForm);
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  const user = getOfflineData(null, "user");
  const role = getUserRole();
  const currentUserIsNotVendor = role?.belongsTo !== USER_ROLES.VENDOR;

  const currentUserIsNotDC =
    role?.belongsTo !== USER_ROLES.DC ||
    currentUserRoleChecker.isDCActionTaker ||
    currentUserRoleChecker.isDCCreditIssuer;
  const userIsAdminOrStoreOwner =
    role?.type === USER_ROLES.STORE_OWNER ||
    role?.type === USER_ROLES.STORE ||
    role?.type === USER_ROLES.ADMIN ||
    role?.type === USER_ROLES.RD ||
    role?.type === USER_ROLES.AM;
  const prevOpen = React.useRef(open);
  const isUserNotStoreOwner =
    // user?.role?.type === USER_ROLES.STORE_OWNER ||
    user?.role?.type !== USER_ROLES.STORE_OWNER &&
    user?.role?.type !== USER_ROLES.STORE;

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      if (curPage === curPageStatuses.home) {
        loadData(filters);
      }
    }
  }, [curPage, caseFilter]);

  const loadData = (filtersObj) => {
    const loadDataFilter = { ...filtersObj };
    const {
      complaintStatus,
      severity,
      store,
      vendor,
      dc,
      productList,
      complaintType,
      storeType,
    } = caseFilter;
    if (caseFilter.startDate) {
      loadDataFilter.startDate = caseFilter.startDate;
    }
    if (caseFilter.endDate) {
      loadDataFilter.endDate = loadDataFilter.endDate;
    }
    setCaseFilterForQueryingData(caseFilter, loadDataFilter);
    if (complaintStatus.length > 0) {
      loadDataFilter.status = JSON.stringify(
        complaintStatus.map((item) => item.bl)
      );
    }

    if (severity.length > 0) {
      loadDataFilter.severity = JSON.stringify(
        severity.map((item) => item.value)
      );
    }

    if (store.length > 0) {
      loadDataFilter.store = JSON.stringify(store.map((item) => item.value));
    }

    if (dc.length > 0) {
      loadDataFilter.dcList = JSON.stringify(dc.map((item) => item.value));
    }

    if (productList.length > 0) {
      loadDataFilter.product = JSON.stringify(
        productList.map((item) => item.id)
      );
    }

    if (complaintType.length > 0) {
      loadDataFilter.complaintType = JSON.stringify(
        complaintType.map((item) => item.value)
      );
    }

    if (vendor.length > 0) {
      loadDataFilter.vendorList = JSON.stringify(
        vendor.map((item) => item.value)
      );
    }

    if (storeType.length > 0) {
      loadDataFilter.storeType = JSON.stringify(
        storeType.map((item) => item.bl)
      );
    }
    addCorrectStartEndDate(loadDataFilter);
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.CASES}`,
      null,
      loadDataFilter
    )
      .then((response) => {
        if (response.results) {
          setCaseList(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRowCLick = (caseDetails, action) => {
    if (action === "VIEW") {
      navigate(`${qnetEndPoint}/case/${caseDetails.id}`);
    } else {
      navigate(`${qnetEndPoint}/case-form/${caseDetails.id}`);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const dateRangeHandler = (e) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
    if (!e) {
      setCaseFilter((prevVal) => ({
        ...prevVal,
        startDate: null,
        endDate: null,
      }));
      return;
    }
    setCaseFilter((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };

  const setCaseFilterOnChange = (value, details, productList) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
    const name = typeof details === "object" ? details.name : details;
    let productListFilter = {};
    if (productList) {
      productListFilter = { productList };
    }
    setCaseFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
      ...productListFilter,
    }));
  };

  const getProductListByCategory = (category, curList) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.GET_ALL_PRODUCT_NAMES_BY_CATEGORY}${category}`
    )
      .then((res) => {
        if (!res?.message) {
          const results = res?.map((item) => {
            const label = `${getValue(item?.name, true)} - ${getValue(
              item?.code
            )}`;
            return { ...item, category, label };
          });
          setProductList([...results, ...curList]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="past-tickets-container">
      <div className="create-new">
        <span className="label">Cases</span>
        {userIsAdminOrStoreOwner && curPage === curPageStatuses.home && (
          <div className="mainPopper-container">
            <Button variant="contained" ref={anchorRef} onClick={handleToggle}>
              Create New
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              className="create-new-popper"
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        style={{ zIndex: 5 }}
                        onKeyDown={handleListKeyDown}
                      >
                        {CASE_TYPES.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              onClick={(e) => handleClose(e, item.value)}
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
        {curPage === curPageStatuses.complaintForm && (
          <CancelIcon
            fontSize="large"
            color="error"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setCurPage(curPageStatuses.home);
            }}
          />
        )}
      </div>
      {curPage === curPageStatuses.home && (
        <>
          <div className="case-filter">
            <div>
              <DateRangePicker
                ranges={DateRangePickerLabels}
                cleanable={true}
                className="case-time-picker"
                value={[
                  caseFilter.startDate ? new Date(caseFilter.startDate) : null,
                  caseFilter.endDate ? new Date(caseFilter.endDate) : null,
                ]}
                placeholder="Select time period"
                onChange={dateRangeHandler}
              />
            </div>

            <div>
              <CheckBoxSelect
                options={caseStatuses}
                value={caseFilter.complaintStatus}
                label="Complaint Status"
                labelKey="ul"
                valueKey="bl"
                id="report-status-multi-select"
                onChange={(option) => {
                  setCaseFilterOnChange(option, "complaintStatus");
                }}
              />
            </div>

            {currentUserIsNotVendor && (
              <div>
                <CheckBoxSelect
                  options={vendorList}
                  value={caseFilter.vendor}
                  label="Select Vendor"
                  labelKey="label"
                  valueKey="value"
                  id="report-status-multi-select"
                  onChange={(option) => {
                    setCaseFilterOnChange(option, "vendor");
                  }}
                />
              </div>
            )}
            {currentUserIsNotDC && (
              <div>
                <CheckBoxSelect
                  options={dcList}
                  value={caseFilter.dc}
                  label="Select DC"
                  labelKey="label"
                  valueKey="value"
                  id="report-status-multi-select"
                  onChange={(option) => {
                    setCaseFilterOnChange(option, "dc");
                  }}
                />
              </div>
            )}

            <div>
              <CheckBoxSelect
                options={complaintTypeList}
                value={caseFilter.complaintType}
                label="Select complaint type"
                labelKey="label"
                valueKey="value"
                id="report-status-multi-select"
                onChange={(option) => {
                  setCaseFilterOnChange(option, "complaintType");
                }}
              />
            </div>
            {isUserNotStoreOwner && (
              <div>
                <CheckBoxSelect
                  options={storeTypeLabels}
                  value={caseFilter.storeType}
                  label="Select Store Type"
                  labelKey="ul"
                  valueKey="bl"
                  id="report-status-multi-select"
                  onChange={(option) => {
                    setCaseFilterOnChange(option, "storeType");
                  }}
                />
              </div>
            )}

            <div>
              <CheckBoxSelect
                options={productCategoryList}
                value={caseFilter.productCategoryList}
                label="Select Product Category"
                labelKey="name"
                valueKey="id"
                id="report-status-multi-select"
                onChange={(option) => {
                  const allIds = {};
                  option?.forEach((item) => {
                    allIds[item.id] = true;
                  });
                  const productCategoriesToRemove = {};

                  Object.keys(productCategoryKeys || {}).forEach((item) => {
                    if (allIds[item] === undefined) {
                      productCategoriesToRemove[item] = true;
                    }
                  });

                  let categorySelected = null;
                  Object.keys(allIds || {}).forEach((item) => {
                    if (productCategoryKeys[item] == undefined) {
                      categorySelected = item;
                    }
                  });
                  setProductCategoryKeys(allIds);

                  const allProducts = [...productList];

                  const finalProductList = [];
                  allProducts.forEach((item) => {
                    if (
                      productCategoriesToRemove[item.category] === undefined
                    ) {
                      finalProductList.push(item);
                    }
                  });

                  // console.log(finalProductList, "this is the")
                  if (categorySelected != null) {
                    getProductListByCategory(categorySelected, allProducts);
                  } else {
                    // console.log(finalProductList)
                    setProductList(finalProductList);
                  }
                  const selectedProductsList = [...caseFilter.productList];
                  const finalSelectedProductList = [];
                  selectedProductsList.forEach((item) => {
                    if (
                      productCategoriesToRemove[item.category] === undefined
                    ) {
                      finalSelectedProductList.push(item);
                    }
                  });
                  setCaseFilterOnChange(
                    option,
                    "productCategoryList",
                    finalSelectedProductList
                  );
                }}
              />
            </div>

            <div>
              <CheckBoxSelect
                options={productList}
                value={caseFilter.productList}
                label="Select Product"
                labelKey="label"
                valueKey="id"
                id="report-status-multi-select"
                onChange={(option) => {
                  setCaseFilterOnChange(option, "productList");
                }}
              />
            </div>

            <div>
              <CheckBoxSelect
                options={SEVERITY_OPTIONS}
                value={caseFilter.severity}
                label="Select severity"
                labelKey="label"
                valueKey="value"
                id="report-status-multi-select"
                onChange={(option) => {
                  setCaseFilterOnChange(option, "severity");
                }}
              />
            </div>

            {isUserNotStoreOwner && (
              <div>
                <CheckBoxSelect
                  options={storeOptions}
                  value={caseFilter.store}
                  label="Select Store"
                  labelKey="label"
                  valueKey="value"
                  id="report-status-multi-select"
                  onChange={(option) => {
                    setCaseFilterOnChange(option, "store");
                  }}
                />
              </div>
            )}

            <div>
              <CheckBoxSelect
                options={yearLabels}
                value={caseFilter.year}
                label="Select Year"
                labelKey="ul"
                valueKey="bl"
                id="report-status-multi-select"
                onChange={(option) => {
                  setCaseFilterOnChange(option, "year");
                }}
              />
            </div>

            <div>
              <CheckBoxSelect
                options={monthLabels}
                value={caseFilter.month}
                label="Select Month"
                labelKey="ul"
                valueKey="bl"
                id="report-status-multi-select"
                onChange={(option) => {
                  setCaseFilterOnChange(option, "month");
                }}
              />
            </div>

            <div>
              <Select
                options={sortDateLabels}
                placeholder="Select sorting order"
                classNamePrefix="subway-select"
                className="input case-filter-select"
                name="sortingOrder"
                value={caseFilter?.sortingOrder || null}
                onChange={setCaseFilterOnChange}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 3 }),
                }}
              />
            </div>
          </div>
        </>
      )}
      {curPage === curPageStatuses.home && (
        <>
          <div className="case-filter">
            <div>
              <TextField
                size="small"
                className="case-addition-filter"
                type="number"
                value={caseFilter.complaintId || ""}
                onChange={(e) => {
                  setCaseFilterOnChange(e?.target?.value || "", "complaintId");
                }}
                placeholder="Select Case ID"
              />
            </div>
            <div>
              <TextField
                size="small"
                className="case-addition-filter"
                type="number"
                value={caseFilter.storeId}
                onChange={(e) => {
                  setCaseFilterOnChange(e?.target?.value || "", "storeId");
                }}
                placeholder="Select Store ID"
              />
            </div>
            <div></div>
          </div>
          <MuiTable
            columnsList={HeaderConfig()}
            dataList={caseList.results || []}
            filters={filters}
            pageCount={caseList.totalPages}
            onClick={onRowCLick}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></MuiTable>
          {/* <SiTable
            header={HeaderConfig()}
            data={caseList.results || []}
            pageCount={caseList.totalPages}
            filters={filters}
            customSiRowClass={`${
              isUserNotStoreOwner ? "admin-store-owner-row" : "other-si-row"
            }`}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
            onClick={onRowCLick}
          ></SiTable> */}
        </>
      )}

      {curPage === curPageStatuses.complaintForm && (
        <ComplaintForm
          complaintType={complaintType}
          curPageStatuses={curPageStatuses}
          setCurPage={setCurPage}
          loadData={() => loadData(filters)}
        />
      )}
      <ResetPasswordModal
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
        tokenKey={tokenMainKeys.qnet}
        url={`${QNET_HOSTNAME}${REST_URLS.EDIT_USER}`}
        slug={appSlugs.qnet}
        resetPasswordKey="changePasswordOnFirstLogin"
      />
    </div>
  );
};

export default Cases;
