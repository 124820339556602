import React, { useState, useEffect } from "react";
import { invokeApi } from "../../utils/http-service";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import Select from "react-select";
import { DateRangePicker } from "rsuite";
import { getHeaderConfig } from "./helper";
import { lastMonthDate } from "./tickets";
import { exportToExcel } from "../../../common-utilities/utils";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import SearchIcon from "@mui/icons-material/Search";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import {
  getEndOfDay,
  getStartOfDay,
} from "../../../common-utilities/utils/time";

const SupportPerson = ({ intranetTokenKey }) => {
  const [reportData, setReportData] = useState({});
  const [supportPerson, setSupportPerson] = useState({});
  const [serviceEngineers, setServiceEngineers] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
    assignedTo: null,
    supportPersonLabel: "",
  });
  useEffect(() => {
    otherOptions();
  }, []);
  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  const fetchDataHandler = (filter, getDataForPreviouslyAppliedFilter) => {
    const params = {
      ...filter,
      startDate: dateRange.startDate
        ? getStartOfDay(dateRange.startDate)
        : null,
      endDate: dateRange.endDate ? getEndOfDay(dateRange.endDate) : null,
      assignedTo: supportPerson.key,
      sortBy: "-createdAt",
    };
    if (getDataForPreviouslyAppliedFilter) {
      params.startDate = filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null;
      params.assignedTo = filterAfterClickingOnApply.assignedTo;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      startDate: filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null,
      endDate: filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null,
      assignedTo: filterAfterClickingOnApply.assignedTo,
    };
    let response = await invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    );
    let results = [];
    if (response && response.results) {
      results = response.results.map((item) => {
        const Status =
          item?.status.slice(0, 1).toUpperCase() +
          item?.status.slice(1).toLowerCase();
        const {
          id,
          sla,
          reopened: Reopened,
          closed: Closed,
          resolveMessage,
          holdEscalations,
          dept,
          category,
          store,
          assignedTo,
          severity: Severity,
        } = item;
        return {
          "Ticket Id": id,
          "Store Number": store?.storeNumber,
          Status,
          SLA: sla,
          Assignee: assignedTo?.name,
          Reopened: Reopened ? "Yes" : "No",
          Category: category?.name,
          Closed: Closed ? "Yes" : "No",
          "Resolved Message": resolveMessage,
          "Hold Escalations": holdEscalations ? "Yes" : "No",
          Department: dept?.name,
          Store: store?.name,
          Severity: Severity
            ? Severity.slice(0, 1).toUpperCase() +
              Severity.slice(1).toLowerCase()
            : "",
        };
      });
    }
    exportToExcel(results, filterAfterClickingOnApply.supportPersonLabel);
  };

  const otherOptions = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.ADMINANDTECHNETUSERSLIST}`,
      null,
      { getAgent: true },
      null,
      intranetTokenKey
    )
      .then((response) => {
        if (response) {
          setServiceEngineers(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s w-100">
          <div className="mw-30">
            <AutocompleteComponent
              value={
                supportPerson?.key
                  ? {
                      label: supportPerson?.label || "",
                      value: supportPerson?.key,
                    }
                  : null
              }
              classNamePrefix="si-select"
              options={serviceEngineers}
              onChange={(e, option) => {
                setSupportPerson((prevVal) => ({
                  ...prevVal,
                  key: option.value,
                  label: option.label,
                }));
              }}
              fullWidth={true}
              disableClear={true}
              label="Support Person"
            />
          </div>

          {supportPerson.key && (
            <div className="left-s">
              <div>
                <DateRangePicker
                  cleanable={false}
                  placeholder="Select Date Range"
                  onChange={dateRangeHandler}
                />
              </div>
              <SearchIcon
                onClick={() => {
                  setFilterAfterClickingOnApply({
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    assignedTo: supportPerson?.key,
                    supportPersonLabel: supportPerson?.label,
                  });
                  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                  fetchDataHandler({ ...filters, page: 1 });
                }}
                sx={{ cursor: "pointer", marginBottom: 1 }}
              />
            </div>
          )}
        </div>

        {supportPerson.key && (reportData.results || []).length !== 0 && (
          <DownloadBtn className="mb-5" onClick={downloadReport} />
        )}
      </div>
      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={reportData.results || []}
        filters={filters}
        nestedValue={true}
        pageCount={reportData.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>
      {!supportPerson.key && (
        <span className="user-info-msg">
          Please select the Support Person to download reports
        </span>
      )}
    </div>
  );
};

export default SupportPerson;
