import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { invokeApi } from "../../utils/http-service";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import "./index.scss";
import SupportPerson from "./SupportPerson";
import Overview from "./Overview";
import Store from "./Store";
import SlaBreaches from "./SlaBreaches";
import TimeRelated from "./TimeRelated";
import DepartmentAndCategory from "./DepartmentAndCategory";
import { DateRangePicker } from "rsuite";
import { Grid } from "@mui/material";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { userTypes } from "../../utils/constants";
import { tokenMainKeys } from "../../../../utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { overviewReports } from "./utils";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import CommonBtn from "../../../common-utilities/core/common-button";
import {
  getEndOfDay,
  getStartOfDay,
} from "../../../common-utilities/utils/time";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Dashboard = () => {
  const [dashboardDetails, setDashboardDetails] = useState({
    overview: {},
    deptAndCategory: {},
    store: [],
    mtt: {},
    sla: [],
    support: {},
  });
  const [datePickerstartDate, setdatePickerStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
  );
  const [datePickerendDate, setdatePickerEndDate] = useState(
    new Date().toISOString()
  );
  const [value, setValue] = useState(0);
  const [storeIndex, setstoreIndex] = useState(3);
  const [showSelectedTabs, setshowSelectedTabs] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [toggle, setToggle] = useState("Department-wise Escalation");
  const [showAllData, setshowAllData] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setDatePickerDate = (value) => {
    const newStartDate = value[0].toISOString();
    const newEndDate = value[1].toISOString();
    setdatePickerStartDate(newStartDate);
    setdatePickerEndDate(newEndDate);
  };

  const applyDatePickerDate = () => {
    setStartDate(datePickerstartDate);
    setEndDate(datePickerendDate);
  };

  const clearDateRangeFilter = () => {
    setdatePickerStartDate(
      new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      ).toISOString()
    );
    setdatePickerEndDate(new Date().toISOString());
  };

  useEffect(() => {
    const userCredentials = getOfflineData(tokenMainKeys.technet, "user");
    if (userCredentials) {
      const { type } = userCredentials;
      if (type === userTypes.STORE_OWNER) {
        setstoreIndex(2);
        setshowSelectedTabs(true);
      } else {
        setstoreIndex(3);
        setshowSelectedTabs(false);
      }
    }
  }, []);

  useEffect(() => {
    let usertype;
    let storeOwnerParams = {};
    const usercredentials = getOfflineData(tokenMainKeys.technet, "user");
    if (usercredentials && usercredentials.type === userTypes.STORE_OWNER) {
      storeOwnerParams = { storeOwner: usercredentials.id };
      usertype = userTypes.STORE_OWNER;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.DASHBOARD}`,
      null,
      {
        startDate: getStartOfDay(startDate),
        endDate: getEndOfDay(endDate),
        ...storeOwnerParams,
      }
    )
      .then((response) => {
        if (response) {
          setshowAllData(false);
          setTimeout(() => {
            setshowAllData(true);
          }, 150);
          let overviewObj = {};
          overviewObj["total"] = response.overviewStats?.totalTickets;
          overviewObj["reOpen"] = response.overviewStats?.reopenedTickets;
          overviewObj["open"] = response.overviewStats?.openTicketCount;
          overviewObj["raisedCount"] = response.overviewStats?.last30DayCount;
          overviewObj["topIssueCausingStore"] =
            response.overviewStats?.topIssueCausingStore?.length > 0
              ? response.overviewStats.topIssueCausingStore[0]
              : null;
          overviewObj["topIssueCausingDept"] =
            response.overviewStats?.topIssueCausingDept?.length > 0
              ? response.overviewStats.topIssueCausingDept[0]
              : null;
          overviewObj["topIssueCausingCategory"] =
            response.overviewStats?.topIssueCausingCategory?.length > 0
              ? response.overviewStats.topIssueCausingCategory[0]
              : null;
          overviewObj["topPerformer"] =
            response.overviewStats?.topPerformer?.length > 0
              ? response.overviewStats.topPerformer[0]
              : null;
          overviewObj["mttc"] = response.overviewStats?.mttc;
          overviewObj["mttr"] = response.overviewStats?.mttr;
          overviewObj["topSLABreachedDept"] =
            response.overviewStats?.topSLABreachedDept?.length > 0
              ? response.overviewStats.topSLABreachedDept[0]
              : null;
          overviewObj["monthWiseTicketCounts"] = response.monthWiseTicketCounts;
          overviewObj["withoutResolution"] =
            response.overviewStats?.unresolvedTicketsByX;

          let deptAndCategoryWiseStats =
            response.deptAndCategoryWiseStats?.length > 0
              ? response.deptAndCategoryWiseStats[0]
              : null;
          const tempStore =
            response.storeWise?.length > 0 ? response.storeWise : [];

          if (usertype !== userTypes.STORE_OWNER) {
            setDashboardDetails((prevDashboardDetails) => ({
              overview: { ...prevDashboardDetails.overview, ...overviewObj },
              deptAndCategory: {
                ...prevDashboardDetails.deptAndCategory,
                ...deptAndCategoryWiseStats,
              },
              store: tempStore,
              mtt: {
                mttr: response?.mttrList?.length > 0 ? response.mttrList : [],
                mttc: response?.mttcList?.length > 0 ? response.mttcList : [],
              },
              sla:
                response.slaCounts?.escalationStats?.length > 0
                  ? response.slaCounts.escalationStats
                  : [],
              support: response.supportPersonStats,
            }));
          } else {
            setDashboardDetails((prevDashboardDetails) => ({
              overview: { ...prevDashboardDetails.overview, ...overviewObj },
              deptAndCategory: {
                ...prevDashboardDetails.deptAndCategory,
                ...deptAndCategoryWiseStats,
              },
              store: tempStore,
            }));
          }
        }
      })
      .catch((err) => console.log(err));
  }, [startDate, endDate]);

  return (
    <>
      <div>
        <div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="technet-tab-container "
          >
            <Grid container spacing={1} md={12}>
              <Grid item md={6} xs={12} sm={7} className="custom-tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  scrollButtons
                  variant="scrollable"
                  allowScrollButtonsMobile
                >
                  <Tab label="Overview" />
                  <Tab label="Department & Category" />
                  {!showSelectedTabs && <Tab label="Support Person" />}
                  <Tab label="Store" />
                  {!showSelectedTabs && <Tab label="SLA Breaches" />}
                  {!showSelectedTabs && <Tab label="Time Related" />}
                </Tabs>
              </Grid>
              <Grid item md={3.5} xs={12} sm={5} className="date-container">
                <div className="dateFilter">
                  <DateRangePicker
                    cleanable={false}
                    placeholder="Select Date Range"
                    onChange={setDatePickerDate}
                    value={[
                      new Date(datePickerstartDate),
                      new Date(datePickerendDate),
                    ]}
                  />
                </div>
              </Grid>
              <Grid
                item
                md={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <CommonBtn
                  className="apply"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={applyDatePickerDate}
                  text="Apply"
                />
              </Grid>
              <Grid
                item
                md={0.5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {value === 0 && (
                  <DownloadBtn
                    onClick={() => overviewReports(dashboardDetails)}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      <div className="dashboard-body">
        {showAllData && (
          <div>
            <TabPanel value={value} index={0}>
              <Overview data={dashboardDetails.overview} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DepartmentAndCategory data={dashboardDetails.deptAndCategory} />
            </TabPanel>

            {!showSelectedTabs && (
              <TabPanel value={value} index={2}>
                <SupportPerson data={dashboardDetails.support} />
              </TabPanel>
            )}

            <TabPanel value={value} index={storeIndex}>
              <Store data={dashboardDetails.store} />
            </TabPanel>

            {!showSelectedTabs && (
              <>
                <TabPanel value={value} index={4}>
                  <SlaBreaches
                    toggle={toggle}
                    setToggle={setToggle}
                    data={dashboardDetails.sla ? dashboardDetails.sla : []}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TimeRelated data={dashboardDetails.mtt} />
                </TabPanel>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
