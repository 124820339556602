import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Autocomplete, Button, TextField } from "@mui/material";
import CustomModal from "../../../../common-utilities/core/modal";
import "./index.scss";
import { exportToExcel } from "../../../../common-utilities/utils";
import { isCreateAllowed } from "../../../utils/common-utils";
import ConfirmModal from "../../../../common-utilities/core/confirm-modal";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../common-components/upload-template";
import { uploadTemplates } from "../constants";
import { toast } from "react-toastify";
import { formatDownloadReports } from "./utils";
import { setProperty } from "../../../../common-utilities/utils/misc";

const CATEGORY_TYPES = [
  { label: "Budget Category", value: "budget_category" },
  { label: "PO Category", value: "po_category" },
];
const CAPEX_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const CategoryList = ({}) => {
  const [budgetModal, setBudgetModal] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [budgetList, setBudgetList] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [capexInputValue, setCapexInputValue] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (filters) => {
    let params = {
      ...filters,
    };
    if (searchKey) {
      params.searchKey = searchKey;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CATEGORY}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (params?.page > response?.totalPages) {
            setProperty("page", response?.totalPages, setFilters);
          }
          setBudgetList(response || []);
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    const { categoryType, capex } = formDetails;
    let payload = {
      name: formDetails.name,
      // categoryType: categoryType?.value,
      capex: capex?.value || false,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CATEGORY}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        setBudgetModal(false);
        loadData(filters);
        setFormDetails({});
        setCapexInputValue("");
        setInputValue("");
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    const { categoryType, capex } = formDetails;

    let payload = {
      name: formDetails.name,
      // categoryType: categoryType?.value,
      capex: capex?.value || false,
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CATEGORY}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        setBudgetModal(false);
        loadData({
          page: 1,
          limit: 10,
        });
        setFormDetails({});
        setCapexInputValue("");
        setInputValue("");
      })
      .catch((err) => {});
  };

  const editRecord = (data) => {
    const { capex = "" } = data;
    delete data.categoryType;
    setBudgetModal(true);
    let formDetailsNew = {
      ...data,
      // categoryType: CATEGORY_TYPES.find((c) => c.value === categoryType),
      capex: CAPEX_OPTIONS.find((c) => c.value === capex),
    };
    setFormDetails({
      ...formDetailsNew,
    });
    setCapexInputValue(formDetailsNew.capex.label);
    // setInputValue(formDetailsNew.categoryType.label);
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CATEGORY}`,
      null,
      { page: 1, limit: budgetList.totalResults || 1000 }
    )
      .then((response) => {
        if (response.message) {
        } else {
          exportToExcel(
            formatDownloadReports(response.results),
            "category-list"
          );
        }
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CATEGORY}/${deleteDetails.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        if (response) {
          setDeleteDetails(null);
          loadData(filters);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="category-list">
      <div className="cl-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                page: 1,
                limit: 10,
              })
            }
          >
            Search
          </Button>
        </div>
        <div className="left-container">
          {isCreateAllowed("master_data") && (
            <>
              <FileUpload
                buttonBackground="#fff"
                buttonColor="#1756f3"
                mainButtonClass="subway-finance-bulk-upload"
                borderColor="rgba(0, 138, 82, 0.5)"
                uploadText="BULK UPLOAD"
                clsName="finance-master-data-uploader"
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}category`}
                callBack={() =>
                  loadData({
                    page: filters.page,
                    limit: 10,
                  })
                }
                formDataKey="file"
              />
              <DownloadTemplateButton type={uploadTemplates.category} />
            </>
          )}

          <Button
            variant="outlined"
            disabled={!budgetList.totalResults}
            onClick={downloadData}
          >
            Download
          </Button>
          {isCreateAllowed("master_data") && (
            <Button variant="outlined" onClick={() => setBudgetModal(true)}>
              Create
            </Button>
          )}
        </div>
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editRecord, setDeleteDetails)}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />

      {budgetModal && (
        <CustomModal
          title={formDetails.id ? "Update Category" : "Create Category"}
          disabledOutsideClick={true}
          onClose={() => {
            setFormDetails({});
            setCapexInputValue("");
            setInputValue("");
            setBudgetModal(false);
          }}
        >
          <div className="create-form">
            <TextField
              value={formDetails.name}
              name="name"
              onChange={onInputChange}
              size="small"
              label="Category Name"
              fullWidth
            />

            {/* <Autocomplete
              options={CATEGORY_TYPES}
              value={formDetails.categoryType || ''}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Category Type" />
              )}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setInputValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  categoryType: val,
                });
              }}
            /> */}

            <Autocomplete
              options={CAPEX_OPTIONS}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Capex" />
              )}
              inputValue={capexInputValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setCapexInputValue(newInputValue);
                }
              }}
              value={formDetails.capex || ""}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  capex: val,
                });
              }}
            />

            {formDetails.id ? (
              <Button
                fullWidth
                variant="contained"
                onClick={onUpdate}
                disabled={!isCreateAllowed("master_data")}
              >
                Update
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                onClick={onSave}
                disabled={!isCreateAllowed("master_data")}
              >
                Create
              </Button>
            )}
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal onYes={onDelete} onNo={() => setDeleteDetails(null)} />
      )}
    </div>
  );
};

export default CategoryList;
