import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { CF_APPROVAL_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { useEffect, useState, useCallback } from "react";
import { getHeaderConfig } from "./config";
import { ApprovalModal, DownloadModal } from "../customerModals";
import "./index.scss";
import { getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import {
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
} from "../../utils/constants";
import { RejectApproval } from "../customerModals/reject-approval.modal";
import MuiTable from "../../../common-utilities/core/mui-table";
import { setApproverModalDetails } from "./utils";

export const UnApprovedForms = ({
  setCountFunction,
  setReloadPendingApprovals,
  setMainModalDetails,
  commonFilters,
}) => {
  const [unapprovedForms, setunapprovedForms] = useState({});
  const [tableItems, setTableItems] = useState(null);
  const [unapprovedModalData, showunapprovedModalData] = useState(null);
  const [modalDetails, setModalDetails] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const closeModal = () => {
    setModalDetails(null);
  };
  const loadData = useCallback(
    (params) => {
      const filterParams = params ? params : filters;

      invokeApi(
        HTTP_METHODS.GET,
        `${CF_APPROVAL_HOSTNAME}${REST_URLS.UNAPPROVED_FORMS}`,
        null,
        { ...filterParams, ...commonFilters }
      )
        .then((response) => {
          if (response) {
            const count = response?.totalResults;
            if (count) {
              setCountFunction(count);
            } else {
              setCountFunction(0);
            }

            setunapprovedForms(response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [filters, setCountFunction, commonFilters]
  );

  const onClickShowDataFunction = (data, type, approverNo) => {
    if (data) {
      switch (type) {
        case "reject approval":
          setModalDetails({
            data,
            type: "reject approval",
          });
          break;
        case "Approver":
          setApproverModalDetails(data, setMainModalDetails, approverNo);
          break;
        default:
          const isCocoAcquired = data?.storeType === storeTypes.cocoAcquired;
          const oddLabel = isCocoAcquired ? "ODD" : "Site approval pack";
          let finalData = [
            { label: "Unit Economics Model", value: "" },
            { label: oddLabel, value: "" },
          ];
          const { uploadedDocuments, snapShot, approvalEmail } = data;
          if (uploadedDocuments) {
            const {
              fiveyearplan,
              newStoreDetailsPPT,
              newStoreDetailsExcel,
              approvalEmail,
            } = uploadedDocuments;
            finalData[0].value = fiveyearplan;
            finalData[1].value = newStoreDetailsPPT;
            if (!isCocoAcquired) {
              finalData.push({
                label: "New Store Details (excel)",
                value: newStoreDetailsExcel,
              });
            } else {
              finalData.push({
                label: "Approver Email",
                value: approvalEmail || "",
              });
            }
          }
          if (snapShot) {
            const {
              unitEconomicModel,
              competitionAndNearbyRestaurantSales,
              gapmapSnapshot,
            } = snapShot;

            finalData = [
              ...finalData,
              {
                label: "Unit Economics Model UEM Cover",
                value: unitEconomicModel,
              },
              {
                label: `${
                  isCocoAcquired
                    ? cocoAcqKeyNames.competitionAndNearbyRestaurantSales
                    : cocoBuiltKeyNames.competitionAndNearbyRestaurantSales
                }`,
                value: competitionAndNearbyRestaurantSales,
              },
              {
                label: `${
                  isCocoAcquired ? "Total Acquisition Cost" : "Gapmap snapshot"
                }`,
                value: gapmapSnapshot,
              },
            ];
          }
          setTableItems(finalData);
          break;
      }
    }
  };
  useEffect(() => {
    loadData(filters);
  }, [filters, loadData, commonFilters]);

  const approveTicketForStage = (id, approver, commentKey, comment) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${CF_APPROVAL_HOSTNAME}${REST_URLS.APPROVE_FORM}`,
      {
        id,
        approver,
        [commentKey]: comment,
      }
    )
      .then((response) => {
        if (response && response.message) {
          toast.error(response.message);
          return;
        } else {
          loadData(filters);
          setReloadPendingApprovals(true);
          toast.success("Form approved successfully");
        }
      })
      .catch((err) => {
        toast.error("Sorry something went wrong");
        console.log(err);
      });
  };
  const approveForm = (data) => {
    const user = getOfflineData("user");
    if (user && user.id && data && data.id) {
      const userid = parseInt(user.id);
      const dataid = parseInt(data.id);
      if (parseInt(data?.approver1?._id) === userid) {
        approveTicketForStage(
          dataid,
          "approver1",
          "approver1Comment",
          data.comment
        );
      } else if (parseInt(data?.approver2?._id) === userid) {
        approveTicketForStage(
          dataid,
          "approver2",
          "approver2Comment",
          data.comment
        );
      } else if (parseInt(data?.approver3?._id) === userid) {
        approveTicketForStage(
          dataid,
          "approver3",
          "approver3Comment",
          data.comment
        );
      } else if (parseInt(data?.approver4?._id) === userid) {
        approveTicketForStage(
          dataid,
          "approver4",
          "approver4Comment",
          data.comment
        );
      } else if (parseInt(data?.approver5?._id) === userid) {
        approveTicketForStage(
          dataid,
          "approver5",
          "approver5Comment",
          data.comment
        );
      } else if (parseInt(data?.approver6?._id) === userid) {
        approveTicketForStage(
          dataid,
          "approver6",
          "approver6Comment",
          data.comment
        );
      }
    }
  };

  return (
    <div className="approval-container unapproved">
      <MuiTable
        columnsList={getHeaderConfig(true, showunapprovedModalData)}
        dataList={unapprovedForms.results || []}
        filters={filters}
        pageCount={unapprovedForms?.totalPages}
        onClick={onClickShowDataFunction}
        nestedValue={true}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></MuiTable>
      {tableItems && (
        <DownloadModal
          title="Uploaded files"
          closeModal={setTableItems}
          data={tableItems}
          tableClassName
        />
      )}

      {unapprovedModalData && (
        <ApprovalModal
          title="Approve"
          closeModal={showunapprovedModalData}
          approveFunction={approveForm}
          approvalData={unapprovedModalData}
          customModalContentClass="approvalModal"
        />
      )}
      <RejectApproval
        data={modalDetails?.data}
        showModal={modalDetails?.type === "reject approval"}
        closeModal={closeModal}
        onSuccess={() => {
          closeModal();
          loadData();
        }}
      />
    </div>
  );
};
