import React, { useEffect, useState } from 'react';
import { FINANCE_HOSTNAME } from '../../../../common-utilities/utils/end-points';
import { HTTP_METHODS, invokeApi } from '../../../utils/http-service';
import { getHeaderConfig } from './helper';
import './index.scss';
import MuiTable from '../../../../common-utilities/core/mui-table';
import { FINANCE_REST_URLS } from '../../../../common-utilities/utils/end-points/finance';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import DateRangeFilter from '../../../../common-utilities/core/date-range-filter';
import {
  getStartOfDay,
  getEndOfDay,
} from '../../../../common-utilities/utils/time';

const InvoiceApprovalList = ({ status, approvalType, invoiceType }) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [filters, setFilters] = useState({
    approvalType: approvalType,
    invoiceType: invoiceType || 'grn_invoice',
    sortBy: '-createdAt',
    populateSite: true,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    let params = {
      ...filters,
    };
    if (searchKey) {
      params.poGrnNo = searchKey;
    }
    if (status) {
      params.status = status;
    }
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_MY_APPROVALS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          setBudgetList(response);
        }
      })
      .catch((err) => {});
  };

  const viewBudget = (data) => {
    const { document } = data;

    navigate(
      `/subway-finance/invoice-data/${document._id}?approvalId=${
        data.id
      }&from=${
        filters?.invoiceType === 'grn_invoice' ? 'GRN' : 'ADVANCE_INVOICE'
      }`
    );
  };

  return (
    <div className="grn-approval-list">
      <div className="filter-row">
        <TextField
          placeholder="Search"
          value={searchKey}
          size="small"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        />
        <Button variant="outlined" onClick={loadData}>
          Search
        </Button>
      </div>
      <MuiTable
        nestedValue={true}
        columnsList={getHeaderConfig(viewBudget, invoiceType)}
        dataList={budgetList.slice((filters.page - 1) * 10, 10) || []}
        filters={filters}
        pageCount={Math.floor(budgetList.length / 10)}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
        }}
      />
    </div>
  );
};

export default InvoiceApprovalList;
