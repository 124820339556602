/** @format */

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button } from "@mui/material";
import { getSLAHeaderConfig } from "./helper";
import SiTable from "../../../common-utilities/core/table";
import { invokeApi } from "../../utils/http-service";
import { DateRangePicker } from "rsuite";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { lastMonthDate } from "./tickets";
import "./index.scss";
import { exportToExcel } from "../../../common-utilities/utils";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import SearchIcon from "@mui/icons-material/Search";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import {
  getEndOfDay,
  getStartOfDay,
} from "../../../common-utilities/utils/time";
const SLA = ({ intranetTokenKey }) => {
  const [reportData, setReportData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [department, setDepartment] = useState({});
  const [departmentsList, setDepartmentsList] = useState({});
  const [categoryList, setCategoryList] = useState({});
  const [category, setCategory] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
    department: null,
    departmentLabel: "",
    category: null,
  });

  useEffect(() => {
    otherOptions();
  }, []);
  useEffect(() => {
    if (department.key) {
      getCategories();
    }
    setCategory({});
  }, [department]);
  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  const fetchDataHandler = (
    filter,
    setFiltersAccordingToPreviosulyAppliedFilters
  ) => {
    const params = {
      ...filter,
      dept: department.key,
      startDate: dateRange.startDate
        ? getStartOfDay(dateRange.startDate)
        : null,
      endDate: dateRange.endDate ? getEndOfDay(dateRange.endDate) : null,
      sortBy: "-createdAt",
    };
    if (category.key) params["category"] = category.key;

    if (setFiltersAccordingToPreviosulyAppliedFilters) {
      params.startDate = filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null;
      params.dept = filterAfterClickingOnApply.department;
      if (
        filterAfterClickingOnApply.category ||
        filterAfterClickingOnApply.category === 0
      ) {
        params.category = filterAfterClickingOnApply.category;
      } else {
        delete params.category;
      }
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.SLA_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      dept: department.key,
      startDate: dateRange.startDate
        ? getStartOfDay(dateRange.startDate)
        : null,
      endDate: dateRange.endDate ? getEndOfDay(dateRange.endDate) : null,
    };

    params.startDate = filterAfterClickingOnApply.startDate
      ? getStartOfDay(filterAfterClickingOnApply.startDate)
      : null;
    params.endDate = filterAfterClickingOnApply.endDate
      ? getEndOfDay(filterAfterClickingOnApply.endDate)
      : null;
    params.dept = filterAfterClickingOnApply.department;
    if (
      filterAfterClickingOnApply.category ||
      filterAfterClickingOnApply.category === 0
    ) {
      params.category = filterAfterClickingOnApply.category;
    }
    let response = await invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.SLA_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    );
    let results = [];
    if (response && response.results) {
      results = response.results.map((item) => {
        const {
          // id: Id,
          level: Level,
          escalated: Escalated,
          dept,
          category,
          ticket,
          escalateTo,
          escalationEmails,
        } = item;
        let allEscalationEmails = "";
        if (escalationEmails && Array.isArray(escalationEmails)) {
          const checker = {};
          escalationEmails.forEach((item, index) => {
            if (item.email && checker[item.email] == undefined) {
              checker[item.email] = true;
            }
          });
          const length = Object.keys(checker).length;

          Object.keys(checker).map((item, index) => {
            if (index === length - 1) {
              allEscalationEmails += item;
            } else {
              allEscalationEmails += item + ",";
            }
          });
        }
        return {
          // Id,
          Level,
          Escalated: Escalated ? "Yes" : "No",
          Department: dept?.name,
          Category: category?.name,
          "Ticket Id": ticket?.id,
          "Store Number": ticket?.store?.storeNumber,
          EscalateTo: escalateTo?.name,
          "Escalation Emails": allEscalationEmails,
        };
      });
    }

    exportToExcel(results || [], filterAfterClickingOnApply.departmentLabel);
  };
  const otherOptions = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`,
      null,
      null,
      null,
      intranetTokenKey
    )
      .then((response) => {
        if (response) {
          setDepartmentsList(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategories = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_CATEGORY_DEPARTMENT_WISE}${department.key}`,
      null,
      null,
      null,
      intranetTokenKey
    )
      .then((response) => {
        if (response) {
          setCategoryList(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s w-100">
          <div className="mw-30">
            <AutocompleteComponent
              value={
                department?.key
                  ? { label: department.label, value: department.key }
                  : null
              }
              classNamePrefix="si-select"
              options={departmentsList}
              onChange={(e, option) => {
                setDepartment((e, prevVal) => ({
                  ...prevVal,
                  key: option.value,
                  label: option.label,
                }));
              }}
              label="Department"
              fullWidth={true}
              disableClear={true}
            />
          </div>

          {department.key && (
            <div className="left-s w-100">
              <div className="mw-30">
                <AutocompleteComponent
                  value={
                    category?.key
                      ? { label: category?.label, value: category?.key }
                      : null
                  }
                  classNamePrefix="si-select"
                  options={categoryList}
                  label="Category"
                  onChange={(e, option) => {
                    setCategory((prevVal) => ({
                      ...prevVal,
                      key: option.value,
                      label: option.label,
                    }));
                  }}
                  fullWidth={true}
                  disableClear={true}
                />
              </div>

              <div>
                <DateRangePicker
                  cleanable={false}
                  placeholder="Select Date Range"
                  onChange={dateRangeHandler}
                />
              </div>
              <SearchIcon
                onClick={() => {
                  setFilterAfterClickingOnApply({
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    department: department.key,
                    departmentLabel: department.label,
                    category: category.key,
                  });
                  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                  fetchDataHandler({ ...filters, page: 1 });
                }}
                sx={{ cursor: "pointer", marginBottom: 1 }}
              />
            </div>
          )}
        </div>
        {department.key && (reportData.results || []).length !== 0 && (
          <DownloadBtn className="mb-5" onClick={downloadReport} />
        )}
      </div>
      <MuiTable
        columnsList={getSLAHeaderConfig()}
        dataList={reportData.results || []}
        filters={filters}
        pageCount={reportData.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler(
            {
              ...filters,
              page,
            },
            true
          );
        }}
        nestedValue={true}
      ></MuiTable>
      {!department.key && (
        <span className="user-info-msg">
          Please select the Department to download reports
        </span>
      )}
    </div>
  );
};

export default SLA;
