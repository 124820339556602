import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { Autocomplete, Button, TextField } from "@mui/material";
import CustomModal from "../../../../common-utilities/core/modal";
import "./index.scss";
import { exportToExcel } from "../../../../common-utilities/utils";
import { isCreateAllowed } from "../../../utils/common-utils";
import ConfirmModal from "../../../../common-utilities/core/confirm-modal";
import { toast } from "react-toastify";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../common-components/upload-template";
import { uploadTemplates } from "../constants";
import PriceHistory from "./history";
import { UOM_LIST } from "../../purchase-order/helper";
import { clearProperty } from "../../../../common-utilities/utils/misc";
import { formatExcelHeaders } from "./utils";
import { setProperty } from "../../../../common-utilities/utils/misc";

const ItemList = ({}) => {
  const [budgetModal, setBudgetModal] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [budgetList, setBudgetList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const [inputValue, setInputValue] = useState("");
  const [subCategoryInputValue, setSubCategoryInputValue] = useState("");
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [priceHistoryDetails, setPriceHistoryDetails] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [inputUnitValue, setInputUnitValue] = useState("");

  useEffect(() => {
    loadData(filters);
    loadCategory();
  }, []);

  const loadData = (filters) => {
    let params = {
      ...filters,
    };
    if (searchKey) {
      params.searchKey = searchKey;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ITEMS}`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
        } else {
          if (params?.page > response?.totalPages) {
            setProperty("page", response?.totalPages, setFilters);
          }
          setBudgetList(response || []);
        }
      })
      .catch((err) => {});
  };

  const loadCategory = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.CATEGORY}`,
      null,
      { limit: 1000, page: 1 }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setCategoryList(
            (response.results || []).map((c) => {
              return { label: c.name, value: c.id, id: c.id };
            })
          );
        }
      })
      .catch((err) => {});
  };

  const loadSubcategory = (categoryId) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.SUBCATEGORY}`,
      null,
      { limit: 1000, page: 1, category: categoryId }
    )
      .then((response) => {
        if (response.message) {
        } else {
          setSubcategoryList(
            (response.results || []).map((c) => {
              return { label: c.name, value: c.id, id: c.id };
            })
          );
        }
      })
      .catch((err) => {});
  };

  const onSave = () => {
    const { category = {}, subCategory = {}, uom = {} } = formDetails;
    let payload = {
      name: formDetails.name,
      price: formDetails.price,
      taxPercentage: formDetails.taxPercentage || 0,
      specification: formDetails.specification,
      hsnCode: formDetails.hsnCode,
    };
    if (uom?.value) {
      payload.uom = uom?.value;
    }

    if (category?.id) {
      payload.category = category.id;
    }

    if (subCategory?.id) {
      payload.subCategory = subCategory.id;
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ITEMS}`,
      payload
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
        }
      })
      .catch((err) => {});
  };

  const onUpdate = () => {
    const { category = {}, subCategory = {}, uom = {} } = formDetails;
    let payload = {
      name: formDetails.name,
      price: formDetails.price,
      taxPercentage: +formDetails.taxPercentage || 0,
      specification: formDetails.specification,
      hsnCode: formDetails.hsnCode,
    };
    if (uom?.value) {
      payload.uom = uom?.value;
    }

    if (category?.id) {
      payload.category = category.id;
    }

    if (subCategory?.id) {
      payload.subCategory = subCategory.id;
    }

    invokeApi(
      HTTP_METHODS.PUT,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ITEMS}/${formDetails.id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          setBudgetModal(false);
          loadData(filters);
          setFormDetails({});
        }
      })
      .catch((err) => {});
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
  };

  const editVendor = (data, type) => {
    const { category = {}, subCategory = {}, priceHistory = [] } = data;

    if (type === "HISTORY") {
      setPriceHistoryDetails(priceHistory || {});
    } else {
      setBudgetModal(true);
      const uom = UOM_LIST.find((e) => e.value === data.uom) || null;
      setInputUnitValue(uom?.name);
      setFormDetails(() => ({ ...data, uom }));
      if (category?.id) {
        loadSubcategory(category.id);
        setSubCategoryInputValue(subCategory.name || "");
        setInputValue(category.name || "");
      }
    }
  };

  const downloadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ITEMS}`,
      null,
      { page: 1, limit: budgetList.totalResults || 1000 }
    )
      .then((response) => {
        if (response.message) {
        } else {
          exportToExcel(
            formatExcelHeaders(response.results),
            "items-list",
            true
          );
        }
      })
      .catch((err) => {});
  };

  const onDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.ITEMS}/${deleteDetails.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response.message, { autoClose: 2000 });
          return;
        }
        loadData(filters);
        setDeleteDetails(null);
      })
      .catch((err) => {});
  };

  return (
    <div className="item-list">
      <div className="itl-header-section">
        <div className="left-container">
          <TextField
            placeholder="Search"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="outlined"
            onClick={() =>
              loadData({
                page: 1,
                limit: 10,
              })
            }
          >
            Search
          </Button>
        </div>
        <div className="left-container">
          {isCreateAllowed("master_data") && (
            <>
              <FileUpload
                buttonBackground="#fff"
                buttonColor="#1756f3"
                mainButtonClass="subway-finance-bulk-upload"
                borderColor="rgba(0, 138, 82, 0.5)"
                uploadText="BULK UPLOAD"
                clsName="finance-master-data-uploader"
                url={`${FINANCE_HOSTNAME}${FINANCE_REST_URLS.UPLOAD_MASTER_DATA}item`}
                callBack={() =>
                  loadData({
                    page: filters.page,
                    limit: 10,
                  })
                }
                formDataKey="file"
              />

              <DownloadTemplateButton type={uploadTemplates.item} />
            </>
          )}

          <Button
            variant="outlined"
            disabled={!budgetList.totalResults}
            onClick={downloadData}
          >
            Download
          </Button>
          {isCreateAllowed("master_data") && (
            <Button variant="outlined" onClick={() => setBudgetModal(true)}>
              Create
            </Button>
          )}
        </div>
      </div>
      <MuiTable
        columnsList={getHeaderConfig(editVendor, setDeleteDetails)}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />

      {budgetModal && (
        <CustomModal
          title={formDetails.id ? "Update Item" : "Create Item"}
          disabledOutsideClick={true}
          onClose={() => {
            setBudgetModal(false);
            setFormDetails({});
          }}
        >
          <div className="create-form">
            <TextField
              value={formDetails.name}
              onChange={onInputChange}
              size="small"
              name="name"
              label="Item Name"
              fullWidth
            />
            <TextField
              value={formDetails.hsnCode}
              onChange={onInputChange}
              size="small"
              label="HSN Code"
              name="hsnCode"
              fullWidth
            />
            <TextField
              value={formDetails.price}
              onChange={(e) => {
                if (!e.target.value) {
                  clearProperty("price", setFormDetails);
                  return;
                }
                onInputChange(e);
              }}
              size="small"
              type="number"
              label="Price"
              name="price"
              fullWidth
            />
            <TextField
              value={formDetails.taxPercentage}
              onChange={(e) => {
                if (!e.target.value) {
                  clearProperty("taxPercentage", setFormDetails);
                  return;
                }
                onInputChange(e);
              }}
              size="small"
              type="number"
              label="Tax Percentage"
              name="taxPercentage"
              fullWidth
            />

            <TextField
              value={formDetails.specification}
              onChange={onInputChange}
              size="small"
              label="Specifications"
              name="specification"
              fullWidth
            />

            <Autocomplete
              options={UOM_LIST}
              value={formDetails.uom || ""}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Unit" />
              )}
              inputValue={inputUnitValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setInputUnitValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  uom: val,
                });
              }}
            />

            <Autocomplete
              options={categoryList}
              value={formDetails.category || ""}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Category" />
              )}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setInputValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  category: val,
                  subCategory: "",
                });
                if (val) {
                  loadSubcategory(val.value);
                }
                clearProperty("subCategory", setFormDetails);
                setSubCategoryInputValue("");
              }}
            />
            <Autocomplete
              options={subcategoryList}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Subcategory" />
              )}
              value={formDetails.subCategory || ""}
              inputValue={subCategoryInputValue}
              onInputChange={(event, newInputValue) => {
                if (event) {
                  setSubCategoryInputValue(newInputValue);
                }
              }}
              onChange={(e, val) => {
                setFormDetails({
                  ...formDetails,
                  subCategory: val,
                });
              }}
            />
            {formDetails.id ? (
              <Button fullWidth variant="contained" onClick={onUpdate}>
                Update
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={onSave}>
                Create
              </Button>
            )}
          </div>
        </CustomModal>
      )}
      {deleteDetails && (
        <ConfirmModal onYes={onDelete} onNo={() => setDeleteDetails(null)} />
      )}

      {priceHistoryDetails && (
        <CustomModal
          title="Price History"
          disabledOutsideClick={true}
          onClose={() => {
            setPriceHistoryDetails(null);
          }}
        >
          <PriceHistory priceHistoryDetails={priceHistoryDetails || []} />
        </CustomModal>
      )}
    </div>
  );
};

export default ItemList;
