import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";

export const loadUsers = (parameters = {}, customLogic) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${QNET_HOSTNAME}${REST_URLS.GET_ALL_USER_NAME}`,
    null,
    parameters
  ).then((response) => {
    if (typeof customLogic === "function") {
      customLogic(response);
    }
  });
};
