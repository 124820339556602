import { useState } from "react";
import CustomModal from "../../core/modal";
import { Button, TextField, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { HTTP_METHODS, mainInvokeApi } from "../../utils/http-service";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getOfflineData, setOfflineData } from "../../utils/offline-services";

export const ResetPasswordModal = ({
  showChangePasswordModal,
  setShowChangePasswordModal,
  tokenKey,
  url,
  slug,
  resetPasswordKey,
}) => {
  const location = useLocation();
  useEffect(() => {
    const user = getOfflineData(tokenKey, "user");
    if (!user) {
      return;
    }
    if (!!user?.[resetPasswordKey]) {
      setShowChangePasswordModal(true);
    }
  }, [location]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const setPasswordHandler = () => {
    const user = getOfflineData(tokenKey, "user");
    const keyAfterReset = !user?.[resetPasswordKey];
    const payload = {
      password: password,
      [resetPasswordKey]: keyAfterReset,
    };
    if (!password) {
      toast.error("Please enter password", { autoClose: 2000 });
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password and confirm password do not match", {
        autoClose: 2000,
      });
    } else {
      const id = getOfflineData(tokenKey, "user")?.id;
      mainInvokeApi(tokenKey, slug, HTTP_METHODS.PUT, `${url}${id}`, payload)
        .then((res) => {
          if (res?.message) {
            toast.error(res?.message, { autoClose: 2000 });
          }
          if (res.changePasswordOnFirstLogin === keyAfterReset) {
            toast.success("Password Set successfully", { autoClose: 2000 });
            setOfflineData(tokenKey, "user", res);
            setShowChangePasswordModal(false);
          }

          setPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          toast.error(err, { autoClose: 2000 });
        });
    }
  };
  return (
    <>
      {showChangePasswordModal && (
        <CustomModal
          doNotClose={true}
          title="Change Password"
          contentClassName={{ headerBackgroundColor: "#1756f3" }}
          onClose={() => {}}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="password"
              type="password"
              style={{ marginBottom: "2em" }}
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              variant="outlined"
              value={password}
              size="small"
            />
            <TextField
              fullWidth
              id="confirmPassword"
              style={{ marginBottom: "1em" }}
              label="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              variant="outlined"
              value={confirmPassword}
              type="password"
              size="small"
            />

            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={setPasswordHandler}
                fullWidth
              >
                Confirm
              </Button>
            </Stack>
          </Box>
        </CustomModal>
      )}
    </>
  );
};
