/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { getHeaderConfig } from "./config";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/docu-storage";
import {
  DOCU_STORAGE_HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { TableFilesModal } from "../../../common-utilities/core/custom-modals";

import {
  StoreLabels,
  DOCUMENT_TYPES_ON_BASIS_OF_STORE_TYPE,
  validDocumentTypeForTypeofDocumentAndStoreType,
  allvalidDocumentsForTypeofDocumentAndStoreType,
  reportLabels,
  reportLabelKeys,
  validStoreTypes,
  validDocumentTypes,
} from "../../utils/document-constants";

import { toast } from "react-toastify";
import { DATE_FORMATS } from "../../../common-utilities/utils/constants";
import {
  setClassForSiTableOnTheBasisOfReports,
  setDataForTables,
  changeRestaurantIdToStoreInFilter,
  getYesNAnoForUploadReports,
  processDownloadReports,
} from "./utils";
import SearchIcon from "@mui/icons-material/Search";
import {
  exportToExcel,
  getLabelsAndValuesForSelect,
  formatDate,
} from "../../../common-utilities/utils";
import { redirectToIntranetPortal } from "../../utils/common-utils";
import { tokenMainKeys } from "../../../../utils/constants";
import {
  processRestaurantDetailsForDropdown,
  setGetStoreInBatchParams,
} from "../../utils";
import { preAgreementDocsSelectList } from "../upload-document/utils/coco-acquired-utils";
import MuiTable from "../../../common-utilities/core/mui-table";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";

const DocustorageReportsSection = ({ tokenKey }) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState({});
  const [documentList, setDocumentList] = useState([]);
  const [viewDetails, setViewDetails] = useState(null);
  const [viewFiles, setViewFiles] = useState(null);
  const [customSiRowClass, setcustomSiRowClass] = useState("");
  const [selectDocumentTypeList, setSelectDocumentTypeList] = useState([]);
  const [reportType, setReportType] = useState(null);
  const [listIds, setlistIds] = useState([]);
  const [userFilters, setUserFilters] = useState({
    restaurantId: { label: "", value: null },
    storeType: { label: "", value: null },
    documentType: { label: "", value: null },
    uploadDocumentType: { label: "", value: null },
  });
  const [
    mainFiltersAfterClickingOnSearch,
    setMainFiltersAfterClickingOnSearch,
  ] = useState({
    restaurantId: { label: "", value: null },
    storeType: { label: "", value: null },
    documentType: { label: "", value: null },
    uploadDocumentType: { label: "", value: null },
    reportName: "",
  });

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  const resetFiltersAndTableData = () => {
    setTableData({});
    setFilters({
      page: 1,
      limit: 10,
      sortBy: "-createdAt",
    });
  };

  useEffect(() => {
    if (userFilters?.storeType?.value) {
      const { value } = userFilters.storeType;
      const mainDocumentTypes = DOCUMENT_TYPES_ON_BASIS_OF_STORE_TYPE[value];
      const allLabels = getLabelsAndValuesForSelect(
        mainDocumentTypes,
        mainDocumentTypes
      );
      setSelectDocumentTypeList(allLabels);
    } else {
      setSelectDocumentTypeList([]);
    }
  }, [userFilters.storeType]);
  useEffect(() => {
    if (
      !getOfflineData(tokenKey ? tokenKey : tokenMainKeys.docuStorage, "user")
    ) {
      redirectToIntranetPortal();
    } else {
      if (reportType?.value === "General Reports") {
        loadData({ ...filters, page: 1, limit: 10, sortBy: "-createdAt" });
      } else {
        setTableData({});
      }
    }
  }, [reportType]);

  useEffect(() => {
    setClassForSiTableOnTheBasisOfReports(
      reportType,
      userFilters,
      setcustomSiRowClass
    );
  }, [reportType, userFilters.storeType, userFilters.documentType]);

  useEffect(() => {
    if (
      userFilters.storeType &&
      userFilters.storeType.value &&
      reportType?.value === "General Reports"
    ) {
      const body = { storeType: userFilters.storeType.value };
      setGetStoreInBatchParams(body);
      invokeApi(
        HTTP_METHODS.POST,
        `${INTRANETHOSTNAME}${REST_URLS.LIST_ALL_RESTAURANT_IDS}`,
        body,
        null,
        null,
        tokenKey
      )
        .then((response) => {
          if (!response?.message) {
            const allSelectValues =
              processRestaurantDetailsForDropdown(response);
            setlistIds(allSelectValues);
          }
        })
        .catch((error) => {
          setlistIds([]);
        });
    } else {
      setlistIds([]);
    }
  }, [userFilters.storeType, reportType]);

  const loadData = (
    filtersObj,
    downloadReport,
    usePrevFiltersAfterClickingOnSearch
  ) => {
    if (
      reportType?.value === reportLabelKeys.informational &&
      !userFilters.storeType?.value
    ) {
      return toast.error("Please select store type", { autoClose: 2000 });
    }
    if (
      reportType?.value === reportLabelKeys.informational &&
      !userFilters?.documentType?.value
    ) {
      return toast.error("Please select document type", { autoClose: 2000 });
    }
    const filterParams = filtersObj ? { ...filtersObj } : { ...filters };
    delete filterParams.documentType;
    delete filterParams.restaurantId;
    delete filterParams.type;
    delete filterParams.restaurantId;
    delete filterParams.storeType;

    if (usePrevFiltersAfterClickingOnSearch) {
      if (mainFiltersAfterClickingOnSearch.restaurantId.value) {
        filterParams.restaurantId =
          mainFiltersAfterClickingOnSearch.restaurantId.value;
      }
      if (mainFiltersAfterClickingOnSearch.storeType.value) {
        filterParams.storeType =
          mainFiltersAfterClickingOnSearch.storeType.value;
      }

      if (mainFiltersAfterClickingOnSearch.documentType.value) {
        filterParams.type = mainFiltersAfterClickingOnSearch.documentType.value;
      }
      if (mainFiltersAfterClickingOnSearch.uploadDocumentType.value) {
        filterParams.documentType =
          mainFiltersAfterClickingOnSearch.uploadDocumentType.value;
      }
    } else {
      if (userFilters.restaurantId.value) {
        filterParams.restaurantId = userFilters.restaurantId.value;
      }
      if (userFilters.storeType.value) {
        filterParams.storeType = userFilters.storeType.value;
      }
      if (userFilters.documentType.value) {
        filterParams.type = userFilters.documentType.value;
      }
      if (userFilters.uploadDocumentType.value) {
        filterParams.documentType = userFilters.uploadDocumentType.value;
      }
    }

    let reportName = reportLabelKeys.general;
    if (downloadReport) {
      if (
        mainFiltersAfterClickingOnSearch.reportName ===
        reportLabelKeys.informational
      ) {
        reportName = reportLabelKeys.informational;
      }
    } else if (reportType?.value === reportLabelKeys.informational) {
      reportName = reportLabelKeys.informational;
    }
    if (downloadReport) {
      if (
        mainFiltersAfterClickingOnSearch.storeType &&
        mainFiltersAfterClickingOnSearch.storeType.label
      ) {
        reportName += `_${mainFiltersAfterClickingOnSearch.storeType.label}`;
      }
    } else if (filterParams.storeType && userFilters.storeType.label) {
      reportName += `_${userFilters.storeType.label}`;
    }
    if (downloadReport) {
      if (
        mainFiltersAfterClickingOnSearch.documentType &&
        mainFiltersAfterClickingOnSearch.documentType.label
      ) {
        reportName += `_${mainFiltersAfterClickingOnSearch.documentType.label}`;
      }
    } else if (userFilters.documentType && userFilters.documentType.label) {
      reportName += `_${userFilters.documentType.label}`;
    }
    if (downloadReport) {
      filterParams.download = true;
      filterParams.page = 1;
      filterParams.limit = tableData.totalResults;
    }
    changeRestaurantIdToStoreInFilter(filterParams);
    invokeApi(
      HTTP_METHODS.GET,
      `${DOCU_STORAGE_HOSTNAME}${REST_URLS.DOCS_FETCH}`,
      null,
      filterParams,
      null,
      tokenKey
    ).then((response) => {
      if (downloadReport) {
        if (response?.results?.length === 0) {
          toast.error("No documents are currently uploaded", {
            autoClose: 2000,
          });
          return;
        }
        exportToExcel(
          response.results,
          reportName,
          true,
          true,
          (workbook, worksheet, sheetName, headers, data) => {
            processDownloadReports(
              workbook,
              worksheet,
              sheetName,
              headers,
              data
            );
          }
        );
        return;
      }
      const results = response?.results?.map((item) => {
        const { details, restaurantId } = item;
        let allDetails = {
          restaurantId,
          storeType: details.storeType,
          type: details.type,
        };
        let additionalDetails = {};
        if (details?.details && typeof details?.details === "object") {
          Object.keys(details.details).forEach((item) => {
            additionalDetails[item] = details.details[item];
            const convertedItem = `${item}`.toLowerCase();
            if (
              convertedItem.includes("date") ||
              convertedItem === "leasestart" ||
              convertedItem === "leaseend"
            ) {
              additionalDetails[item] = formatDate(
                details.details[item],
                DATE_FORMATS["DD-MM-YYYY"]
              );
            }
          });
        }
        allDetails.additionalDetails = additionalDetails;
        return allDetails;
      });
      setTableData({ ...response, results });
    });
  };

  const loadUploadStatusData = (
    filtersObj,
    downloadReport,
    usePrevFiltersAfterClickingOnSearch
  ) => {
    const filterParams = filtersObj ? { ...filtersObj } : { ...filters };
    if (!filterParams.limit) {
      filterParams.limit = filters.limit;
    }
    if (!filterParams.page) {
      filterParams.page = filters.page;
    }
    if (!filterParams.sortBy) {
      filterParams.sortBy = filters.sortBy;
    }
    if (!userFilters?.storeType?.value) {
      toast.error("Please select store type", { autoClose: 2000 });
      return;
    }
    if (!userFilters?.documentType?.value) {
      toast.error("Please select document type", { autoClose: 2000 });
      return;
    }
    let reportName = reportLabelKeys.upload;
    if (downloadReport) {
      if (mainFiltersAfterClickingOnSearch.storeType.label) {
        reportName += `_ ${mainFiltersAfterClickingOnSearch.storeType.label}`;
      }
    } else if (userFilters.storeType.label) {
      reportName += `_ ${userFilters.storeType.label}`;
    }
    if (downloadReport) {
      if (mainFiltersAfterClickingOnSearch.documentType.label) {
        reportName += `_ ${mainFiltersAfterClickingOnSearch.documentType.label}`;
      }
    } else if (userFilters.documentType.label) {
      reportName += `_ ${userFilters.documentType.label}`;
    }

    if (downloadReport) {
      filterParams.download = true;
    }
    let allFilters = {};
    if (usePrevFiltersAfterClickingOnSearch) {
      allFilters = {
        storeType: mainFiltersAfterClickingOnSearch.storeType.value,
        type: mainFiltersAfterClickingOnSearch.documentType.value,
      };
    } else {
      allFilters = {
        storeType: userFilters.storeType.value,
        type: userFilters.documentType.value,
      };
    }

    if (downloadReport) {
      allFilters["limit"] = tableData.totalResults;
      allFilters["page"] = 1;
    } else {
      allFilters["limit"] = filterParams.limit;
      allFilters["page"] = filterParams.page;
    }
    changeRestaurantIdToStoreInFilter(allFilters);
    invokeApi(
      HTTP_METHODS.GET,
      `${DOCU_STORAGE_HOSTNAME}${REST_URLS.TABLE_STATS}`,
      null,
      {
        ...allFilters,
      },
      null,
      tokenKey
    )
      .then((response) => {
        if (response) {
          let allKeys =
            allvalidDocumentsForTypeofDocumentAndStoreType[
              userFilters.storeType.value
            ][userFilters.documentType.value];

          const keyToLabelMap = {};
          allKeys = allKeys.map((item) => {
            if (typeof item === "object") {
              keyToLabelMap[item.value] = item.label;
              return item.value;
            }
            keyToLabelMap[item] = item;
            return item;
          });

          let allKeysMap = {};
          allKeys.forEach((item) => {
            allKeysMap[item] = getYesNAnoForUploadReports(
              userFilters?.storeType?.value,
              userFilters?.documentType?.value,
              0
            );
          });

          const results =
            response?.results?.map((item) => {
              let finalItem = { ...item };
              delete finalItem.details;
              let itemCountMap = {};

              item?.details.forEach((itemDetails) => {
                const { type, count, na, isApplied } = itemDetails;
                itemCountMap[type] = getYesNAnoForUploadReports(
                  userFilters?.storeType?.value,
                  userFilters?.documentType?.value,
                  count,
                  na,
                  isApplied
                );
              });
              if (downloadReport) {
                delete finalItem._id;
                const allCount = { ...allKeysMap, ...itemCountMap };
                let finalCountMap = {};
                Object.keys(allCount).forEach((itemKey) => {
                  let label = keyToLabelMap[itemKey];
                  if (!label) {
                    label = itemKey;
                  }
                  finalCountMap[label] = allCount[itemKey];
                });
                return { ...finalItem, ...finalCountMap };
              }
              return { ...finalItem, ...allKeysMap, ...itemCountMap };
            }) || [];
          if (downloadReport) {
            if (results?.length === 0) {
              toast.error("No documents are currently uploaded", {
                autoClose: 2000,
              });
              return;
            }

            exportToExcel(results, reportName, null, true);
            return;
          }
          setTableData({ ...response, results });
        }
      })
      .catch((err) => {
        toast.error("Sorry something went wrong", { autoClose: 2000 });
      });
  };

  const setUploadDocumentList = (documentType, storeType) => {
    const allDocumentList =
      validDocumentTypeForTypeofDocumentAndStoreType[storeType]?.[documentType];
    let selectListOfUploadDocuments = getLabelsAndValuesForSelect(
      allDocumentList,
      allDocumentList
    );
    if (
      storeType === validStoreTypes.cocoAcquired &&
      documentType === validDocumentTypes.preAgreementDocs
    ) {
      selectListOfUploadDocuments = preAgreementDocsSelectList();
    }
    setDocumentList(selectListOfUploadDocuments);
  };
  const loadDataAccordingToReportType = (
    allFilters,
    usePrevFiltersAfterClickingOnSearch
  ) => {
    const finalReportType = reportType?.value;
    let providedFilters = {};
    if (allFilters && allFilters.page) {
      providedFilters = { page: allFilters.page };
    }
    switch (finalReportType) {
      case reportLabelKeys.general:
        loadData(
          { ...filters, ...providedFilters },
          null,
          usePrevFiltersAfterClickingOnSearch
        );
        return;
      case reportLabelKeys.upload:
        loadUploadStatusData(
          { ...filters, ...providedFilters },
          null,
          usePrevFiltersAfterClickingOnSearch
        );
        return;

      case reportLabelKeys.informational:
        loadData(
          { ...filters, ...providedFilters },
          null,
          usePrevFiltersAfterClickingOnSearch
        );
        return;

      default:
        toast.error("Please type of report", { autoClose: 2000 });
        return;
    }
  };
  return (
    <div className="reports-container">
      <div className="create-new">
        <span className="label">Reports</span>
        <div>
          {(tableData.results || []).length !== 0 && (
            <DownloadBtn
              onClick={() => {
                const curReportType = reportType?.value;
                switch (curReportType) {
                  case reportLabelKeys.general:
                    loadData(null, true, true);
                    return;
                  case reportLabelKeys.upload:
                    loadUploadStatusData(null, true, true);
                    return;

                  case reportLabelKeys.informational:
                    loadData(null, true, true);
                    return;

                  default:
                    return;
                }
              }}
            />
          )}
        </div>
      </div>
      <div className="searchField">
        <div className="main-filter-con">
          <div className="filter-con">
            <AutocompleteComponent
              label="Report type"
              fullWidth={true}
              options={reportLabels}
              value={reportType?.value ? reportType : null}
              className="doc-type"
              onChange={(e, event) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                  limit: 10,
                }));
                setUserFilters((prevFilters) => ({
                  ...prevFilters,
                  restaurantId: { label: "", value: null },
                  storeType: { label: "", value: null },
                  documentType: { label: "", value: null },
                  uploadDocumentType: { label: "", value: null },
                }));
                setReportType(event);
              }}
            />
          </div>
          <div className="filter-con">
            <AutocompleteComponent
              label="Store type"
              options={StoreLabels}
              value={
                userFilters.storeType?.value ? userFilters.storeType : null
              }
              className="doc-type"
              onChange={(e, event) => {
                if (
                  reportType?.value === reportLabelKeys.upload ||
                  reportType?.value === reportLabelKeys.informational
                ) {
                  resetFiltersAndTableData();
                }
                if (!event) {
                  setUserFilters((prevVal) => ({
                    ...prevVal,
                    storeType: { label: "", value: null },
                    documentType: { label: "", value: null },
                    uploadDocumentType: { label: "", value: null },
                    restaurantId: { label: "", value: null },
                  }));

                  return;
                }

                setUserFilters((prevVal) => ({
                  ...prevVal,
                  storeType: { label: event.label, value: event.value },
                  documentType: { label: "", value: null },
                  uploadDocumentType: { label: "", value: null },
                  restaurantId: { label: "", value: null },
                }));
              }}
            />
          </div>

          {reportType?.value === "General Reports" && (
            <div className="filter-con">
              <AutocompleteComponent
                label="Restaurant Id"
                options={listIds}
                value={
                  userFilters.restaurantId?.value
                    ? userFilters.restaurantId
                    : null
                }
                className="doc-type"
                onChange={(e, event) => {
                  if (!event) {
                    setUserFilters((prevVal) => ({
                      ...prevVal,
                      restaurantId: { label: "", value: null },
                    }));
                    return;
                  }

                  setUserFilters((prevVal) => ({
                    ...prevVal,
                    restaurantId: { label: event.label, value: event.value },
                  }));
                }}
              />
            </div>
          )}

          <div className="filter-con">
            <AutocompleteComponent
              label="Document type"
              options={selectDocumentTypeList}
              value={
                userFilters.documentType?.value
                  ? userFilters.documentType
                  : null
              }
              className="doc-type"
              onChange={(e, event) => {
                if (
                  reportType?.value === reportLabelKeys.informational ||
                  reportType?.value === reportLabelKeys.upload
                ) {
                  resetFiltersAndTableData();
                }
                if (!event) {
                  setUserFilters((prevVal) => ({
                    ...prevVal,
                    documentType: { label: "", value: null },
                    uploadDocumentType: { label: "", value: null },
                  }));
                  return;
                }
                setUploadDocumentList(event.value, userFilters.storeType.value);
                setUserFilters((prevVal) => ({
                  ...prevVal,
                  documentType: { label: event.label, value: event.value },
                  uploadDocumentType: { label: "", value: null },
                }));
              }}
            />
          </div>
          {userFilters.documentType.value !== null &&
            reportType?.value !== reportLabelKeys.upload &&
            userFilters.storeType.value !== "FOFO" && (
              <div className="filter-con">
                <AutocompleteComponent
                  label="Upload document type"
                  options={documentList}
                  value={
                    userFilters.uploadDocumentType?.value
                      ? userFilters.uploadDocumentType
                      : null
                  }
                  className="doc-type"
                  onChange={(e, event) => {
                    if (!event) {
                      setUserFilters((prevVal) => ({
                        ...prevVal,
                        uploadDocumentType: { label: "", value: null },
                      }));
                      return;
                    }

                    setUserFilters((prevVal) => ({
                      ...prevVal,
                      uploadDocumentType: {
                        label: event.label,
                        value: event.value,
                      },
                    }));
                  }}
                  isClearable
                />
              </div>
            )}

          <div className="search-btn">
            <SearchIcon
              onClick={() => {
                setMainFiltersAfterClickingOnSearch((prevFilters) => ({
                  ...prevFilters,
                  ...userFilters,
                  reportName: reportType?.value,
                }));
                setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                loadDataAccordingToReportType({ page: 1 });
              }}
              sx={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>

      {reportType &&
        getHeaderConfig(
          reportType.value,
          userFilters?.storeType?.value,
          userFilters.documentType?.value
        ).length > 0 && (
          <MuiTable
            columnsList={getHeaderConfig(
              reportType.value,
              userFilters?.storeType?.value,
              userFilters.documentType?.value
            )}
            dataList={tableData.results || []}
            filters={filters}
            pageCount={tableData.totalPages || 0}
            onClick={(data, type) => {
              setDataForTables(data, type, setViewDetails, setViewFiles);
            }}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadDataAccordingToReportType({ page }, true);
            }}
          ></MuiTable>
        )}

      {viewDetails && (
        <TableFilesModal
          title="Details"
          closeModal={setViewDetails}
          data={viewDetails}
        />
      )}

      {viewFiles && (
        <TableFilesModal
          title="Files"
          closeModal={setViewFiles}
          data={viewFiles}
        />
      )}
    </div>
  );
};

export default DocustorageReportsSection;
