import CustomModal from "../../../common-utilities/core/modal";
import Stack from "@mui/material/Stack";
import { Button, TextareaAutosize } from "@mui/material";
import "./index.scss";
import { useState, useEffect } from "react";
import { getOfflineData } from "../../utils/offline-services";
import CommonBtn from "../../../common-utilities/core/common-button";

export const ApprovalModal = ({
  title,
  closeModal,
  approveFunction,
  approvalData,
  dialogText,
  closeText,
  approveText,
  customModalContentClass,
}) => {
  const [approverPlaceholder, setApproverPlaceholder] = useState("");
  useEffect(() => {
    const user = getOfflineData("user");
    if (user && user.id && approvalData && approvalData.id) {
      const userid = parseInt(user.id);

      if (approvalData.approver1 === userid) {
        setApproverPlaceholder("Approver 1 Comment");
      } else if (approvalData.approver2 === userid) {
        setApproverPlaceholder("Approver 2 Comment");
      } else if (approvalData.approver3 === userid) {
        setApproverPlaceholder("Approver 3 Comment");
      }
    }
  }, [approvalData]);
  const [comment, setComment] = useState("");

  return (
    <CustomModal
      title={title}
      contentClassName={{
        headerBackgroundColor: "#1756f3",
        customClass: `${
          customModalContentClass ? customModalContentClass : ""
        }`,
      }}
      onClose={() => closeModal(null)}
    >
      <TextareaAutosize
        className="approverInput new-textfield"
        minRows={10}
        fullWidth
        placeholder={`${approverPlaceholder}`}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        value={comment}
        style={{ width: 200 }}
      />
      <h6>{dialogText ? dialogText : "Do you want to approve this?"} </h6>
      <Stack className="flexEnd" direction="row" spacing={2} sx={{ mt: 1 }}>
        <CommonBtn
          className="button-color"
          variant="outlined"
          size="medium"
          onClick={() => closeModal(null)}
          text={closeText ? closeText : "No"}
        />

        <CommonBtn
          variant="contained"
          color="error"
          size="medium"
          onClick={() => {
            if (approveFunction) {
              approveFunction(approvalData ? { ...approvalData, comment } : {});
            }

            closeModal(null);
          }}
          text={approveText ? approveText : "Yes"}
        />
      </Stack>
    </CustomModal>
  );
};
