import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import "./index.scss";
import MasterData from "../master-data";
import PurchaseOrder from "../purchase-order";
import GoodsReceiveNotes from "../goods-receive-notes";
import { isViewAllowed } from "../../utils/common-utils";
import InvoicesData from "../invoices-data";
import Reports from "../reports";
import Budget from "../budget";
import ApprovalConfig from "../approval-config";
import { getOfflineData } from "../../utils/offline-services";
import CfApprovalMapping from "../cf-approval-mapping";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get("index");
  const [value, setValue] = useState(+openTabIndex || 0);
  const user = getOfflineData("user");

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setSearchParams({
        index: newValue,
        subIndex: 5,
      });
    } else if (newValue === 0) {
      setSearchParams({
        index: newValue,
        subIndex: 2,
      });
    } else if (newValue === 2) {
      setSearchParams({
        index: newValue,
        subIndex: 5,
      });
    } else if (newValue === 4) {
      setSearchParams({
        index: newValue,
        subIndex: 4,
      });
    } else {
      setSearchParams({
        index: newValue,
        subIndex: 0,
      });
    }

    setValue(newValue);
  };
  useEffect(() => {
    handleChange(null, 1);
  }, []);
  return (
    <div className="home-container">
      <div className="tabs-section">
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          <Tab label="Budgets" />
          <Tab label="PO" />
          <Tab label="Goods Receive Notes" />
          <Tab label="Masters" />
          <Tab label="Advance Payment Details" />
          <Tab label="Reports" />
          {user?.properties?.finance?.approvalConfigRights && (
            <Tab label="Approval Config" />
          )}
          {user?.properties?.finance?.cfMappingRights && (
            <Tab label="CF Approval Mapping" />
          )}
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        {isViewAllowed("budget") ? <Budget /> : "No access to view budgets"}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isViewAllowed("po") ? (
          <PurchaseOrder />
        ) : (
          "No access to view Purchase orders "
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {isViewAllowed("grn") ? <GoodsReceiveNotes /> : "No access to view grn"}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {isViewAllowed("master_data") ? <MasterData /> : "No access to Master"}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {isViewAllowed("advancePayment") ? (
          <InvoicesData />
        ) : (
          "No access to Advance Payment"
        )}
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Reports />
      </TabPanel>
      {user?.properties?.finance?.approvalConfigRights && (
        <TabPanel value={value} index={6}>
          <ApprovalConfig />
        </TabPanel>
      )}
      {user?.properties?.finance?.cfMappingRights && (
        <TabPanel value={value} index={7}>
          <CfApprovalMapping />
        </TabPanel>
      )}
    </div>
  );
};

export default Home;
