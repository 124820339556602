import { useEffect, useState } from 'react';
import { getApprovalList } from './apis';
import MuiTable from '../../../common-utilities/core/mui-table';
import TableConfig from './config/table.config';
import { getAllDepartmentNames } from '../../../../components/admin-screen/apis';
import { setProperty } from '../../../common-utilities/utils/misc';
import Filters from './components/filters';
import { modalHandler } from './utils';
import EditCreateModal from './modals/edit-create';
import { modalConstants } from './config/constants';
import ConfirmModal from '../../../common-utilities/core/confirm-modal';
import { deleteApprovalConfigById } from './modals/apis';
import './index.scss';
import MainConfigButtons from './components/main-config-buttons';
import FinanceRole from './modals/finance-role';

const ApprovalConfig = () => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  const [tableResults, setTableResults] = useState({});

  const [config, setConfig] = useState({
    departmentList: [],
    modalDetails: null,
    refreshRoleList: true,
  });

  const closeModal = () => {
    setProperty('modalDetails', null, setConfig);
  };

  useEffect(() => {
    getApprovalList(filters, setTableResults);
  }, [filters]);

  useEffect(() => {
    getAllDepartmentNames((list) => {
      setProperty('departmentList', list, setConfig);
    }, true);
  }, []);

  return (
    <div className="finance-approval-config">
      <MainConfigButtons setConfig={setConfig} />
      <Filters config={config} filters={filters} setFilters={setFilters} />
      <MuiTable
        columnsList={TableConfig(config.departmentList)}
        dataList={tableResults?.results || []}
        pageCount={tableResults?.totalPages || 0}
        onChange={(page) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            page,
          }));
        }}
        filters={filters}
        onClick={(data, type) => {
          modalHandler(data, type, setConfig);
        }}
        nestedValue={true}
      />
      <EditCreateModal
        data={config?.modalDetails?.data}
        setMainConfig={setConfig}
        mainConfig={config}
        showModal={
          config?.modalDetails?.type === modalConstants.update ||
          config?.modalDetails?.type === modalConstants.create
        }
        title={config?.modalDetails?.title}
        onClose={closeModal}
        onSuccess={() => {
          closeModal();
          getApprovalList(filters, setTableResults);
        }}
      />
      <FinanceRole
        showModal={
          config?.modalDetails?.type === modalConstants.createFinanceRole
        }
        onClose={closeModal}
        title={config?.modalDetails?.title}
        onSuccess={() => {
          setProperty('refreshRoleList', true, setConfig);
          closeModal();
        }}
      />
      {config?.modalDetails?.type === modalConstants.delete && (
        <ConfirmModal
          onYes={() => {
            deleteApprovalConfigById(
              config?.modalDetails?.data?.id || config?.modalDetails?.data?._id,
              () => {
                closeModal();
                getApprovalList(filters, setTableResults);
              }
            );
          }}
          onNo={closeModal}
        />
      )}
    </div>
  );
};

export default ApprovalConfig;
