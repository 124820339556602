import { toast } from "react-toastify";
import { deleteFalsyProperties } from "../../../common-utilities/utils/misc";
import { HOSTNAME, INTRANETHOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { checkForForUserSelectedTab } from "./user-modal-utils-2";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

export const disabledCheckForModal = (
  userDetails,
  addUser,
  editUser,
  value
) => {
  const { email, name, password, role, dc, vendor } = userDetails;
  if (!email?.trim()) {
    return true;
  }
  if (!name?.trim()) {
    return true;
  }
  if (!editUser && !password?.trim() && value == 0) {
    return true;
  }

  if (addUser) {
    switch (value) {
      case 0:
        if (!role) {
          return true;
        }

        if (role.type === "QA") {
          if (role.belongsTo === "DC") {
            if (!dc) {
              return true;
            }
          }
          if (role.belongsTo === "VENDOR") {
            if (!vendor) {
              return true;
            }
          }
        }
        break;
      case 1:
        break;
      case 2:
        break;
      default:
        break;
    }
  }
  if (editUser) {
    switch (value) {
      case 0:
        if (!role) {
          return true;
        }
        if (role.type === "QA") {
          if (role.belongsTo === "DC") {
            if (!dc) {
              return true;
            }
          }
          if (role.belongsTo === "VENDOR") {
            if (!vendor) {
              return true;
            }
          }
        }
        break;
      case 1:
        return (
          !userDetails?.dcCode ||
          !userDetails?.phone ||
          !userDetails?.address ||
          !userDetails?.city
        );
      case 2:
        return (
          !userDetails?.manufacturerNumber ||
          !userDetails?.splCode ||
          !userDetails?.phone ||
          !userDetails?.address ||
          !userDetails?.city
        );
      default:
        break;
    }
  }
  return false;
};

export const editUserHandler = (
  userDetails,
  closeCreateModal,
  loadData,
  value,
  filters
) => {
  const payload = { ...userDetails };
  const { id } = payload;
  if (!payload.password) {
    delete payload.password;
  }
  if (payload.distributors) {
    payload.distributors = payload.distributors.map((item) => item.value);
  }
  delete payload.id;
  if (
    !userDetails?.role?.type?.includes("ACTION") &&
    !userDetails?.role?.type?.includes("CREDIT")
  ) {
    delete payload.distributors;
  }
  if (!userDetails?.role?.belongsTo?.includes("DC")) {
    delete payload.dc;
  }
  if (!userDetails?.role?.belongsTo?.includes("VENDOR")) {
    delete payload.vendor;
  }
  let url = `${QNET_HOSTNAME}${REST_URLS.EDIT_USER}${id}`;
  let message = `User data updated successfully.`;
  switch (value) {
    case 0:
      delete payload.isVegetableVendor;
      payload.role = payload.role.value;
      if (payload.dc) {
        payload.dc = payload.dc.value;
      }
      if (payload.vendor) {
        payload.vendor = payload.vendor.value;
      }
      break;
    case 1:
      delete payload.isVegetableVendor;
      url = `${INTRANETHOSTNAME}${REST_URLS.UPDATE_DC_BY_ID}${id}`;
      message = "Distribution Center updated successfully";
      break;
    case 2:
      delete payload.dcCumVendor;
      url = `${INTRANETHOSTNAME}${REST_URLS.UPDATE_VENDOR_BY_ID}${id}`;
      message = "Supplier Plant updated successfully";
      break;
    default:
      break;
  }

  invokeApi(HTTP_METHODS.PUT, url, {
    ...payload,
  })
    .then((response) => {
      if (response.message) {
        toast.error(response.message);
      } else {
        toast.success(message, { autoClose: 2000 });
        closeCreateModal();
        loadData({ ...filters }, true, value);
      }
    })
    .catch((err) => {
      toast.error(err?.message, { autoClose: 2000 });
      closeCreateModal();
    });
};

export const createUser = (
  userDetails,
  closeCreateModal,
  loadData,
  value,
  filters
) => {
  const payload = { ...userDetails };
  if (payload.role) {
    payload.role = userDetails?.role?.value;
  }

  if (payload.distributors) {
    payload.distributors = payload.distributors.map((item) => item.value);
  }
  deleteFalsyProperties(payload, [
    "address",
    "city",
    "dcCode",
    "phone",
    "manufacturerNumber",
    "plantLocation",
    "splCode",
  ]);

  if (userDetails?.role?.type === "QA") {
    if (userDetails?.role?.belongsTo === "DC") {
      payload.dc = payload.dc.value;
    } else {
      payload.vendor = payload.vendor.value;
    }
  }
  let url;
  const userTab = checkForForUserSelectedTab(value);
  const { allUsersFirstTab, distirbutorsTab, suppliersTab } = userTab;

  if (allUsersFirstTab) {
    url = `${QNET_HOSTNAME}${REST_URLS.CREATE_USER}`;
  }
  if (distirbutorsTab) {
    url = `${INTRANETHOSTNAME}${REST_URLS.CREATE_DC}`;
  }
  if (suppliersTab) {
    url = `${INTRANETHOSTNAME}${REST_URLS.CREATE_VENDOR}`;
  }

  invokeApi(HTTP_METHODS.POST, url, payload)
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      const message = allUsersFirstTab
        ? `User created successfully. User Id is ${res?.userId}`
        : distirbutorsTab
        ? "Distribution Center created successfully"
        : "Supplier Plant created successfully";
      toast.success(message, {
        autoClose: 2000,
      });
      closeCreateModal();
      loadData({ ...filters }, true, value);
    })
    .catch((err) => {
      console.log(err);
    });
};
