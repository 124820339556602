export const intranetService = `/v1/central-service/`;
export const REST_URLS = {
  INTRANET_LOGIN: `${intranetService}auth/login-with-token`,
  REFRESH_TOKEN: `${intranetService}auth/refresh-tokens-app`,
  LOGIN: `${intranetService}auth/login`,
  REFRESH_TOKEN_INTRANET: `${intranetService}auth/refresh-tokens`,
};

export const HOSTNAME =
  "https://invoice-tracking.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
export const INTRANETHOSTNAME = `https://central-service.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io`;
export const INVOICE_TRACKING_HOSTNAME =
  "https://invoice-tracking.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
export const CF_APPROVAL_HOSTNAME =
  "https://cf-api.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
export const FINANCE_HOSTNAME =
  "https://finance-service.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
export const BD_LEAD_HOSTNAME =
  "https://bd-lead-api.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
export const DOCU_STORAGE_HOSTNAME =
  "https://docu-service-api.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
export const SERVICE_REQUEST_HOSTNAME =
  "https://tech-support-service.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
export const QNET_HOSTNAME =
  "https://docu-service-api.victoriousmoss-b896bbb2.centralindia.azurecontainerapps.io";
// export const HOSTNAME = `http://localhost:5006`;
// export const INTRANETHOSTNAME = "http://localhost:5000"; // This should be the hostname of the central service
// export const INVOICE_TRACKING_HOSTNAME = "http://localhost:4004"; //This should be the hostname of the invoice tracking service
// export const FINANCE_HOSTNAME = "http://localhost:5005";
// export const CF_APPROVAL_HOSTNAME = "http://localhost:5006"; // HOSTNAME for subway-cf-approval-api
// export const BD_LEAD_HOSTNAME = "http://localhost:4001";
// export const HOSTNAME = `http://localhost:4000`;
// export const INTRANETHOSTNAME = "http://localhost:5000";
// export const INVOICE_TRACKING_HOSTNAME = "http://localhost:4000";
// export const DOCU_STORAGE_HOSTNAME = "http://localhost:5006";
