export const TICKET_SEVERITY = [
  {
    label: "Minor",
    value: "MINOR",
  },
  {
    label: "Major",
    value: "MAJOR",
  },
];

export const TICKET_STAGES = {
  NO_STAGE: "UNASSIGNED",
  COMPLAINT_FORM_SUBMITTED: "COMPLAINT_FORM_SUBMITED",
  COMPLAINT_FORM_UPDATED: "COMPLAINT_FORM_UPDATED",
  ASSIGN_SE: "ASSIGNED",
  COMMENT: "COMMENT",
  TICKET_UPDATED: "TICKET_UPDATED",
  ATTACHMENT: "ATTACHMENT",
  SEVERITY: "TICKET_SEVERITY_UPDATE",
  STATUS_UPDATE: "TICKET_STATUS_UPDATE",
  REASSIGN: "REASSIGN",
  HOLD_ESCALATIONS: "HOLD_ESCALATIONS",
  TICKET_ESCALATION: "TICKET_ESCALATION",
};

export const userTypes = {
  STORE_OWNER: "STORE_OWNER",
  ADMIN: "ADMIN",
  TECH_NET_USER: "TECH_NET_USER",
  AGENT: "AGENT",
};

export const convertedTypes = {
  STORE_OWNER: "Store Owner",
  ADMIN: "Admin",
  TECH_NET_USER: "Tech Net User",
  AGENT: "Agent",
};
