/** @format */

import { convertToCamelCaseFromUnderScore } from "../../utils";

export const REPORT_TABS = [
  "Cases",
  "Complain Types",
  "Other",
  "Detailed Report",
];

export const OwnerTypes = [
  {
    label: "Cases for Admin",
    value: "by-admin",
  },
  {
    label: "Cases for DC",
    value: "by-dc",
  },
  {
    label: "Cases for Vendor",
    value: "by-vendor",
  },
];

export const getHeaderConfig = () => [
  {
    label: "Case ID",
    key: "id",
    render: (data, onClick) => {
      return (
        <span
          key={Math.random()}
          className="clickable"
          onClick={() => onClick(data, "VIEW")}
        >
          {data.id}
        </span>
      );
    },
  },
  {
    label: "Case Type",
    key: "type",
    render: (data) => {
      const { type = {} } = data;
      return (
        <span key={Math.random()}>
          {convertToCamelCaseFromUnderScore(type)}
        </span>
      );
    },
  },
  {
    label: "Store Number",
    key: "store",
  },
  {
    label: "Status",
    key: "status",
    render: (data) => {
      const { status = {} } = data;
      return (
        <span key={Math.random()}>
          {convertToCamelCaseFromUnderScore(status)}
        </span>
      );
    },
  },
  {
    label: "Created By",
    key: "createdBy",
    render: (data) => {
      const { createdBy = {} } = data;
      return <span key={Math.random()}>{createdBy.name}</span>;
    },
  },
  {
    label: "Updated By",
    key: "lastUpdatedBy",
    render: (data) => {
      const { lastUpdatedBy = {} } = data;
      return <span key={Math.random()}>{lastUpdatedBy.name}</span>;
    },
  },
];
export const getSLAHeaderConfig = () => [
  {
    label: "Level",
    key: "level",
  },
  {
    label: "Created On",
    key: "createdAt",
    render: (data) => {
      return (
        <span>
          {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{" "}
          {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
        </span>
      );
    },
  },
  {
    label: "Department",
    key: "dept",
    render: (data) => {
      return <div>{data.dept.name}</div>;
    },
  },
  {
    label: "Category",
    key: "category",
    render: (data) => {
      return <div>{data.category.name}</div>;
    },
  },
  {
    label: "Ticket",
    key: "ticket",
  },
];
export const getStoreHeaderConfig = () => [
  {
    label: "Store Name",
    key: "Store Name",
  },
  {
    label: "Department",
    key: "Department",
  },
  {
    label: "Category",
    key: "Category",
  },
  {
    label: "Count",
    key: "Count",
    render: (data, onClick) => {
      return (
        <span onClick={() => onClick(data)} className="clickable userClickable">
          {data.Count}
        </span>
      );
    },
  },
];

export const getTableHeaderConfigForDetailedReport = (
  addProductName,
  addStoreNum
) => {
  let config = [
    { label: "Created Date", id: "createdAt" },
    { label: "Closing Date", id: "closedAt" },
    { label: "Complaint Number", id: "_id" },
    { label: "Restaurant", id: "storeNumber" },
    { label: "Restaurant country", id: "country" },
    {
      label: "Product Number",
      id: "productNumber",
    },
    { label: "Complaint Type", id: "complaintType" },
    { label: "Status", id: "status" },
    { label: "Complaint Severity", id: "severity" },
    { label: "Supplier", id: "supplier" },
    { label: "Distribution Center", id: "distributor" },
    // {
    //   label: "Complaint Owner",
    //   key: "compalintowner",
    // },
    // {
    //   label: "Complaint Owner Type",
    //   key: "complaintOwnerType",
    // },
    {
      label: "Final Organization At Fault",
      id: "faultOrganization",
    },
    // {
    //   label: "Final Fault Type",
    //   key: "dffd",
    // },
    {
      label: "Store city",
      id: "city",
    },
    {
      label: "Store state",
      id: "state",
    },
    {
      label: "Batch Lot",
      id: "batchlot",
    },
    {
      label: "Expiration Date",
      id: "expirationDate",
    },
    {
      label: "Delivery Date",
      id: "deliveryDate",
    },
    {
      label: "Invoice Number",
      id: "dcInvoice",
    },
    {
      label: "Production Date",
      id: "productionDate",
    },
    {
      label: "Complaint Notes",
      id: "complaintNotes",
    },
    { label: "Created By", id: "createdBy" },
    { label: "Number of units affected", id: "unitsAffected" },
    { label: "Affected UOM", id: "affectedUOM" },
    { label: "Credit Quantity", id: "creditQuantity" },
    { label: "Credit Method", id: "creditMethod" },
    { label: "Credit Notes", id: "creditNotes" },
    { label: "Credit Currency", id: "creditCurrency" },
    { label: "Credit Issuance Date", id: "Credit Issuance Date" },
  ];
  if (addStoreNum) {
    config.splice(4, 0, {
      label: "Store Type",
      id: "storeType",
    });
  }
  if (addProductName) {
    config = [
      ...config.slice(0, 5),
      { label: "Product Name", id: "Product Name" },
      ...config.slice(5, config.length),
    ];
  }
  return config;
};

export const getHeaderConfigForTableItems = () => [
  {
    label: "Id",
    key: "id",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Resolve Message",
    key: "resolveMessage",
  },
];

export const formatCaseDetails = (results) => {
  return results.map((item) => {
    const {
      id,
      closedAt,
      creditInfo,
      fault = {},
      lastUpdatedBy = {},
      createdBy = {},
      store,
      complaintType = {},
      type,
      closeAgainstStore,
      creditConfirmationByStore,
      closingMessage,
      status,
      details,
    } = item;
    return {
      Id: id,
      store,
      "Complaint Type": complaintType.name || "",
      type,
      "Close Against Store": closeAgainstStore,
      "Credit Confirmation By Store": creditConfirmationByStore,
      "Closing Message": closingMessage,
      status,
      details,
      "Created By": createdBy.name,
      "Created By Email": createdBy.email,
      "last Updated By": lastUpdatedBy.name,
      "last Updated By Email": lastUpdatedBy.email,
      "Closed At": closedAt,
      "Fault cause": fault?.cause || "",
      "Corrective Action": fault?.correctiveAction || "",
      notes: fault.notes,
      "Fault Accepted By": fault.acceptedBy,
      "Fault Credit": fault.credit,
    };
  });
};
