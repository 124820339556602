/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishIcon from "@mui/icons-material/Publish";
import "./index.scss";
import { IconButton } from "@mui/material";
import { invokeUploadFile } from "../../utils/http-service";
import { CF_APPROVAL_HOSTNAME } from "../../../common-utilities/utils/end-points";

import { cocoAcqKeyNames, cocoBuiltKeyNames } from "../../utils/constants";

const SiFileUpload = ({
  title,
  clsName,
  url,
  accept,
  callBack,
  inputclassName,
  fileFormats,
  setDataFunction,
  showFileName,
  documentName,
  warningclass,
}) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };
  const onChange = (e) => {
    if (fileFormats && !fileFormats.includes(e.target.files[0].type.trim())) {
      toast.error("File provided is not of correct format", {
        autoClose: 2000,
      });
      return;
    }
    setFile(e.target.files[0]);
  };

  const fileUpload = () => {
    if (file !== null) {
      if (file.size > 5000000) {
        toast.error("File size should not exceed 5MB");
        return;
      }
      if (documentName === "Five Year Plan") {
        showFileName((prevVal) => ({ ...prevVal, fiveYearPlan: file.name }));
      }
      if (documentName === "New Store Details") {
        showFileName((prevVal) => ({
          ...prevVal,
          newStoreDetailsPPT: file.name,
        }));
      }
      if (documentName === "New Store Details Excel") {
        showFileName((prevVal) => ({
          ...prevVal,
          newStoreDetailsExcel: file.name,
        }));
      }
      if (documentName === "Approval Email") {
        showFileName((prevVal) => ({
          ...prevVal,
          approvalEmail: file.name,
        }));
      }
      if (documentName === "Unit Economics Model - Cover") {
        showFileName((prevVal) => ({
          ...prevVal,
          unitEconomicModel: file.name,
        }));
      }
      if (
        documentName === cocoAcqKeyNames.competitionAndNearbyRestaurantSales ||
        documentName === cocoBuiltKeyNames.competitionAndNearbyRestaurantSales
      ) {
        showFileName((prevVal) => ({
          ...prevVal,
          competitionAndNearbyRestaurantSales: file.name,
        }));
      }
      if (documentName === "Total Acquisition Cost") {
        showFileName((prevVal) => ({
          ...prevVal,
          gapmapSnapshot: file.name,
        }));
      }
    }
    const formData = new FormData();
    formData.append("upload", file);
    setUploadStatus("Uploading...");
    return invokeUploadFile(`${CF_APPROVAL_HOSTNAME}${url}`, formData)
      .then((response) => {
        if (response.status === 200) {
          const documenturl = response.data[0].split("?")[0];
          toast.success("Uploaded Successfully", { autoClose: 1000 });
          setFile(null);
          if (setDataFunction) {
            setDataFunction(documenturl);
          }
          callBack && callBack();
          setUploadStatus("");
        } else {
          toast.error(
            (response.data && response.data.message) || "Upload Failed"
          );
          setUploadStatus("");
        }
      })
      .catch((err) => {
        setUploadStatus("");
        setFile(null);
        toast.error(`Upload Failed ${err?.response?.data?.message}`);
      });
  };

  return (
    <div className="relativePosition">
      <form className={`spidle-upload-file ${clsName}`}>
        {file === null ? (
          <IconButton
            title="Attach file"
            size="small"
            style={{
              border: "1px solid #1d49ae",
              color: "#1d49ae",
              borderRadius: 0,
              cursor: "pointer",
            }}
          >
            <label
              htmlFor="file-upload"
              className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium upload-button"
            >
              Upload Document <PublishIcon fontSize="small" />
            </label>
          </IconButton>
        ) : (
          <label
            title={(file && file.name) || ""}
            className="uploaded-file-name"
          >
            {(file && file.name) || ""}
          </label>
        )}
        <input
          id="file-upload"
          type="file"
          accept={accept}
          className={`${inputclassName ? inputclassName : ""}`}
          onChange={(event) => {
            onChange(event);
            event.target.value = null;
          }}
        />
        {file !== null && (
          <span>
            <IconButton
              color="primary"
              onClick={onFormSubmit}
              title="Upload"
              disabled={uploadStatus}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                if (setDataFunction) {
                  setDataFunction(null);
                }
                setFile(null);
              }}
              title="Clear"
              disabled={uploadStatus}
            >
              <HighlightOffIcon />
            </IconButton>
          </span>
        )}
      </form>
      {documentName === "Unit Economics Model - Cover" ||
      documentName === cocoAcqKeyNames.competitionAndNearbyRestaurantSales ||
      documentName === cocoBuiltKeyNames.competitionAndNearbyRestaurantSales ||
      documentName === "Total Acquisition Cost" ? (
        <div
          className={`file-Upload-Warning ${warningclass ? warningclass : ""}`}
        >
          *Image should not exceed 5 MB
        </div>
      ) : (
        <div
          className={`file-Upload-Warning ${warningclass ? warningclass : ""}`}
        >
          *File size should not exceed 5 MB
        </div>
      )}
    </div>
  );
};

export default SiFileUpload;
