import { Button, IconButton } from "@mui/material";
import {
  capitalizeFirstLetter,
  convertToCamelCaseFromUnderScore,
} from "../../../bd-tool/utils";
import { INVOICE_TRACKING_USERS } from "../../utils/constants";
import { getOfflineData } from "../../utils/offline-services";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { downloadFile, downloadFileFromUrl } from "../../utils/common-utils";
import { appSlugs } from "../../../../utils/constants";
import { getKeysBySlug } from "../../utils/keys";
import { dateFun } from "../../../common-utilities/utils";

export const getHeaderConfig = (role, token, slug) => {
  const keys = getKeysBySlug(slug);
  const headers = [
    {
      label: "DocID",
      id: "id",
      render: (data, viewInvoice) => {
        return (
          <Button variant="text" onClick={() => viewInvoice(data, "VIEW")}>
            {data.id}
          </Button>
        );
      },
    },
  ];
  if (slug !== appSlugs.vendorInvoiceSubmission) {
    headers.push({
      label: "Vendor Code",
      id: "vendor",
      render: (data) => {
        const { vendor = {} } = data;
        return `${vendor.code} - ${vendor.name}`;
      },
    });
  }
  headers.push({
    label: "PO Number",
    id: "poNumber",
  });

  if (slug !== appSlugs.vendorInvoiceSubmission) {
    headers.push({
      label: "GRN Number",
      id: "grnNumber",
    });
  }

  headers.push({
    label: "Invoice Number",
    id: "invoiceNumber",
  });

  if (slug !== appSlugs.vendorInvoiceSubmission) {
    headers.push(
      {
        label: "Store",
        id: "store",
      },
      {
        label: "Invoice Type",
        id: "invoiceType",
        render: (data) => {
          return convertToCamelCaseFromUnderScore(data.invoiceType);
        },
      },
      {
        label: "Invoice Date",
        id: "invoiceDate",
        render: (data) => {
          return dateFun(data.invoiceDate, true);
        },
      }
    );
  } else {
    headers.push(
      {
        label: "Invoice Date",
        id: "invoiceDate",
        render: (data) => {
          return dateFun(data.invoiceDate, true);
        },
      },
      {
        label: "Delivery Date",
        id: "deliveryDate",
        render: (data) => {
          if (data?.deliveryDate) {
            return dateFun(data.deliveryDate, true);
          }
          return <></>;
        },
      },
      {
        label: "Store",
        id: "store",
      }
    );
  }

  if (slug === appSlugs.vendorInvoiceSubmission) {
    headers.push(
      {
        label: "Vendor Name",
        id: "vendor.name",
      },
      {
        label: "Vendor Code",
        id: "vendor.code",
      }
    );
  }

  headers.push({
    label: keys.amount,
    id: "amount",
  });

  headers.push(
    {
      label: keys.invoiceStatus,
      id: "assignedTo",
      render: (data, viewInvoice) => {
        const user = getOfflineData("user", token);
        const { role } = user;
        const { assignedTo = "", cancelled } = data;
        if (cancelled) {
          return <span>Cancelled</span>;
        }
        if (
          (assignedTo === "Store" && role === INVOICE_TRACKING_USERS.STORE) ||
          (role === "VENDOR" &&
            slug === appSlugs.vendorInvoiceSubmission &&
            assignedTo === "Vendor")
        ) {
          return (
            <Button variant="text" onClick={() => viewInvoice(data, "EDIT")}>
              Update Details (Reconsider)
            </Button>
          );
        }
        return cancelled ? (
          <span>Cancelled</span>
        ) : assignedTo ? (
          <span>In Review({assignedTo})</span>
        ) : (
          <span>Completed</span>
        );
      },
    },
    {
      label: "Invoice Attachment",
      id: "invoiceAttachment",
      render: (data, viewInvoice) => {
        const { invoiceAttachment = "" } = data;
        return (
          <div className="action-btns">
            <IconButton onClick={() => viewInvoice(data, "PREVIEW_DOC")}>
              <RemoveRedEyeIcon />
            </IconButton>
            <IconButton onClick={() => downloadFileFromUrl(invoiceAttachment)}>
              <DownloadForOfflineIcon />
            </IconButton>
          </div>
        );
      },
    }
  );

  if (role === INVOICE_TRACKING_USERS.STORE) {
    headers.splice(5, 1);
  }
  if (slug === appSlugs.vendorInvoiceSubmission) {
    headers.push(
      {
        label: "Amount Paid",
        id: "amountPaid",
      },
      {
        label: "Payment Status",
        id: "paymentStatus",
        valueConversionLogic: capitalizeFirstLetter,
      }
    );
  }

  return headers;
};

export const FILTER_DATE_RANGE_TYPES = [
  {
    label: "Created Date",
    value: "createdAt",
  },
  {
    label: "Invoice Date",
    value: "invoiceDate",
  },
];

export const isFilterDisabled = (filters) => {
  const { invoiceType, dateType, startDate, endDate } = filters;
  if (invoiceType && invoiceType.length > 0) {
    if (dateType && dateType.length > 0) {
      return !startDate && !endDate;
    }
    return false;
  }

  if (dateType && dateType.length > 0) {
    return !startDate && !endDate;
  }
  return false;
};

export const clearFilter = (filters) => {
  const { invoiceType, dateType, startDate, endDate } = filters;

  if (invoiceType && invoiceType.length > 0) {
    if (dateType && dateType.length > 0) {
      return startDate && endDate;
    }
    return false;
  }

  if (dateType && dateType.length > 0) {
    return !startDate && !endDate;
  }
  return true;
};

export const getOptions = (role) => {
  return [
    { label: "Pending Request", value: 0 },
    { label: "Completed", value: 1 },
    { label: "All Invoice", value: 2 },
  ];
};

export const transformInvoiceReport = (res) => {
  const newRes = res.map((row) => {
    return {
      DocID: row.DocID,
      "Vendor Code": row["Vendor Code"],
      "PO Number": row["PO Number"],
      "GRN Number": row["GRN Number"],
      "Invoice Type": row["Invoice Type"],
      "Invoice No": row["Invoice No"],
      "Invoice Date": row["Invoice Date"],
      Amount: row.Amount,
      "Invoice Attachment": row["Invoice Attachment"],
      "GRN Attachment": row["GRN Attachment"],
      "PO Attachment": row["PO Attachment"],
      Remarks: row.Remarks,
      "New vendor Name": row["New vendor Name"],
      "Vendor Name": row["Vendor Name"],
      Store: row.Store,
      "Creation Date": row["Creation Date"],
      "Creator Name": row["Creator Name"],
      "Last Updated Date": row["Last Updated Date"],
      "RM Approval Required": row["RM Approval Required"],
      "RM Approved": row["RM Approved"],
      "RM Approved Date": row["RM Approved Date"],
      "RM Comments": row["RM Comments"],
      "Assigned To": row["Assigned To"],
      "Finance Approved": row["Finance Approved"],
      "Finance Approved Date": row["Finance Approved Date"],
      "Finance Comments": row["Finance Comments"],
    };
  });
  return newRes;
};
