import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import { technetEndPoint } from "../../../common-utilities/utils/end-points/tech-net";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { toast } from "react-toastify";
import {
  getFormConfig,
  isFormValid,
  convertedEventNamesToErrorObjectKeys,
} from "./helper";
import SiFileUpload from "../../core/file-uploader";
import { invokeUploadFile } from "../../utils/http-service";
import { getAllTicketCategoriesForaParent } from "./data-apis";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";
import { userTypes } from "../../utils/constants";
import { pick } from "../../../common-utilities/utils";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import CommonBtn from "../../../common-utilities/core/common-button";
import { CategoryDoc } from "../TicketCategory/sub-components/category-doc.subcomponent";

const sx = {
  input: {
    background: "#fff",
    borderColor: "gray",
  },
};

export const CreateTicket = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [storeList, setStoresList] = useState([]);
  const [ticketCategoriesList, setticketCategoriesList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [departmentsList, setdepartmentsList] = useState([]);

  const [ticketDetails, setTicketDetails] = useState({});
  const [multipleFiles, setMultipleFiles] = useState([]);

  useEffect(() => {
    const user = getOfflineData(tokenMainKeys.technet, "user");

    const params = {};
    if (user?.type !== userTypes.STORE_OWNER) {
      params.getAll = true;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_ALL_STORE_NAMES}`,
      null,
      params
    )
      .then((response) => {
        if (response) {
          const listOfStores = response.map((item) => {
            const { name, id, storeNumber, satelliteNumber, sequenceNumber } =
              item;
            return {
              label: name
                ? name
                : `${storeNumber}-${sequenceNumber}-${satelliteNumber}`,
              value: id,
            };
          });
          setStoresList(listOfStores);
          // setTicketDetails((alldetails) => ({ ...alldetails, storeList: listOfStores }))
        }
      })
      .catch((err) => console.log(err));
  }, [setdepartmentsList, setStoresList]);

  const loadAllDepartmentsAssociatedWithStore = (storeId) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENTs_STOREWISE}${storeId}`,
      null
    )
      .then((response) => {
        if (response && !response?.message) {
          const departmentsList = response.map((item) => {
            const { id, name } = item;
            return { label: name, value: id };
          });
          setdepartmentsList(departmentsList);
        } else {
          setdepartmentsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setdepartmentsList([]);
      });
  };

  const getTicketCategoriesByCurrentDepartment = (dept, parentNull) => {
    const params = { showZeroEscalation: true };
    if (parentNull) {
      params.parentNull = true;
    }
    if (ticketDetails?.StoresList?.value) {
      params.store = ticketDetails?.StoresList?.value;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_CATEGORY_DEPARTMENT_WISE}${dept}`,
      null,
      params
    )
      .then((response) => {
        if (!response?.message) {
          const ticketCategoriesList = response.map((item) => {
            const { name, id, attachments } = item;
            return { label: name, value: id, attachments: attachments || [] };
          });
          setticketCategoriesList(ticketCategoriesList);
        }
      })
      .catch((err) => console.log(err));
  };

  const onInputChange = (event) => {
    const isDepartmentDropDown = event.target.name === "department";
    const isStoreChanged = event.target.name === "StoresList";
    const isParentCategorySelected = event.target.name === "ticketcategory";
    const isSubCategorySelected = event.target.name === "ticketsubcategory";
    let valueToRemoveOnResetting = {};

    if (isParentCategorySelected) {
      const parentValue = event?.target?.value?.value;
      const isValueSelected = !!parentValue;
      if (isValueSelected) {
        getAllTicketCategoriesForaParent(
          parentValue,
          setSubCategoryList,
          ticketDetails?.StoresList?.value
        );
      }
      valueToRemoveOnResetting.ticketsubcategory = null;
      valueToRemoveOnResetting.categoryAttachments = [];
    }
    // When department is resetted ticket category also has to be resetted as per department
    if (isDepartmentDropDown || isStoreChanged) {
      valueToRemoveOnResetting = {
        ticketcategory: null,
        ticketsubcategory: null,
      };
      if (isStoreChanged) {
        valueToRemoveOnResetting.department = null;
      }
      setSubCategoryList([]);
    }

    if (isSubCategorySelected) {
      valueToRemoveOnResetting.categoryAttachments = [];
    }
    setTicketDetails({
      ...ticketDetails,
      ...valueToRemoveOnResetting,
      [event.target.name]: event.target.value,
    });

    if (isDepartmentDropDown) {
      getTicketCategoriesByCurrentDepartment(event.target.value.value, true);
    }

    const errorKey = convertedEventNamesToErrorObjectKeys[event.target.name];
    const removeError = {};
    removeError[errorKey] = false;
    setErrors((prevError) => ({ ...prevError, ...removeError }));
    if (event.target.name === "StoresList") {
      loadAllDepartmentsAssociatedWithStore(event.target.value.value);
    }
  };

  const createTicketApi = (payload) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.CREATECOMPLAINT}`,
      payload
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          navigate(`${technetEndPoint}/tickets`);
        }
      })
      .catch((err) => console.log(err));
  };

  const createTicket = () => {
    let allerrors = isFormValid(ticketDetails);
    if (Object.keys(allerrors).length > 0) {
      setErrors(allerrors);
      return;
    }
    const {
      StoresList,
      department,
      details,
      ticketcategory,
      ticketsubcategory,
      storeContact,
      email,
    } = ticketDetails;
    const additionalDetails = pick(ticketDetails, [
      "contactPerson",
      "categoryAttachments",
    ]);
    const category = ticketsubcategory?.value
      ? parseInt(ticketsubcategory?.value)
      : parseInt(ticketcategory.value);

    let payload = {
      store: parseInt(StoresList.value),
      dept: parseInt(department.value),
      category,
      details,
      storeContact,
      email,
      ...additionalDetails,
    };
    if (multipleFiles.length > 0) {
      const formData = new FormData();
      let sizeError = false;
      multipleFiles.forEach((item) => {
        if (item.size > 1000000) {
          sizeError = true;
        }
        formData.append("upload", item);
      });

      if (sizeError) {
        toast.error("File size should not exceed 1MB");
        return;
      }

      return invokeUploadFile(
        `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.UPLOAD_FILES}`,
        formData
      )
        .then((response) => {
          if (response.status === 200) {
            payload["attachments"] = response.data;
            createTicketApi(payload);
          } else {
            toast.error(
              (response.data && response.data.message) || "Upload Failed"
            );
          }
        })
        .catch((err) => {
          toast.error("Upload Failed");
        });
    }
    createTicketApi(payload);
  };

  const inputElements = (config) => {
    return (
      <div className="form-row" key={config.label}>
        <span className="label">{config.label}</span>
        {config.type === "select" ? (
          <div>
            <AutocompleteComponent
              label="Select..."
              onChange={(e, value) => {
                onInputChange({
                  target: {
                    name: config.name,
                    value: value,
                  },
                });
              }}
              options={config.options}
              disableClear={true}
              value={config?.value || null}
            />

            <div className="danger-text"> {config.helperText}</div>
          </div>
        ) : config.type === "text" || config.type === "number" ? (
          <TextField
            size="small"
            value={config.value}
            name={config.name}
            type={config.type}
            sx={{ ...sx, width: "100%" }}
            onChange={onInputChange}
            className="new-textfield"
            error={config.error}
            helperText={config.error ? "Required" : ""}
          />
        ) : config.type === "categoryAttachments" ? (
          <>
            <CategoryDoc
              showModal={false}
              mainSetData={setTicketDetails}
              fileDetails={config.details}
              errorMsg={config.error ? "Required" : ""}
              data={ticketDetails}
              attachmentKey="categoryAttachments"
            />
          </>
        ) : (
          <TextField
            size="small"
            value={config.value}
            name={config.name}
            type={config.type}
            sx={{ ...sx, width: "100%" }}
            onChange={onInputChange}
            error={config.error}
            className="new-textfield"
            helperText={config.error ? "Required" : ""}
            multiline
            rows={10}
          />
        )}
      </div>
    );
  };

  return (
    <div className="create-tickets">
      <div className="back-container">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
        </IconButton>
        <span className="s-heading">New Ticket</span>
      </div>
      <div className="create-ticket-form">
        {getFormConfig(
          ticketDetails,
          storeList,
          ticketCategoriesList,
          departmentsList,
          subCategoryList,
          errors
        ).map((config) => {
          return inputElements(config);
        })}

        <SiFileUpload
          filesLimit={5}
          setMultipleFilesFunction={setMultipleFiles}
          multiple={true}
        />

        <div className="buttons">
          <CommonBtn
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => navigate("/")}
          >
            Go Back
          </CommonBtn>
          <CommonBtn
            size="small"
            variant="contained"
            color="primary"
            onClick={createTicket}
          >
            Create Ticket
          </CommonBtn>
        </div>
      </div>
    </div>
  );
};
