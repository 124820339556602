import CustomModal from "../../../common-utilities/core/modal";
import "./index.scss";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export const TableFilesModal = ({
  title,
  closeModal,
  data = [],
  customModalContentClass,
}) => {
  return (
    <CustomModal
      title={title}
      onClose={() => closeModal(null)}
      contentClassName={{
        headerBackgroundColor: "#1756f3",
        customClass: `${
          customModalContentClass ? customModalContentClass : ""
        }`,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {data.map((item, index) => {
          return (
            <div className="downloadgrid" key={index}>
              <div>{item?.label || item?.type}</div>
              {!item.fileName ? (
                <div>{item?.value}</div>
              ) : (
                <>
                  <div className="downloadSubGrid">
                    <div>{item?.fileName}</div>
                    <div>
                      <a target="_blank" download={true} href={item?.link}>
                        <DownloadForOfflineIcon color="action" />
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};
