import { getTableHeaderConfigForDetailedReport } from "./helper";
import { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";

import { dateFun, exportToExcel, getValue, USER_ROLES } from "../../utils";
import { DateRangePicker } from "rsuite";
import {
  caseStatuses,
  storeTypeLabels,
  DateRangePickerLabels,
  getAllYearLabels,
  monthLabels,
  sortDateLabels,
} from "../../utils/labels";
import CheckBoxSelect from "../../../common-utilities/core/checkbox-select";
import { getOfflineData } from "../../utils/offline-services";
import "./index.scss";
import "rsuite/dist/rsuite.min.css";
import {
  checkForUserRoles,
  showSelectDCAccordingToRoles,
} from "../../utils/select-dropwdown-conditions";
import { setCaseFilterForQueryingData } from "../../utils/setcaseFilter";
import { getSelectVendorList } from "../../utils/vendor";
import { getSelectDcList } from "../../utils/dc";
import { daysExcludingWeekends } from "../../../common-utilities/utils/misc";
import Select from "react-select";
import { monthNumberMap } from "../../../common-utilities/utils/constants";
import MuiTable from "../../../common-utilities/core/mui-table";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

const DetailedReport = ({ intranetTokenKey }) => {
  const [reportData, setReportData] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [dcList, setDCList] = useState([]);
  const user = getOfflineData(
    intranetTokenKey ? intranetTokenKey : null,
    "user"
  );

  const showSelectDC = showSelectDCAccordingToRoles();

  const [complaintTypeList, setComplaintTypeList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productCategoryKeys, setProductCategoryKeys] = useState({});
  const [yearLabels, setYearLabels] = useState([]);
  const [productList, setProductList] = useState([]);
  const [reportFilters, setReportFilters] = useState({
    startDate: null,
    endDate: null,
    vendor: [],
    dc: [],
    complaintType: [],
    storeType: [],
    productCategoryList: [],
    productList: [],
    complaintStatusList: [],
    complaintId: "",
    year: [],
    month: [],
    sortingOrder: sortDateLabels[1],
    storeId: null,
  });

  const [
    reportFiltersAfterClickingOnApplyButton,
    setReportFiltersAfterClickingOnApplyButton,
  ] = useState({
    startDate: null,
    endDate: null,
    vendor: [],
    dc: [],
    complaintType: [],
    storeType: [],
    productCategoryList: [],
    productList: [],
    complaintStatusList: [],
    complaintId: "",
    year: [],
    month: [],
    sortingOrder: sortDateLabels[1],
    storeId: null,
  });

  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });

  const getComplaintTypeList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.COMPLAINT_TYPE_LIST}`,
      null,
      null,
      null,
      intranetTokenKey
    )
      .then((res) => {
        if (!res?.message) {
          const results = res.map((item) => {
            return { label: getValue(item.name, true), value: item.id };
          });
          setComplaintTypeList(results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllProductCategoryNames = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.GET_ALL_PRODUCT_CATEGORY_NAMES}`,
      null,
      null,
      null,
      intranetTokenKey
    )
      .then((res) => {
        if (!res?.message) {
          setProductCategoryList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadData(filters);
    (async () => {
      const dc = await getSelectDcList(intranetTokenKey);
      setDCList(dc);
      const vendor = await getSelectVendorList(intranetTokenKey);
      setVendorList(vendor);
      const yearLabelList = getAllYearLabels();
      setYearLabels(yearLabelList);
    })();
    getComplaintTypeList();
    getAllProductCategoryNames();
  }, []);
  const dateRangeHandler = (e) => {
    if (!e) {
      setReportFilters((prevVal) => ({
        ...prevVal,
        startDate: null,
        endDate: null,
      }));
      return;
    }
    setReportFilters((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };

  const setSelectValueOnChange = (e, details, productList) => {
    const name = typeof details === "object" ? details.name : details;

    let productListFilter = {};
    if (productList) {
      productListFilter = { productList };
    }

    setReportFilters((prevFilters) => ({
      ...prevFilters,
      [name]: e,
      ...productListFilter,
    }));
  };

  const loadData = (
    filter,
    downloadReports,
    getDataOnTheBasisOfClickingOnApplyButton
  ) => {
    const isFilterPresent = typeof filter === "object" && filter;
    const providedFilters = isFilterPresent ? { ...filter } : { ...filters };
    if (!providedFilters.limit) {
      providedFilters.limit = filters.limit;
    }
    if (!providedFilters.page) {
      providedFilters.page = filters.page;
    }
    if (!providedFilters.sortBy) {
      providedFilters.sortBy = filters.sortBy;
    }
    if (getDataOnTheBasisOfClickingOnApplyButton) {
      const {
        vendor,
        dc,
        complaintType,
        storeType,
        productList,
        complaintStatusList,
      } = reportFiltersAfterClickingOnApplyButton;

      if (vendor.length > 0) {
        providedFilters.vendor = JSON.stringify(
          vendor.map((item) => item.value)
        );
      }
      setCaseFilterForQueryingData(
        reportFiltersAfterClickingOnApplyButton,
        providedFilters
      );

      if (dc.length) {
        providedFilters.dc = JSON.stringify(dc.map((item) => item.value));
      }
      if (reportFiltersAfterClickingOnApplyButton.startDate) {
        providedFilters.startDate =
          reportFiltersAfterClickingOnApplyButton.startDate;
      }

      if (reportFiltersAfterClickingOnApplyButton.endDate) {
        providedFilters.endDate =
          reportFiltersAfterClickingOnApplyButton.endDate;
      }

      if (complaintType.length > 0) {
        providedFilters.complaintType = JSON.stringify(
          complaintType.map((item) => item.value)
        );
      }

      if (storeType.length > 0) {
        providedFilters.storeType = JSON.stringify(
          storeType.map((item) => item.bl)
        );
      }

      if (productList.length > 0) {
        providedFilters.product = JSON.stringify(
          productList.map((item) => item.id)
        );
      }
      if (complaintStatusList.length > 0) {
        providedFilters.status = JSON.stringify(
          complaintStatusList.map((item) => item.bl)
        );
      }
    } else {
      const {
        vendor,
        dc,
        complaintType,
        storeType,
        productList,
        complaintStatusList,
      } = reportFilters;

      if (vendor.length > 0) {
        providedFilters.vendor = JSON.stringify(
          vendor.map((item) => item.value)
        );
      }

      setCaseFilterForQueryingData(reportFilters, providedFilters);

      if (complaintStatusList.length > 0) {
        providedFilters.status = JSON.stringify(
          complaintStatusList.map((item) => item.bl)
        );
      }

      if (dc.length) {
        providedFilters.dc = JSON.stringify(dc.map((item) => item.value));
      }

      if (reportFilters.startDate) {
        providedFilters.startDate = reportFilters.startDate;
      }

      if (reportFilters.endDate) {
        providedFilters.endDate = reportFilters.endDate;
      }

      if (complaintType.length > 0) {
        providedFilters.complaintType = JSON.stringify(
          complaintType.map((item) => item.value)
        );
      }

      if (storeType.length > 0) {
        providedFilters.storeType = JSON.stringify(
          storeType.map((item) => item.bl)
        );
      }

      if (productList.length > 0) {
        providedFilters.product = JSON.stringify(
          productList.map((item) => item.id)
        );
      }
    }

    if (downloadReports) {
      providedFilters.limit = reportData.totalResults;
      providedFilters.page = 1;
    }
    addCorrectStartEndDate(providedFilters);
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.DETAILED_REPORT}`,
      null,
      providedFilters,
      null,
      intranetTokenKey
    )
      .then((res) => {
        if (!res?.message) {
          const statusToFrontEndLabel = {};
          caseStatuses.forEach((item) => {
            statusToFrontEndLabel[item.bl] = item.ul;
          });
          const results = res?.[0]?.data?.map((item) => {
            let faultOrganization =
              item?.fault?.acceptedBy === "VENDOR"
                ? item?.vendor?.name
                : item?.fault?.acceptedBy === "DC"
                ? item?.dc?.name
                : "";
            const severity = `${item?.severity?.[0]}${item?.severity
              ?.slice(1)
              .toLowerCase()}`;
            const status = `${statusToFrontEndLabel[item?.status] || ""}`;
            item["Closed Against Store"] = item?.closeAgainstStore;
            item["Credit confirmation by store"] =
              item?.creditConfirmationByStore;
            const closingDays = daysExcludingWeekends(
              item?.closedAt,
              item?.createdAt
            );
            item["Total days for closing case"] = closingDays;
            item["Closed At"] = item?.closedAt;
            item["Closing Message"] = item?.closingMessage;
            item["Credit Issuance Date"] =
              item?.creditInfo?.details?.creditNotesIssuanceDate;
            item["Product Name"] = item?.productDetails?.name;

            return {
              "Product Name": item?.productDetails?.name,
              ...item,
              createdAt: dateFun(item.createdAt),
              closedAt: dateFun(item?.closedAt),
              storeNumber: item?.storeDetails?.storeNumber,
              storeType: item?.storeDetails?.type?.split("_")?.join?.(" "),
              country: item?.storeDetails?.country,
              complaintType: item?.complaintType?.name,
              supplier: item?.vendor?.name,
              distributor: item?.dc?.name,
              faultOrganization,
              city: item?.storeDetails?.city,
              state: item?.storeDetails?.state,
              expirationDate: dateFun(
                item?.caseDetails?.additionalInformation?.expirationDate
              ),
              deliveryDate: dateFun(
                item?.caseDetails?.additionalInformation?.deliveryDate
              ),
              dcInvoice: item?.caseDetails?.additionalInformation?.dcInvoice,
              productionDate: dateFun(
                item?.caseDetails?.additionalInformation?.productionDate
              ),
              createdBy: item?.createdBy?.name,
              unitsAffected: item?.caseDetails?.productInfo?.unitsAffected,
              productNumber: item?.caseDetails?.productInfo?.code,
              batchlot: item?.caseDetails?.additionalInformation?.lotCode,
              affectedUOM: item?.caseDetails?.productInfo?.unitOfMeasure,
              creditQuantity: item?.creditInfo?.details?.creditAmount,

              creditMethod: item?.creditInfo?.details?.creditMethod,
              creditNotes: item?.creditInfo?.details?.creditNotes,
              creditCurrency: item?.creditInfo?.details?.creditCurrency,
              severity,
              status,
            };
          });

          if (downloadReports) {
            const dataList = getTableHeaderConfigForDetailedReport(true, true);
            const downloadData = results.map((item) => {
              const caseDate = item?.createdAt?.split("-") || [];
              const month = parseInt(caseDate[1]);

              const rowData = {
                "Case Month": monthNumberMap[month] || "",
              };
              dataList.forEach((columnData) => {
                rowData[columnData.label] = item[columnData.id];
              });
              rowData["Area Manager Name"] = item?.storeDetails?.am?.name;
              rowData["Area Manager Email"] = item?.storeDetails?.am?.email;
              if (item.status === "Closed") {
                rowData["Closed Against Store"] = item?.closeAgainstStore
                  ? "Yes"
                  : "No";
                rowData["Credit confirmation by store"] =
                  item?.creditConfirmationByStore ? "Yes" : "No";
                const closingDays = item["Total days for closing case"];
                rowData["Total days for closing case"] = `${
                  ![null, undefined, "", NaN].includes(closingDays)
                    ? `${closingDays} days`
                    : ""
                } `;
                rowData["Closed At"] = item?.closedAt;
                rowData["Closing Message"] = item?.closingMessage;
              }
              return rowData;
            });
            exportToExcel(downloadData, "Detailed Report");
            return;
          }
          const totalResults = res?.[0]?.count || 0;
          const totalPages = Math.ceil(totalResults);

          setReportData({
            results,
            totalPages,
            totalResults: res?.[0]?.totalResults || 0,
            pageStart: res?.[0]?.pageStart || 0,
            pageEnd: res?.[0]?.pageEnd || 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductListByCategory = (category, curList) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.GET_ALL_PRODUCT_NAMES_BY_CATEGORY}${category}`,
      null,
      null,
      null,
      intranetTokenKey
    )
      .then((res) => {
        if (!res?.message) {
          const results = res?.map((item) => {
            const label = `${item?.name} - ${item?.code}`;
            return { ...item, category, label };
          });
          setProductList([...results, ...curList]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="qnet-main-report">
      <div className="reports-table">
        <div className="filter-section">
          <div className="left-s">
            <DateRangePicker
              cleanable={true}
              ranges={DateRangePickerLabels}
              className="report-time-picker"
              value={[
                reportFilters.startDate
                  ? new Date(reportFilters.startDate)
                  : null,
                reportFilters.endDate ? new Date(reportFilters.endDate) : null,
              ]}
              placeholder="Select time period"
              onChange={dateRangeHandler}
            />

            {user?.role?.belongsTo !== "VENDOR" && (
              <CheckBoxSelect
                options={vendorList}
                value={reportFilters.vendor}
                label="Select Vendor"
                labelKey="label"
                valueKey="value"
                id="report-status-multi-select"
                onChange={(option) => {
                  setSelectValueOnChange(option, "vendor");
                }}
              />
            )}

            {showSelectDC && (
              <CheckBoxSelect
                options={dcList}
                value={reportFilters.dc}
                label="Select DC"
                labelKey="label"
                valueKey="value"
                id="report-status-multi-select"
                onChange={(option) => {
                  setSelectValueOnChange(option, "dc");
                }}
              />
            )}
            <CheckBoxSelect
              options={complaintTypeList}
              value={reportFilters.complaintType}
              label="Select complaint type"
              labelKey="label"
              valueKey="value"
              id="report-status-multi-select"
              onChange={(option) => {
                setSelectValueOnChange(option, "complaintType");
              }}
            />
            {user?.role?.type !== USER_ROLES.STORE && (
              <CheckBoxSelect
                options={storeTypeLabels}
                value={reportFilters.storeType}
                label="Select Store Type"
                labelKey="ul"
                valueKey="bl"
                id="report-status-multi-select"
                onChange={(option) => {
                  setSelectValueOnChange(option, "storeType");
                }}
              />
            )}

            <CheckBoxSelect
              options={productCategoryList}
              value={reportFilters.productCategoryList}
              label="Select Product Category"
              labelKey="name"
              valueKey="id"
              id="report-status-multi-select"
              onChange={(option) => {
                const allIds = {};
                option?.forEach((item) => {
                  allIds[item.id] = true;
                });
                const productCategoriesToRemove = {};

                Object.keys(productCategoryKeys).forEach((item) => {
                  if (allIds[item] === undefined) {
                    productCategoriesToRemove[item] = true;
                  }
                });

                let categorySelected = null;
                Object.keys(allIds).forEach((item) => {
                  if (productCategoryKeys[item] == undefined) {
                    categorySelected = item;
                  }
                });
                setProductCategoryKeys(allIds);

                const allProducts = [...productList];

                const finalProductList = [];
                allProducts.forEach((item) => {
                  if (productCategoriesToRemove[item.category] === undefined) {
                    finalProductList.push(item);
                  }
                });

                if (categorySelected != null) {
                  getProductListByCategory(categorySelected, allProducts);
                } else {
                  setProductList(finalProductList);
                }
                const selectedProductsList = [...reportFilters.productList];
                const finalSelectedProductList = [];
                selectedProductsList.forEach((item) => {
                  if (productCategoriesToRemove[item.category] === undefined) {
                    finalSelectedProductList.push(item);
                  }
                });
                setSelectValueOnChange(
                  option,
                  "productCategoryList",
                  finalSelectedProductList
                );
              }}
            />

            <CheckBoxSelect
              options={productList}
              value={reportFilters.productList}
              label="Select Product"
              labelKey="label"
              valueKey="id"
              id="report-status-multi-select"
              onChange={(option) => {
                setSelectValueOnChange(option, "productList");
              }}
            />

            <CheckBoxSelect
              options={caseStatuses}
              value={reportFilters.complaintStatusList}
              label="Select Complaint Status"
              labelKey="ul"
              valueKey="bl"
              id="report-status-multi-select"
              onChange={(option) => {
                setSelectValueOnChange(option, "complaintStatusList");
              }}
            />

            <CheckBoxSelect
              options={monthLabels}
              value={reportFilters.month}
              label="Select Month"
              labelKey="ul"
              valueKey="bl"
              id="report-status-multi-select"
              onChange={(option) => {
                setSelectValueOnChange(option, "month");
              }}
            />

            <CheckBoxSelect
              options={yearLabels}
              value={reportFilters.year}
              label="Select Year"
              labelKey="ul"
              valueKey="bl"
              id="report-status-multi-select"
              onChange={(option) => {
                setSelectValueOnChange(option, "year");
              }}
            />

            <Select
              options={sortDateLabels}
              placeholder="Select sorting order"
              classNamePrefix="subway-select"
              className="input case-filter-select"
              name="sortingOrder"
              value={reportFilters?.sortingOrder || null}
              onChange={setSelectValueOnChange}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
              }}
            />
          </div>
        </div>

        <div className="download-report-button-container">
          <div className="cases-stats">
            <b>
              {reportData?.totalResults
                ? `${getValue(reportData?.pageStart)} to ${getValue(
                    reportData?.pageEnd
                  )} of ${getValue(reportData?.totalResults)}`
                : "0 to 0 of 0"}
            </b>
            <div>
              <TextField
                type="number"
                value={reportFilters.complaintId}
                onChange={(e) => {
                  setSelectValueOnChange(e?.target?.value || "", "complaintId");
                }}
                placeholder="Select Case ID"
              />
            </div>

            <div>
              <TextField
                type="number"
                value={reportFilters.storeId}
                onChange={(e) => {
                  setSelectValueOnChange(e?.target?.value || "", "storeId");
                }}
                placeholder="Select Store ID"
              />
            </div>
          </div>
          <div className="inner-button-container">
            <div>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  const allFilter = { ...reportFilters };
                  setReportFiltersAfterClickingOnApplyButton((prevFilters) => ({
                    ...prevFilters,
                    ...allFilter,
                  }));

                  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                  loadData({ ...filters, page: 1 });
                }}
              >
                Apply
              </Button>
            </div>

            <div>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  loadData(null, true, true);
                }}
                disabled={(reportData.results || []).length === 0}
              >
                Download reports
              </Button>
            </div>
          </div>
        </div>
        <MuiTable
          columnsList={getTableHeaderConfigForDetailedReport()}
          dataList={reportData.results || []}
          filters={filters}
          pageCount={reportData.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            loadData(
              {
                ...filters,
                page,
              },
              null,
              true
            );
          }}
        ></MuiTable>
        {/* <MaterialUITable
          columnsList={getTableHeaderConfigForDetailedReport()}
          dataList={reportData.results || []}
          pageCount={reportData.totalPages}
          filters={filters}
          onChange={(e, page) => {
            setFilters({
              ...filters,
              page,
            });
            loadData(
              {
                ...filters,
                page,
              },
              null,
              true
            );
          }}
        /> */}
      </div>
    </div>
  );
};

export default DetailedReport;
