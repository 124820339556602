/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import { ProductsHeaderConfig } from "./config";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { Button, TextField } from "@mui/material";
import CustomModal from "../../../common-utilities/core/modal";
import ConfirmModal from "../../../common-utilities/core/confirm-modal";
import { toast } from "react-toastify";
import Select from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import MaterialUITable from "../../../common-utilities/core/material-ui-table";
import MuiTable from "../../../common-utilities/core/mui-table";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

const Products = () => {
  const [products, setProducts] = useState({});
  const [productDetails, setProductDetails] = useState(null);
  const [productCateogryList, setProductCategoryList] = useState([]);
  const [deleteDetails, setDeleteDetails] = useState(null);
  const [filtersAfterClickingOnApply, setFiltersAfterClickingOnApply] =
    useState({
      code: "",
    });

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
    code: "",
  });

  const getProductCategoryList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.GET_ALL_PRODUCT_CATEGORY_NAMES}`
    )
      .then((res) => {
        if (!res?.message) {
          setProductCategoryList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProductCategoryList();
  }, []);

  useEffect(() => {
    loadData(filters);
  }, []);

  const loadData = (filtersObj, usePreviouslyAppliedFilters) => {
    const isFilterPresent = typeof filtersObj === "object" && filtersObj;
    const userFilters = isFilterPresent ? { ...filtersObj } : { ...filters };
    if (!userFilters.limit) {
      userFilters.limit = filters.limit;
    }
    if (!userFilters.sortBy) {
      userFilters.sortBy = filters.sortBy;
    }
    if (!userFilters.page) {
      userFilters.page = filters.page;
    }

    if (!userFilters.code) {
      userFilters.code = filters.code;
    }

    if (usePreviouslyAppliedFilters) {
      userFilters.code = filtersAfterClickingOnApply.code;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${QNET_HOSTNAME}${REST_URLS.PRODUCTS}`,
      null,
      userFilters
    )
      .then((response) => {
        if (response.results) {
          setProducts(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRowClick = (details, actionType) => {
    if (actionType === "DELETE") {
      setDeleteDetails(details);
    } else {
      setProductDetails(details);
    }
  };

  const onInputChange = (event) => {
    setProductDetails({
      ...productDetails,
      [event.target.name]: event.target.value,
    });
  };

  const createRecord = () => {
    const payload = { ...productDetails };
    if (payload.category) {
      payload.category = payload.category.id;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${QNET_HOSTNAME}${REST_URLS.PRODUCTS}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }

        if (response.code) {
          toast.success("Product created successfully", { autoClose: 2000 });
          setProductDetails(null);
          loadData(filters, true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateRecord = (details) => {
    const method = details?.archive ? HTTP_METHODS.DELETE : HTTP_METHODS.PUT;
    const convertedDetails = { ...details };
    if (convertedDetails.category) {
      convertedDetails.category = convertedDetails.category.id;
    }
    invokeApi(
      method,
      `${QNET_HOSTNAME}${REST_URLS.PRODUCTS}/${details.id}`,
      convertedDetails
    ).then((response) => {
      if (method === HTTP_METHODS.DELETE) {
        if (response === "ok") {
          toast.success("Product deleted successfully", { autoClose: 2000 });
          setProductDetails(null);
          setDeleteDetails(null);
          loadData(filters, true);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      }
      if (method === HTTP_METHODS.PUT) {
        if (!response?.message) {
          toast.success("Product updated successfully", { autoClose: 2000 });
          setProductDetails(null);
          setDeleteDetails(null);
          loadData(filters, true);
        } else {
          toast.error(response?.message, { autoClose: 2000 });
        }
      }
    });
  };

  const isDisabled = () => {
    const { code, description, name, category } = productDetails;
    return !code || !description || !name || !category;
  };

  return (
    <>
      <div className="products-container">
        <div className="create-new"></div>
        <div>
          <div className="main-search-field">
            <div className="search-field">
              <TextField
                placeholder="Search by code"
                variant="outlined"
                color="primary"
                size="small"
                value={filters.code || ""}
                name="name"
                onChange={(event) => {
                  event.persist();
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    code: event?.target?.value,
                  }));
                }}
              />
              <SearchIcon
                className="main-search-icon"
                onClick={() => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    page: 1,
                  }));
                  setFiltersAfterClickingOnApply((prevFilters) => ({
                    ...prevFilters,
                    code: filters.code,
                  }));
                  loadData({ ...filters, page: 1 }, false);
                }}
              />
            </div>
            <Button variant="contained" onClick={() => setProductDetails({})}>
              Create
            </Button>
          </div>
          <MuiTable
            columnsList={ProductsHeaderConfig}
            dataList={products.results || []}
            filters={filters}
            pageCount={products.totalPages}
            onClick={onRowClick}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData(
                {
                  ...filters,
                  page,
                },
                true
              );
            }}
          ></MuiTable>
          {/* <MaterialUITable
            columnsList={ProductsHeaderConfig}
            dataList={products.results || []}
            pageCount={products.totalPages}
            filters={filters}
            onChange={(_, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData(
                {
                  ...filters,
                  page,
                },
                true
              );
            }}
            onClick={onRowClick}
          /> */}
        </div>

        {productDetails && (
          <CustomModal
            title={productDetails.id ? "Edit Product" : "Add Product"}
            onClose={() => setProductDetails(null)}
          >
            <div className="form-container">
              <div>
                <TextField
                  size="small"
                  className="product-input"
                  label="Code"
                  value={productDetails.code || ""}
                  onChange={onInputChange}
                  name="code"
                />
              </div>
              <div>
                <TextField
                  size="small"
                  className="product-input"
                  label="Description"
                  value={productDetails.description || ""}
                  onChange={onInputChange}
                  name="description"
                />
              </div>

              <TextField
                size="small"
                label="Name"
                className="product-input"
                value={productDetails.name || ""}
                onChange={onInputChange}
                name="name"
              />
              <div className="category-grid">
                <div>Select Category</div>
                <Select
                  options={productCateogryList}
                  placeholder="Product Category"
                  classNamePrefix="subway-select"
                  getOptionLabel={(op) => op.name}
                  getOptionValue={(op) => op.id}
                  value={productDetails.category || null}
                  onChange={(value) => {
                    setProductDetails((prevDetails) => ({
                      ...prevDetails,
                      category: value,
                    }));
                  }}
                />
              </div>
              <Button
                variant="contained"
                fullWidth
                disabled={isDisabled()}
                onClick={() => {
                  {
                    productDetails.id
                      ? updateRecord(productDetails)
                      : createRecord();
                  }
                }}
              >
                Save
              </Button>
            </div>
          </CustomModal>
        )}
        {deleteDetails && (
          <ConfirmModal
            onNo={() => setDeleteDetails(null)}
            onYes={() => updateRecord({ ...deleteDetails, archive: true })}
          />
        )}
      </div>
    </>
  );
};

export default Products;
