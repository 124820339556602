import { toast } from "react-toastify";
import { exportToExcel } from "../../../common-utilities/utils";
import { INVOICE_TRACKING_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";
import {
  getAllExcelColumns,
  getExcelColumns,
  processExcelDates,
} from "../../../common-utilities/utils/excel";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";

export const getPendingInvoices = (slug, token) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INVOICE_TRACKING_HOSTNAME}${REST_URLS.GET_PAYMENT_PENDING_INVOICE}`,
    null,
    null,
    null,
    slug,
    token
  )
    .then((res) => {
      if (!res?.length) {
        toast.info("No completed invoices", { autoClose: 2000 });
      }
      if (res?.length) {
        exportToExcel(
          res,
          "Invoices Upload Details",
          false,
          false,
          (workbook, worksheet, sheetName, headers) => {
            const columns = getAllExcelColumns(worksheet, headers);
            const dateColumns = getExcelColumns(
              headers,
              ["Date Of Payment", "Delivery Date"],
              columns
            );
            const convertedWorkSheet = processExcelDates(
              res,
              worksheet,
              dateColumns
            );

            workbook.Sheets[sheetName] = convertedWorkSheet;
            workbook.SheetNames.push(sheetName);
          }
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const vendorList = (filter, slug, token, setDataFunc) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INVOICE_TRACKING_HOSTNAME}${REST_URLS.VENDOR_LIST}`,
    null,
    filter,
    null,
    slug,
    token
  )
    .then((res) => {
      const list =
        res?.map?.((item) => {
          return { label: `${item.name} - ${item.code}`, value: item.id };
        }) || [];
      setDataFunc(list);
    })
    .catch((err) => {
      setDataFunc([]);
    });
};
