import { useEffect, useState } from "react";
import "./index.scss";
import { invokeApi } from "../../utils/http-service";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { toast } from "react-toastify";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getHeaderConfig } from "./config";
import SearchIcon from "@mui/icons-material/Search";
import { Button, TextField } from "@mui/material";
import CustomModal from "../../../common-utilities/core/modal";

const ExternalClientSecret = () => {
  const [clientSecrets, setClientSecrets] = useState({});
  const [addClientSecret, setAddClientSecret] = useState(false);
  const [clientId, setClientId] = useState("");
  const [formData, setFormData] = useState({});
  const [editObj, setEditObj] = useState({});
  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    page: 1,
    limit: 10,
  });
  const loadData = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.GET_CLIENT_SECRET}`,
      null,
      filter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setClientSecrets(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const editFunc = (data, type) => {
    if (type === "EDIT") {
      setEditObj(data);
      setFormData({
        clientId: data?.clientId,
        clientSecret: data?.clientSecret,
      });
      setAddClientSecret(true);
    }
  };
  const submitHandler = () => {
    const payload = {};
    if (formData.clientId) {
      payload["clientId"] = formData.clientId;
    }
    if (formData.clientSecret) {
      payload["clientSecret"] = formData.clientSecret;
    }
    if (editObj?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.UPDATE_CLIENT_SECRET}/${editObj.id}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Updated Successfully", { autoClose: 2000 });
            setAddClientSecret(false);
            setFormData({});
            setEditObj({});
            loadData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.ADD_CLIENT_SECRET}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Created Successfully", { autoClose: 2000 });
            setAddClientSecret(false);
            setFormData({});
            loadData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const addClientSecretHandler = () => {
    setAddClientSecret(true);
  };
  useEffect(() => {
    loadData(filters);
  }, []);
  return (
    <div className="client-secret-con">
      <div className="button-div">
        <div className="search-field">
          <TextField
            fullWidth
            sx={{ my: 1 }}
            label="Search by Client Id"
            onChange={(e) => setClientId(e?.target?.value)}
            value={clientId}
            size="small"
            className="new-textfield"
          />

          <SearchIcon
            className="main-search-icon"
            onClick={() => {
              loadData({ ...filters, page: 1, clientId: clientId });
            }}
          />
        </div>

        <Button
          variant="contained"
          className="button"
          onClick={addClientSecretHandler}
        >
          Create Client Secret
        </Button>
      </div>
      <MuiTable
        columnsList={getHeaderConfig}
        dataList={clientSecrets.results || []}
        filters={filters}
        pageCount={clientSecrets.totalPages}
        onClick={editFunc}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      ></MuiTable>
      {addClientSecret && (
        <CustomModal
          onClose={() => {
            setAddClientSecret(false);
            setFormData({});
            if (editObj.id) {
              setEditObj({});
            }
          }}
          title={editObj?.id ? "Update Client Secret" : "New Client Secret"}
        >
          <TextField
            fullWidth
            label="Client Id"
            onChange={(e) =>
              setFormData((prevVal) => ({
                ...prevVal,
                clientId: e.target.value,
              }))
            }
            value={formData?.clientId || ""}
            size="small"
          />
          <TextField
            fullWidth
            label="Client Secret"
            onChange={(e) =>
              setFormData((prevVal) => ({
                ...prevVal,
                clientSecret: e.target.value,
              }))
            }
            value={formData?.clientSecret || ""}
            sx={{ mt: 2 }}
            size="small"
          />
          <Button
            size="small"
            variant="contained"
            disabled={
              editObj.id ? false : !formData.clientId || !formData.clientSecret
            }
            sx={{ mt: 3 }}
            fullWidth
            onClick={submitHandler}
          >
            Save
          </Button>
        </CustomModal>
      )}
    </div>
  );
};

export default ExternalClientSecret;
