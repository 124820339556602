import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import BudgetReport from "./budget";
import PoCapexReport from "./poCapex";
import PoMaster from "./master-po";
import GrnReport from "./grn";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Reports = () => {
  const [value, setValue] = useState(2);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div>
        <div className="tabs-section">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Master" />
            <Tab label="Budget" />
            <Tab label="Purchase Order" />
            <Tab label="GRN" />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <PoMaster />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BudgetReport />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PoCapexReport />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <GrnReport />
        </TabPanel>
      </div>
    </div>
  );
};

export default Reports;
