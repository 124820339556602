import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import CategoryList from './category-list';
import './index.scss';
import SubCategoryList from './sub-category-list';
import ItemList from './item-list';
import VendorItemMap from './vendor-item-map';
import Company from './company';
import DepartmentList from './department-list';
import VendorList from './vendor-list';
import Locations from './locations';
import ForeignCurrency from './foreign-currency';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const MasterData = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="budget-list">
      <div className="tabs-section">
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          <Tab label="Items" />
          <Tab label="Category" />
          <Tab label="Subcategory" />
          <Tab label="Vendor Item Mapping" />
          <Tab label="Company" />
          <Tab label="Vendors" />
          <Tab label="Departments" />
          <Tab label="Locations" />
          <Tab label="Foreign Currency" />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <ItemList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CategoryList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SubCategoryList />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <VendorItemMap />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Company />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <VendorList />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <DepartmentList />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Locations />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <ForeignCurrency />
      </TabPanel>
    </div>
  );
};

export default MasterData;
