import { getValue } from "../../utils";
import { toast } from "react-toastify";
import { dateFun, USER_ROLES } from "../../utils";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { getProductByCode, getStoreDataByStoreId } from "./data-apis";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

export const setListOfComplaintTypeDropdown = (setComplaintTypeList) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${QNET_HOSTNAME}${REST_URLS.COMPLAINT_TYPE_LIST}`
  )
    .then((res) => {
      if (!res?.message) {
        const results = res.map((item) => {
          return { label: getValue(item.name, true), value: item.id };
        });

        setComplaintTypeList(results);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setProductListForDropDown = (setProductList) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${QNET_HOSTNAME}${REST_URLS.GET_ALL_PRODUCT_NAMES}`
  )
    .then((res) => {
      const results = res?.map((item) => {
        return {
          label: `${getValue(item.name)} - ${getValue(item.code)}`,
          value: item.code,
        };
      });
      setProductList(results);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setStoreListForDropdown = (user, setRestaurantList) => {
  const paramsByUserRole =
    user?.role?.type === USER_ROLES.ADMIN ||
    user?.role?.type === USER_ROLES.RD ||
    user?.role?.type === USER_ROLES.AM
      ? {}
      : { owner: user.id };
  if (user?.role?.type === USER_ROLES.STORE) {
    return;
  }
  invokeApi(
    HTTP_METHODS.GET,
    `${QNET_HOSTNAME}${REST_URLS.STORE_LIST}`,
    null,
    paramsByUserRole
  )
    .then((res) => {
      if (!res?.message) {
        const results = res.map((item) => {
          return { label: item.storeNumber, value: item.id };
        });
        setRestaurantList(results);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCaseDataById = (
  setRestaurantDetails,
  setFormDetails,
  complaintType,
  setOriginalCasesDetails,
  caseId,
  setStateList
) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${QNET_HOSTNAME}${REST_URLS.FETCH_CASE}${caseId}`
  )
    .then((res) => {
      if (res?.message) {
        toast.error(res?.message, { autoClose: 2000 });
        return;
      }
      getStoreDataByStoreId(
        res?.store?.id,
        null,
        setRestaurantDetails,
        setFormDetails,
        complaintType,
        setStateList
      );
      const {
        caseDetails = {},
        complaintNotes = "",
        details = "",
        severity,
        type = "",
        vendor,
      } = res;

      const allDetails = {
        complaintNotes,
        details,
        type: {
          label: `${type[0]}${type.slice(1).toLowerCase()}`,
          value: type,
        },
      };

      if (vendor) {
        allDetails.vendor = {
          label: getValue(vendor.name, true),
          value: vendor.id,
        };
      }

      if (severity) {
        allDetails.severity = {
          label: `${severity[0]}${severity.slice(1).toLowerCase()}`,
          value: severity,
        };
      }
      if (res.complaintType) {
        allDetails.complaintType = {
          label: res.complaintType.name,
          value: res.complaintType.id,
        };
      }

      const { additionalInformation, vendorList, productInfo } = caseDetails;

      if (vendorList) {
        allDetails.vendorList = [...vendorList];
      }
      if (additionalInformation) {
        const { deliveryDate, productionDate, expirationDate, packDate } =
          additionalInformation;
        if (deliveryDate) {
          additionalInformation.deliveryDate = dateFun(deliveryDate);
        }
        if (productionDate) {
          additionalInformation.productionDate = dateFun(productionDate);
        }
        if (expirationDate) {
          additionalInformation.expirationDate = dateFun(expirationDate);
        }
        if (packDate) {
          additionalInformation.packDate = dateFun(packDate);
        }
        allDetails.additionalInformation = { ...additionalInformation };
      }
      if (productInfo) {
        const { unitOfMeasure, dc } = productInfo;
        if (unitOfMeasure) {
          productInfo.unitOfMeasure = {
            label: unitOfMeasure,
            value: unitOfMeasure,
          };
        }
        if (dc) {
          productInfo.dc = { label: dc.name, value: dc.id };
        }
        allDetails.productInfo = { ...productInfo };
      }

      if (allDetails?.productInfo?.code) {
        setOriginalCasesDetails(allDetails);
        getProductByCode(allDetails, setFormDetails);
        getStoreDataByStoreId(
          res.store,
          null,
          setRestaurantDetails,
          setFormDetails,
          complaintType,
          setStateList
        );
        return;
      }
      setOriginalCasesDetails(allDetails);
      setFormDetails(allDetails);
    })
    .catch((err) => {
      console.log(err);
    });
};
