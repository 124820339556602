import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { getStoreHeaderConfig, getHeaderConfigForTableItems } from "./helper";
import SiTable from "../../../common-utilities/core/table";
import { invokeApi } from "../../utils/http-service";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import { DateRangePicker } from "rsuite";
import "./index.scss";
import { lastMonthDate } from "./tickets";
import { exportToExcel } from "../../../common-utilities/utils";
import CustomModal from "../../../common-utilities/core/modal";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import SearchIcon from "@mui/icons-material/Search";
import {
  getEndOfDay,
  getStartOfDay,
} from "../../../common-utilities/utils/time";
const Stores = ({ intranetTokenKey }) => {
  const [reportData, setReportData] = useState([]);
  const [tableItems, setTableItems] = useState(null);
  const [tableItemsDataSlicingIndex, settableItemsDataSlicingIndex] =
    useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };

  const fetchDataHandler = (
    providedFilters,
    getDataOnBasisOfPreviousFilters
  ) => {
    const dataFilters =
      typeof providedFilters === "object"
        ? { ...providedFilters }
        : { ...filters };
    const params = {
      startDate: dateRange.startDate
        ? getStartOfDay(dateRange.startDate)
        : null,
      endDate: dateRange.endDate ? getEndOfDay(dateRange.endDate) : null,
      ...dataFilters,
      sortBy: "-createdAt",
    };
    if (getDataOnBasisOfPreviousFilters) {
      params.startDate = filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.STORE_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      startDate: filterAfterClickingOnApply.startDate
        ? getStartOfDay(filterAfterClickingOnApply.startDate)
        : null,
      endDate: filterAfterClickingOnApply.endDate
        ? getEndOfDay(filterAfterClickingOnApply.endDate)
        : null,
      sortBy: "-createdAt",
    };
    let response = await invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.STORE_REPORTS}`,
      null,
      params,
      null,
      intranetTokenKey
    );
    let convertedResponse = [];
    if (response) {
      const processedResponse = [];
      response?.results?.forEach((item) => {
        const responseItem = {
          Category: item.Category,
          Count: item.Count,
          Department: item.Department,
          // "Store ID": item["Store ID"],
          "Store Number": item["Store Number"],
          "Store Name": item["Store Name"],
        };
        item["Ticket Data"].forEach((item) => {
          const ticketDataItem = {
            "Ticket Id": item.id,
            "Resolve Message": item.resolveMessage,
            Status:
              item?.status.slice(0, 1).toUpperCase() +
              item?.status.slice(1).toLowerCase(),
          };
          processedResponse.push({ ...responseItem, ...ticketDataItem });
        });
      });
      convertedResponse = processedResponse;
    }
    exportToExcel(convertedResponse || [], "Stores");
  };

  const onClickShowDataFunction = (data) => {
    if (data && data["Ticket Data"]) {
      setTableItems(data["Ticket Data"]);
    }
  };

  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s stores">
          <div>
            <DateRangePicker
              cleanable={false}
              placeholder="Select Date Range"
              onChange={dateRangeHandler}
            />
          </div>
          <SearchIcon
            onClick={() => {
              setFilterAfterClickingOnApply({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
              });
              setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
              fetchDataHandler({ ...filters, page: 1 });
            }}
            sx={{ cursor: "pointer" }}
          />
          {/* <Button size="medium" variant="contained">
            Apply
          </Button> */}
        </div>
        {(reportData?.results || []).length !== 0 && (
          <DownloadBtn onClick={downloadReport} />
        )}
      </div>
      <MuiTable
        columnsList={getStoreHeaderConfig()}
        dataList={reportData?.results || []}
        filters={filters}
        pageCount={reportData?.totalPages}
        onClick={onClickShowDataFunction}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler({ ...filters, page }, true);
        }}
      ></MuiTable>
      {/* <SiTable
        header={getStoreHeaderConfig()}
        data={reportData?.results || []}
        filters={filters}
        pageCount={reportData?.totalPages}
        onClick={onClickShowDataFunction}
        onChange={(e, page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler({ ...filters, page }, true);
        }}
      ></SiTable> */}
      {tableItems && (
        <CustomModal
          title="Ticket Details"
          onClose={() => {
            settableItemsDataSlicingIndex(0);
            setTableItems(null);
          }}
          contentClassName={{
            headerBackgroundColor: "#1756f3",
            customClass: "tableModal",
          }}
        >
          <div>
            <SiTable
              header={getHeaderConfigForTableItems()}
              filters={filters}
              data={
                tableItems?.slice(
                  tableItemsDataSlicingIndex * 10,
                  tableItemsDataSlicingIndex * 10 + 10
                ) || []
              }
              pageCount={Math.ceil(tableItems?.length / 10 || 0)}
              onChange={(_, page) => {
                settableItemsDataSlicingIndex(page - 1);
              }}
            ></SiTable>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default Stores;
