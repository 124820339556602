import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../../common-utilities/core/mui-table";
import { getHeaderConfig, REGIONS_LIST, STORE_TYPE } from "./config";
import FileUpload from "../../../common-utilities/core/file-uploader";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/store-master";
import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import { toast } from "react-toastify";
import { Button, Grid, Autocomplete, TextField } from "@mui/material";
// import { exportToExcel } from "../../../../utils";

import Select from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import CustomModal from "../../../common-utilities/core/modal";
import { exportToExcel, pick } from "../../../common-utilities/utils";
import { CreateUpdateStoreModal } from "./create-update-modal";
import { convertObjValuesToStr } from "../../../common-utilities/utils/misc";
import SearchIcon from "@mui/icons-material/Search";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
const StoreMaster = () => {
  const [tableData, setTableData] = useState({});
  const [storeOptions, setStoreOptions] = useState([]);
  const [storeNo, setStoreNo] = useState("");
  const [amOptions, setAmOptions] = useState([]);
  const [am, setAm] = useState("");
  const [modalDetails, setModalDetails] = useState({});
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 5,
  });
  const [ftrsAftrAply, setFtrsAftrAply] = useState({ page: 1, limit: 5 });
  const loadData = (filters) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
      null,
      filters
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setTableData(res);
        }
      })
      .catch((err) => console.log(err));
  };

  const downloadStoresHandler = () => {
    let params = pick(filters, ["region", "storeType"]);
    if (am) {
      const areaManagerName = am.split("-")[0];
      params["am"] = areaManagerName;
    }
    if (storeNo) {
      params["storeNumber"] = storeNo;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.DOWNLOAD_STORES}`,
      null,
      params
    )
      .then((res) => {
        if (res) {
          const storeData = res?.Store || [];
          const dcData = res?.Dc || [];
          const data = [
            {
              sheetName: "Store",
              details: storeData,
              dateColumns: ["Store Opening Date"],
            },
            { sheetName: "Dc", details: dcData },
          ];
          exportToExcel(data, "Store Master", true);
        }
      })
      .catch((err) => console.log(err));
  };

  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
        null,
        {
          storeNumber: searchTerm,
        }
      ).then((res) => {
        const storeList = res.results.map((ele) => ele.storeNumber);
        setStoreOptions(storeList);
      });
    }, 300);
  };
  const getAreaManagerList = (searchTerm) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
        null,
        {
          am: searchTerm,
        }
      ).then((res) => {
        const amList = res.results.map(
          (ele) => `${ele?.am?.name}-${ele.storeNumber}`
        );
        setAmOptions(amList);
      });
    }, 300);
  };
  const onInputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
      setStoreNo(value);
    } else {
      setStoreNo("");
      setStoreOptions([]);
    }
  };
  const areaManagerInputChange = (e, value) => {
    if (value?.trim()) {
      const areaManager = value.split("-")[0];
      getAreaManagerList(areaManager);
      setAm(areaManager);
    } else {
      setAm("");
      setAmOptions([]);
    }
  };
  const searchHandler = () => {
    let params = { ...filters };
    if (am) {
      const areaManagerName = am.split("-")[0];
      params["am"] = areaManagerName;
    }
    if (storeNo) {
      params["storeNumber"] = storeNo;
    }
    setFtrsAftrAply(params);
    loadData(params);
  };
  useEffect(() => {
    loadData(filters);
  }, []);

  const actionIconAndButtonHandler = (type, storeData) => {
    const data = { ...storeData };
    switch (type) {
      case "create new store":
        setModalDetails({
          data: null,
          showModal: true,
        });
        break;
      case "update store details":
        const storeProperties = convertObjValuesToStr(
          data,
          [
            "satelliteNumber",
            "sequenceNumber",
            "storeNumber",
            "latitude",
            "longitude",
            "pinCode",
          ],
          true,
          true
        );

        setModalDetails({
          data: {
            ...data,
            dc: data?.dc?.id ? `${data?.dc?.id}` : "",
            am: data?.am?.id ? `${data?.am?.id}` : "",
            rm: data?.rm?.id ? `${data?.rm?.id}` : "",
            firstLevelEscalation: data?.firstLevelEscalation?.id || null,
            ...storeProperties,
          },
          showModal: true,
        });
        break;
      default:
        break;
    }
  };
  return (
    <div className="store-master-con">
      <Grid container md={12} sx={{ mb: 2 }} spacing={1}>
        <Grid item md={1.5}>
          <Autocomplete
            getOptionLabel={(option) => {
              return option ? option.toString() : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setStoreNo("");
                  setStoreOptions([]);
                }}
              />
            }
            options={storeOptions}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Store No" />
            )}
            inputValue={storeNo + "" || ""}
            onInputChange={onInputChange}
            onChange={(e, val) => {
              if (val) {
                setStoreNo(val?.storeNo);
              } else {
                setStoreNo("");
              }
            }}
          />
        </Grid>
        <Grid item md={1.5}>
          <Autocomplete
            getOptionLabel={(option) => {
              return option ? option : "";
            }}
            clearIcon={
              <ClearIcon
                fontSize="small"
                onClick={() => {
                  setAm("");
                  setAmOptions([]);
                }}
              />
            }
            options={amOptions}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Area Manager" />
            )}
            // inputValue={am + "" || ""}
            onInputChange={areaManagerInputChange}
            onChange={(e, val) => {
              if (val) {
                setAm(val);
              } else {
                setAm("");
              }
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            options={REGIONS_LIST}
            placeholder="Select Region"
            onChange={(e) => {
              setFilters((prevVal) => ({ ...prevVal, region: e.value }));
            }}
            styles={
              {
                // menu: () => ({
                //   zIndex: 2,
                // }),
              }
            }
          />
        </Grid>
        <Grid item md={2}>
          <Select
            options={STORE_TYPE}
            placeholder="Select Store Type"
            onChange={(e) => {
              setFilters((prevVal) => ({ ...prevVal, storeType: e.value }));
            }}
          />
        </Grid>

        <Grid item md={0.5}>
          <SearchIcon onClick={searchHandler} />
        </Grid>
        <Grid item md={2}>
          <FileUpload
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            fileFormats={[
              ".csv",
              ".xls",
              ".xlsx",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
            ]}
            url={`${INTRANETHOSTNAME}${REST_URLS.UPLOAD_STORES}`}
            callBack={() => loadData({ ...filters, page: 1 })}
          />
        </Grid>
        <Grid item md={0.5}>
          <DownloadBtn onClick={downloadStoresHandler} />
        </Grid>
        <Grid item md={2}>
          <Button
            variant="contained"
            onClick={() => {
              actionIconAndButtonHandler("create new store");
            }}
          >
            Create New Store
          </Button>
        </Grid>
      </Grid>

      <MuiTable
        columnsList={getHeaderConfig()}
        dataList={tableData.results || []}
        filters={filters}
        pageCount={tableData?.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...ftrsAftrAply,
              page,
            },
            true
          );
        }}
        maxWords={8}
        showAllFunc={setShowLargeText}
        onClick={actionIconAndButtonHandler}
      />
      {showLargeText.isOpen && (
        <CustomModal
          onClose={() =>
            setShowLargeText({
              isOpen: false,
              text: "",
            })
          }
        >
          {showLargeText.text}
        </CustomModal>
      )}

      <CreateUpdateStoreModal
        data={modalDetails?.data || null}
        showForm={modalDetails?.showModal}
        setShowForm={setModalDetails}
        onSuccess={() => {
          let params = { ...filters };
          if (am) {
            const areaManagerName = am.split("-")[0];
            params["am"] = areaManagerName;
          }
          if (storeNo) {
            params["storeNumber"] = storeNo;
          }
          loadData(params);
        }}
      />
    </div>
  );
};

export default StoreMaster;
