import React, { useState, useEffect } from "react";
import "../index.scss";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ROLES } from "../config";
import {
  getAllDepartmentNames,
  getUserRoleList,
  nonCreatedVendorList,
} from "../apis";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { INTRANETHOSTNAME } from "../../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../../packages/common-utilities/utils/end-points/intranet";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import AutocompleteComponent from "../../../packages/common-utilities/core/styled-autocomplete";
import {
  clearProperty,
  setProperty,
} from "../../../packages/common-utilities/utils/misc";

import { clearDepartment, setEditData } from "../utils";
import AutoCompleteMultiSelect from "../../../packages/common-utilities/core/autocomplete-multiselect";

import { formSubmitHandler } from "../apis/form-handler";
import FinanceAppConfig from "./sub-components/finance-config";
import SelectApps from "./sub-components/select-apps";

const CreateUser = ({ closeModal, mainTitle, userData, onSuccess }) => {
  const editUser = !!userData;

  const [formData, setFormData] = useState({
    email: "",
    role: "",
    password: "",
    limits: {},
    additionalRoles: [],
  });
  const [resetPasswordOnFirstLogin, setResetPasswordOnFirstLogin] =
    useState(false);
  const [appList, setAppList] = useState([]);
  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState({});
  const [userRolesList, setUserRolesList] = useState([]);
  const [config, setConfig] = useState({
    departmentList: [],
    vendorList: [],
    financeRoleList: [],
    financeAppId: null,
    showFinanceConfig: false,
  });
  const [isEmployee, setIsEmployee] = useState(true);
  const [departmentRoleList, setDepartmentRoleList] = useState([]);
  const handleChange = (e) => {
    setFormData((prevVal) => ({ ...prevVal, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    setEditData(
      userData,
      setDepartmentRoleList,
      setFormData,
      setIsEmployee,
      setSelectedApps,
      setSelectedRoles,
      config,
      setConfig
    );
  }, [userData, config.financeRoleList, config.financeAppId]);

  const listAppAndRoles = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_ROLES_AND_APPS}`
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setAppList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userRoleHandler = (e, val, type) => {
    let key = type || "additionalRoles";
    setFormData((prevVal) => ({ ...prevVal, [key]: val }));
  };

  useEffect(() => {
    getUserRoleList(setUserRolesList, setConfig);
  }, []);

  useEffect(() => {
    listAppAndRoles();
    getAllDepartmentNames((list) =>
      setProperty("departmentList", list, setConfig)
    );
  }, []);

  useEffect(() => {
    const filter = {};
    if (userData?._id) {
      filter._id = userData?._id;
    }
    nonCreatedVendorList((list) => {
      setProperty("vendorList", list, setConfig);
    }, filter);
  }, [userData?._id]);

  return (
    <div className="create-user-con">
      <div className="cancel-div">
        <h1>{mainTitle}</h1>
        <CancelIcon
          color="error"
          onClick={() => closeModal(false)}
          sx={{ cursor: "pointer" }}
          fontSize="large"
        />
      </div>
      <div>
        <Grid
          container
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            [`@media (max-width: 768px)`]: {
              gridGap: "5px",
            },
          }}
        >
          <Grid item md={5.5}>
            <TextField
              label="Email"
              size="small"
              type="email"
              className="new-textfield"
              fullWidth
              value={formData.email}
              name="email"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={5.5} sx={{ display: "flex", gridGap: 20 }}>
            <Grid
              item
              md={
                formData.role === "user"
                  ? 3
                  : formData.role === "vendor"
                  ? 6
                  : 12
              }
            >
              <AutocompleteComponent
                label="Select Role"
                onClick={() => {
                  clearProperty("role", setFormData);
                }}
                onChange={(e, val) => {
                  setFormData({ ...formData, role: val?.value });
                }}
                value={ROLES.find((e) => e.value === formData?.role) || null}
                options={ROLES}
                disableClear={true}
              />
            </Grid>

            {formData.role === "user" && (
              <>
                <Grid item md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isEmployee}
                          onChange={(event) => {
                            setIsEmployee(event.target.checked);
                          }}
                        />
                      }
                      label="Is Employee?"
                    />
                  </FormGroup>
                </Grid>
                {isEmployee && (
                  <Grid item md={5}>
                    <AutoCompleteMultiSelect
                      options={userRolesList}
                      onChange={userRoleHandler}
                      value={formData?.additionalRoles || []}
                      placeholder="Select User Role"
                      useDefaultVal={true}
                      isValValArr={true}
                    />
                  </Grid>
                )}
              </>
            )}

            {formData.role === "vendor" && (
              <>
                <Grid item md={6}>
                  <AutoCompleteMultiSelect
                    options={config.vendorList}
                    onChange={(_, val) => {
                      setProperty("vendor", val, setFormData);
                    }}
                    value={formData.vendor || []}
                    isValValArr={true}
                    placeholder="Select Vendors"
                    useDefaultVal={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            alignItems: "flex-end",
            [`@media (max-width: 768px)`]: {
              gridGap: "10px",
            },
          }}
        >
          <Grid
            container
            md={5.5}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item md={editUser ? 8 : 12}>
              <div className="userid-container">
                <TextField
                  label="Name"
                  type="text"
                  name="name"
                  fullWidth
                  className="new-textfield"
                  size="small"
                  variant="outlined"
                  value={formData.name}
                  onChange={handleChange}
                />
                {formData?.role === "franchise" && (
                  <span className="userId-warning">
                    Disclaimer:Changing Name will change User Id
                  </span>
                )}
              </div>
            </Grid>

            {editUser && (
              <Grid item md={3}>
                <TextField
                  label="User Id"
                  disabled={true}
                  type="text"
                  fullWidth
                  className="new-textfield"
                  size="small"
                  variant="outlined"
                  value={userData?.userId || ""}
                />
              </Grid>
            )}
          </Grid>
          <Grid item md={5.5} sx={{ display: "flex", gridGap: 20 }}>
            <Grid item md={editUser && formData.password ? 8 : 12}>
              <TextField
                label="Password"
                type="password"
                name="password"
                fullWidth
                size="small"
                variant="outlined"
                className="new-textfield"
                value={formData.password}
                onChange={handleChange}
              />
            </Grid>
            {editUser && formData?.password && (
              <Grid item md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resetPasswordOnFirstLogin}
                        // defaultChecked
                        onChange={(event) => {
                          setResetPasswordOnFirstLogin(event.target.checked);
                        }}
                      />
                    }
                    label="Reset On Login"
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </Grid>

        {formData?.role !== "vendor" && (
          <Grid
            container
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              alignItems: "flex-end",
              [`@media (max-width: 768px)`]: {
                gridGap: "10px",
              },
            }}
          >
            <Grid item md={5.5}>
              <AutoCompleteMultiSelect
                options={config.departmentList}
                onChange={(e, val) => {
                  setProperty("department", val, setFormData);
                }}
                isValValArr={true}
                useDefaultOpts={true}
                value={formData?.department || []}
                placeholder="Department"
              />
              {/* <AutocompleteComponent
                onClick={() => {
                  clearDepartment(setFormData, setDepartmentRoleList);
                }}
                disableClear={true}
                onChange={(e, val) => {
                  if (val) {
                    // getAllDepartmentRoles(
                    //   { department: val?.value },
                    //   setDepartmentRoleList
                    // );
                    // clearProperty('departmentRole', setFormData);
                    setProperty('department', val?.value, setFormData);
                  } else {
                    clearDepartment(setFormData, setDepartmentRoleList);
                  }
                }}
                options={config.departmentList}
                label="Department"
                value={
                  config.departmentList.find(
                    (item) => item.value === formData?.department
                  ) || null
                }
              /> */}
            </Grid>

            {/* <Grid item md={5.5}>
              <AutocompleteComponent
                onClick={() => {
                  clearProperty('departmentRole', setFormData);
                }}
                onChange={(e, val) => {
                  if (val) {
                    setProperty('departmentRole', val?.value, setFormData);
                  } else {
                    clearProperty('departmentRole', setFormData);
                  }
                }}
                disableClear={true}
                options={departmentRoleList}
                label="Select Department Role"
                value={departmentRoleList.find(
                  (item) => item.value === formData?.departmentRole
                )}
              />
            </Grid> */}
          </Grid>
        )}

        <SelectApps
          selectedRoles={selectedRoles}
          appList={appList}
          selectedApps={selectedApps}
          setSelectedRoles={setSelectedRoles}
          setSelectedApps={setSelectedApps}
          config={config}
          setConfig={setConfig}
        />

        <FinanceAppConfig
          config={config}
          userRoleHandler={userRoleHandler}
          formData={formData}
          setFormData={setFormData}
        />
        <Stack spacing={2} sx={{ my: 2 }}></Stack>

        <Stack direction="row" spacing={2} sx={{ my: 2 }}>
          <div className="btn-container">
            <div>
              <Button
                className="user-modal-button"
                variant="contained"
                fullWidth
                onClick={() => {
                  formSubmitHandler(
                    selectedApps,
                    selectedRoles,
                    formData,
                    editUser,
                    isEmployee,
                    resetPasswordOnFirstLogin,
                    closeModal,
                    onSuccess
                  );
                }}
                startIcon={<SaveIcon />}
                disabled={
                  (formData.role === "vendor" && !formData.vendor) ||
                  !formData.email ||
                  !formData.role ||
                  (editUser ? false : !formData.password) ||
                  !formData.name
                    ? true
                    : false
                }
              >
                Save
              </Button>
            </div>

            <div>
              <Button
                className="user-modal-button"
                variant="outlined"
                fullWidth
                color="error"
                onClick={() => closeModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default CreateUser;
