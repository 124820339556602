import React, { useEffect, useState } from "react";
import CustomModal from "../../../common-utilities/core/modal";
import { Button, Box, TextField, Stack } from "@mui/material";
import SiTable from "../../../common-utilities/core/table";
import FormControl from "@mui/material/FormControl";
import { competitorInformationConfig } from "./config";
import MenuItem from "@mui/material/MenuItem";
import "./index.scss";
import { toast } from "react-toastify";
import { getBrandsSelectList } from "./utils";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { brandKeysToLabelsForCompetitorInfo } from "../../utils/labels";
import { FormatSelect } from "./common-components/format-select";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import SaveIcon from "@mui/icons-material/Save";
// import { regionsList } from "../../utils/labels";
// import { getLabelsAndValuesForSelect } from "../../utils";
const CompetitorInformation = ({ data, setCompetitorInforation }) => {
  const [tableData, setTableData] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [filters, setFilters] = useState({});
  // const [allRegionsList, setAllRegionsList] = useState([]);

  const setFiltersOnChange = (e) => {
    const { name, value } = e.target;
    const haveToCreateNewBrand = name === "selectedBrand";

    if (haveToCreateNewBrand) {
      const isBrandEditingDisabled = value !== "others";
      editDeleteAdditionModalHandler(
        {
          brand:
            value !== "others" ? brandKeysToLabelsForCompetitorInfo[value] : "",
          dataIndex: data?.competitorInformation?.[value]
            ? data?.competitorInformation?.[value].length
            : 0,
          isBrandEditingDisabled,
          brandKey: value,
        },
        "create"
      );
    }
    setFilters((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };
  const [page, setPage] = useState(1);

  const [modalDetails, setModalDetails] = useState({
    modalTitle: "",
    data: null,
    type: "",
  });

  useEffect(() => {
    const brandSelectList = getBrandsSelectList();
    setBrandList(brandSelectList);
    // const allRegionSelectList = getLabelsAndValuesForSelect(
    //   regionsList,
    //   regionsList
    // );
    // setAllRegionsList(allRegionSelectList);
  }, []);

  const setModalDataOnChange = (e) => {
    const { name, value } = e.target;

    setModalDetails((prevDetails) => ({
      ...prevDetails,
      data: { ...prevDetails.data, [name]: value },
    }));
  };

  const editDeleteAdditionModalHandler = (data, type) => {
    switch (type) {
      case "edit":
        setModalDetails((prevDetails) => ({
          ...prevDetails,
          modalTitle: "Edit details",
          data,
          type: "edit",
        }));
        break;
      case "create":
        setModalDetails((prevDetails) => ({
          ...prevDetails,
          modalTitle: "Create",
          data,
          type: "create",
        }));
        break;
      case "delete":
        setModalDetails((prevDetails) => ({
          ...prevDetails,
          modalTitle: "Delete",
          data,
          type: "delete",
        }));
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedBrand: null,
    }));
    setModalDetails((prevDetails) => ({
      ...prevDetails,
      modalTitle: "",
      data: null,
      type: "",
    }));
  };

  useEffect(() => {
    const { competitorInformation } = data;
    let allData = [];
    if (competitorInformation?.dominos) {
      competitorInformation?.dominos?.forEach((item, index) => {
        allData.push({
          ...item,
          brandKey: "dominos",
          isBrandEditingDisabled: true,
          dataIndex: index,
        });
      });
    }
    if (competitorInformation?.burgerKing) {
      competitorInformation?.burgerKing?.forEach((item, index) => {
        allData.push({
          ...item,
          brandKey: "burgerKing",
          isBrandEditingDisabled: true,
          dataIndex: index,
        });
      });
    }
    if (competitorInformation?.kfc) {
      competitorInformation?.kfc?.forEach((item, index) => {
        allData.push({
          ...item,
          brandKey: "kfc",
          isBrandEditingDisabled: true,
          dataIndex: index,
        });
      });
    }
    if (competitorInformation?.pizzaHut) {
      competitorInformation?.pizzaHut?.forEach((item, index) => {
        allData.push({
          ...item,
          brandKey: "pizzaHut",
          isBrandEditingDisabled: true,
          dataIndex: index,
        });
      });
    }
    if (competitorInformation?.mcd) {
      competitorInformation?.mcd?.forEach((item, index) => {
        allData.push({
          ...item,
          brandKey: "mcd",
          isBrandEditingDisabled: true,
          dataIndex: index,
        });
      });
    }
    if (competitorInformation?.others) {
      competitorInformation.others.forEach((item, index) => {
        allData.push({ ...item, dataIndex: index, brandKey: "others" });
      });
    }
    setTableData(allData);
  }, [data]);

  const saveAddorDeleteModalData = () => {
    const {} = modalDetails;
    const {
      brandKey,
      brand = "",
      ads = "",
      del = "",
      distance = "",
      adt = "",
      apc,
      dataIndex,
      format,
      // region,
      location,
    } = modalDetails.data;

    const allDetails = {};
    if (adt) {
      allDetails.adt = adt;
    }
    if (format) {
      allDetails.format = format;
    }
    if (apc) {
      allDetails.apc = apc;
    }
    if (del) {
      allDetails.del = del;
    }
    if (brand) {
      allDetails.brand = brand;
    }
    if (ads) {
      allDetails.ads = ads;
    }
    if (distance) {
      allDetails.distance = distance;
    }
    // if (region) {
    //   allDetails.region = region;
    // }

    if (location) {
      allDetails.location = location;
    }

    switch (modalDetails.type) {
      case "edit":
        if (brand) {
          setCompetitorInforation((prevDetails) => {
            const prevBrandDetails =
              prevDetails?.competitorInformation?.[brandKey] || [];
            prevBrandDetails[dataIndex] = allDetails;
            return {
              ...prevDetails,
              competitorInformation: {
                ...prevDetails.competitorInformation,
                [brandKey]: [...prevBrandDetails],
              },
            };
          });
        } else {
          toast.error("Brand is required", { autoClose: 2000 });
        }
        break;
      case "create":
        if (brand) {
          setCompetitorInforation((prevDetails) => {
            const prevBrandDetails =
              prevDetails?.competitorInformation?.[brandKey] || [];

            return {
              ...prevDetails,
              competitorInformation: {
                ...prevDetails.competitorInformation,
                [brandKey]: [...prevBrandDetails, allDetails],
              },
            };
          });
        } else {
          toast.error("Brand Name is required", { autoClose: 2000 });
        }

        break;
      case "delete":
        setCompetitorInforation((prevDetails) => {
          let allNewData = [...prevDetails.competitorInformation[brandKey]];
          allNewData = allNewData.filter((_, index) => {
            return index !== dataIndex;
          });
          return {
            ...prevDetails,
            competitorInformation: {
              ...prevDetails.competitorInformation,
              [brandKey]: allNewData,
            },
          };
        });
        break;
      default:
        break;
    }

    closeModal();
  };
  return (
    <div className="competitor-info-section">
      <div className="add-others-button-container">
        <div className="add-others-button-select">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Select Brand</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                filters?.selectedBrand
                  ? brandList.find((item) => {
                      return item.value === filters?.selectedBrand;
                    }).value
                  : ""
              }
              label="Brand"
              name="selectedBrand"
              onChange={setFiltersOnChange}
            >
              {brandList.map((format) => {
                return <MenuItem value={format.value}>{format.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <MuiTable
        columnsList={competitorInformationConfig()}
        dataList={tableData.slice((page - 1) * 10, (page - 1) * 10 + 10)}
        filters={page}
        pageCount={Math.ceil(tableData.length / 10)}
        onClick={editDeleteAdditionModalHandler}
        customTableClass="competitor-info-table"
        customTableConClass="competitor-table-class"
        onChange={(page) => {
          setPage(page);
        }}
      ></MuiTable>
      {/* <SiTable
        header={competitorInformationConfig()}
        filters={page}
        data={tableData.slice((page - 1) * 10, (page - 1) * 10 + 10)}
        pageCount={Math.ceil(tableData.length / 10)}
        onClick={editDeleteAdditionModalHandler}
        onChange={(_, page) => {
          setPage(page);
        }}
      /> */}
      {modalDetails.data && (
        <CustomModal
          title={modalDetails.modalTitle}
          classesToPreventClosing={["poper-select", "MuiBackdrop-root"]}
          onClose={closeModal}
        >
          {modalDetails.type === "delete" && (
            <>
              <h3>Are you sure ?</h3>
            </>
          )}
          {modalDetails.type !== "delete" && (
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                className="new-textfield"
                name="brand"
                disabled={!!modalDetails?.data?.isBrandEditingDisabled}
                sx={{ my: 1 }}
                label="Brand"
                multiline
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.brand || ""}
                size="small"
              />

              <FormatSelect
                value={modalDetails?.data?.format || ""}
                name="format"
                handleChange={setModalDataOnChange}
                removeValueFromMainData={() => {
                  setModalDetails((prevDetails) => {
                    delete prevDetails?.data?.format;
                    return prevDetails;
                  });
                }}
              />
              <TextField
                fullWidth
                name="location"
                sx={{ my: 1 }}
                className="new-textfield"
                label="Store Name/Location"
                multiline
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.location || ""}
                size="small"
              />

              <TextField
                fullWidth
                name="distance"
                sx={{ my: 1 }}
                type="number"
                className="new-textfield"
                label="Distance(in kms)"
                multiline
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.distance || ""}
                size="small"
              />
              <TextField
                fullWidth
                name="ads"
                sx={{ my: 1 }}
                className="new-textfield"
                label="ADS"
                multiline
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.ads || ""}
                size="small"
              />

              <TextField
                fullWidth
                name="del"
                sx={{ my: 1 }}
                label="Del%"
                type="number"
                className="new-textfield"
                onChange={setModalDataOnChange}
                value={modalDetails?.data?.del || ""}
                size="small"
              />
            </Box>
          )}

          <Stack direction="row" spacing={2} sx={{ my: 2 }}>
            <div className="end-container">
              {modalDetails.type === "delete" ? (
                <div>
                  <Button
                    onClick={saveAddorDeleteModalData}
                    variant="contained"
                    color="error"
                  >
                    Delete
                  </Button>
                </div>
              ) : (
                <div>
                  <CommonBtn
                    size="small"
                    variant="contained"
                    className="button-color"
                    fullWidth
                    onClick={saveAddorDeleteModalData}
                    text="Save"
                    startIcon={<SaveIcon />}
                  />
                </div>
              )}

              <div>
                <CommonBtn
                  size="small"
                  variant="outlined"
                  fullWidth
                  color="error"
                  onClick={closeModal}
                  text="Cancel"
                />
              </div>
            </div>
          </Stack>
        </CustomModal>
      )}
    </div>
  );
};

export default CompetitorInformation;
