import React, { useState } from 'react';
import { Box, Button, Tab, Tabs } from '@mui/material';
import BudgetList from './budget-list';
import CustomModal from '../../../common-utilities/core/modal';
import UploadBudget from './upload-budget';
import './index.scss';
import { uploadTemplates } from '../master-data/constants';
import { useSearchParams } from 'react-router-dom';
import { isCreateAllowed } from '../../utils/common-utils';
import DownloadTemplateButton from '../master-data/common-components/upload-template';
import BudgetApprovalList from './budget-approval-list';
import UploadBulkTemplate from './upload-bulk-template';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Budget = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openTabIndex = searchParams.get('subIndex');
  const mainTabIndex = searchParams.get('index');
  const [value, setValue] = useState(+openTabIndex || 0);

  const [budgetModal, setBudgetModal] = useState(false);
  const [bulkUploadTemplate, setBulkUploadTemplate] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const handleChange = (event, newValue) => {
    setSearchParams({
      index: mainTabIndex || 0,
      subIndex: newValue,
    });
    setValue(newValue);
  };

 
  return (
    <div className="budget-list">
      <div className="tabs-section">
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          <Tab label="Budget" />
          <Tab label="Hold Budgets" />
          <Tab label="Pending Actions" />
        </Tabs>
        {isCreateAllowed('budget') && (
          <>
            <div className="budget-ui-btns">
            <Button variant="outlined" onClick={() => setBulkUploadTemplate(true)}>
                Bulk Upload
              </Button>
              <Button variant="outlined" onClick={() => setBudgetModal(true)}>
                Create Budget
              </Button>
              <DownloadTemplateButton type={uploadTemplates.budgetLineItems} />
            </div>
          </>
        )}
      </div>
      <TabPanel value={value} index={0}>
        <BudgetList status="approved" refresh={refresh} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BudgetList status="inactive" refresh={refresh} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BudgetApprovalList searchPlaceHolder="Search by Budget Id" status="lineitem_approval" />
      </TabPanel>

      {budgetModal && (
        <CustomModal
          title="Create budget"
          disabledOutsideClick={true}
          onClose={() => {
            setBudgetModal(false);
          }}
        >
          <UploadBudget
            onSuccess={(response) => {
              if (response.success) {
                setBudgetModal(false);
                setRefresh(Math.random());
              }
            }}
          />
        </CustomModal>
      )}

      {bulkUploadTemplate && (
        <CustomModal
          title="Bulk Upload"
          disabledOutsideClick={true}
          onClose={() => {
            setBulkUploadTemplate(false);
          }}
        >
          <UploadBulkTemplate
            onSuccess={(response) => {
              if (response.success) {
                setBulkUploadTemplate(false);
                setRefresh(Math.random());
              }
            }}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default Budget;
