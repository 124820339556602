import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./index.scss";
import {
  ALPHA_NUMERIC,
  APPROVED_BANK_LIST,
  GST_STATUS,
  formatPayload,
  isFormValid,
  formateSubmittedDetails,
  agreementAvailableOptions,
} from "./helper";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { INVOICE_TRACKING_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { VENDOR_ONBOARD_REST_URLS } from "../../../common-utilities/utils/end-points/vendor-onboard";
import { toast } from "react-toastify";
import {
  CancelOutlined,
  CloseOutlined,
  RemoveRedEye,
} from "@mui/icons-material";
import SiFileUpload from "../../../invoice-tracking/core/file-uploader";
import CustomRadioGroup from "../../../common-utilities/core/radio-group";
import {
  INDIA_STATE_LIST,
  TYPE_OF_MSME,
  TYPE_OF_VENDOR,
  VENDOR_TENURE,
} from "../../../common-utilities/utils/constants";
import { getFinanceApprovalData } from "../vendor-request-details/helper";
import { VENDOR_FORM_STATUS } from "../../utils/constants";

const VendorOnboardForm = () => {
  const [searchParams] = useSearchParams();
  const formToken = searchParams.get("token");
  const [vendorForm, setVendorForm] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [stateInputValue, setStateInputValue] = useState("");

  useEffect(() => {
    if (formToken) {
      loadData();
    }
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.GET_VENDOR_FORM_BY_TOKEN}`,
      null,
      {
        token: formToken,
      }
    )
      .then((response) => {
        const { vendorForm, submittedDetails = {} } = response;
        if (vendorForm.id) {
          setVendorForm(vendorForm);
          if (submittedDetails) {
            const { address, bankDetails } = submittedDetails;
            const { state } = address;
            const { bankName } = bankDetails;
            setStateInputValue(state);
            setInputValue(bankName);
            setFormDetails(formateSubmittedDetails(submittedDetails || {}));
          } else {
            setFormDetails({
              email: vendorForm.email,
              department: vendorForm.department,
            });
          }
        } else {
          toast.error(response.message || "Failed to send request");
        }
      })
      .catch((err) => {});
  };

  const onInputChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onPanCardChange = (event) => {
    setFormDetails({
      ...formDetails,
      [event.target.name]: (event.target.value || "").toUpperCase(),
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onMobileInputChange = (event) => {
    if (event.target.value.length > 10) {
      return;
    }
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onGSTChange = (event) => {
    if (event.target.value.length < 12) {
      return;
    }
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onInputChangeAlphaNumeric = (event) => {
    if (!event.target.value.match(ALPHA_NUMERIC)) {
      return;
    }

    setFormDetails({
      ...formDetails,

      [event.target.name]: event.target.value,
    });
    delete formErrors[event.target.name];
    setFormErrors({
      ...formErrors,
    });
  };

  const onSubmit = () => {
    let errors = isFormValid(formDetails);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    let payload = formatPayload(formDetails);
    invokeApi(
      HTTP_METHODS.POST,
      `${INVOICE_TRACKING_HOSTNAME}${VENDOR_ONBOARD_REST_URLS.VENDOR_FORM_SUBMIT_BY_TOKEN}`,
      payload,
      {
        token: formToken,
      }
    )
      .then((response) => {
        if (response.id) {
          setVendorForm(response);
        } else {
          toast.error(response.message || "Failed to send request");
        }
      })
      .catch((err) => {});
  };

  const { status, approvers = [], comments = [] } = vendorForm;
  const statusObject = getFinanceApprovalData(approvers, status);

  if ([VENDOR_FORM_STATUS.CANCELED].includes(status)) {
    return (
      <div className="vendor-onboard-form-container">
        <div className="submitted-message">
          <CancelOutlined style={{ fontSize: 160 }} color="error" />
          <div>Your form got cancelled</div>
          <span>Please contact subway team to resend the form</span>
        </div>
      </div>
    );
  }

  if ([VENDOR_FORM_STATUS.REJECTED].includes(status)) {
    return (
      <div className="vendor-onboard-form-container">
        <div className="submitted-message">
          <CancelOutlined style={{ fontSize: 160 }} color="error" />
          <span>
            Current status is <strong>{statusObject.status}</strong>
          </span>
          {comments.length > 0 && (
            <span className="comment-list">
              Comments :{" "}
              {(comments || []).map((c) => (
                <div className="comment">
                  <span>
                    {c.createOn && new Date(c.createOn).toLocaleDateString()}
                  </span>
                  <span>{c.comment}</span>
                </div>
              ))}
            </span>
          )}
        </div>
      </div>
    );
  }

  if (
    ["SUBMITTED", "APPROVED", "IN_PROGRESS", "RESUBMITTED"].includes(status)
  ) {
    return (
      <div className="vendor-onboard-form-container">
        <div className="submitted-message">
          <CheckCircleOutlineIcon style={{ fontSize: 160 }} color="primary" />
          <div>Thank you for submitting</div>
          <span>
            Current status is <strong>{statusObject.status}</strong>
          </span>
          {/* {comments.length > 0 && (
            <span className="comment-list">
              Comments :{" "}
              {(comments || []).map((c) => (
                <div className="comment">
                  <span>
                    {c.createOn && new Date(c.createOn).toLocaleDateString()}
                  </span>
                  <span>{c.comment}</span>
                </div>
              ))}
            </span>
          )} */}
          <span>Please contact support team to get more details </span>
        </div>
      </div>
    );
  }

  if ([VENDOR_FORM_STATUS.OPEN, VENDOR_FORM_STATUS.REOPENED].includes(status)) {
    return (
      <div className="vendor-onboard-form-container">
        <div className="header-section">
          <div>
            <span className="title">Vendor Request Form </span>
          </div>
        </div>
        <div className="vendor-onboard-form-details">
          <Card>
            <CardContent>
              <div className="form-row">
                <TextField
                  label="Vendor Name *"
                  name="nameOfLegalEntity"
                  size="small"
                  fullWidth
                  value={formDetails.nameOfLegalEntity || ""}
                  onChange={onInputChange}
                  error={formErrors.nameOfLegalEntity}
                  helperText={formErrors.nameOfLegalEntity ? "Required" : ""}
                />
                <TextField
                  label="Address Line 1 *"
                  name="line"
                  size="small"
                  fullWidth
                  value={formDetails.line || ""}
                  onChange={onInputChange}
                  error={formErrors.line}
                  helperText={formErrors.line ? "Required" : ""}
                />
                <TextField
                  label="Address Line 2"
                  name="street"
                  size="small"
                  fullWidth
                  value={formDetails.street || ""}
                  onChange={onInputChange}
                />
                <TextField
                  label="PIN No *"
                  name="pincode"
                  size="small"
                  type="number"
                  fullWidth
                  value={formDetails.pincode || ""}
                  onChange={onInputChange}
                  error={formErrors.pincode}
                  helperText={
                    formErrors.pincode
                      ? "Required,Not allowed special characters(!,@,#,$,%...)"
                      : ""
                  }
                />
                <TextField
                  label="City *"
                  name="city"
                  size="small"
                  fullWidth
                  value={formDetails.city || ""}
                  onChange={onInputChangeAlphaNumeric}
                  error={formErrors.city}
                  helperText={
                    formErrors.city
                      ? "Required,Not allowed special characters(!,@,#,$,%...)"
                      : ""
                  }
                />

                <Autocomplete
                  options={INDIA_STATE_LIST}
                  className="form-autocomplete"
                  size="small"
                  value={formDetails.state || ""}
                  inputValue={stateInputValue}
                  onInputChange={(event, newInputValue) => {
                    if (event) {
                      setStateInputValue(newInputValue);
                    }
                  }}
                  onChange={(event, values) => {
                    onInputChange({
                      target: {
                        value: values || "",
                        name: "state",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="State *"
                      error={formErrors.city}
                      helperText={formErrors.city ? "Required" : ""}
                    />
                  )}
                />
                <TextField
                  label="Country *"
                  name="country"
                  size="small"
                  fullWidth
                  value={formDetails.country || ""}
                  onChange={onInputChangeAlphaNumeric}
                  error={formErrors.country}
                  helperText={
                    formErrors.country
                      ? "Required,Not allowed special characters(!,@,#,$,%...)"
                      : ""
                  }
                />

                <TextField
                  label="PAN No *"
                  name="pan"
                  size="small"
                  fullWidth
                  value={formDetails.pan || ""}
                  onChange={onPanCardChange}
                  error={formErrors.pan}
                  helperText={
                    formErrors.pan ? "Format should be ABCDE1234A" : ""
                  }
                />
                <TextField
                  label="Contact Person *"
                  name="focalPointOfContact"
                  size="small"
                  fullWidth
                  value={formDetails.focalPointOfContact || ""}
                  onChange={onInputChange}
                  error={formErrors.focalPointOfContact}
                  helperText={formErrors.focalPointOfContact ? "Required" : ""}
                />
                <TextField
                  label="Mobile Number *"
                  name="mobile"
                  size="small"
                  type="number"
                  fullWidth
                  value={formDetails.mobile || ""}
                  onChange={onMobileInputChange}
                  error={formErrors.mobile}
                  helperText={
                    formErrors.mobile
                      ? "Mobile  should be number and equals to 10"
                      : ""
                  }
                />
                <TextField
                  label="Alternative Number"
                  name="alternativeMobile"
                  size="small"
                  type="number"
                  fullWidth
                  value={formDetails.alternativeMobile || ""}
                  onChange={onInputChange}
                  error={formErrors.alternativeMobile}
                  helperText={
                    formErrors.alternativeMobile
                      ? "Mobile  should be number and equals to 10"
                      : ""
                  }
                />
                <TextField
                  label="Email *"
                  name="email"
                  size="small"
                  fullWidth
                  value={formDetails.email || ""}
                  onChange={onInputChange}
                  error={formErrors.email}
                  helperText="email should be example@example.com"
                />
              </div>
              <div className="form-row">
                <Autocomplete
                  options={TYPE_OF_VENDOR}
                  className="form-autocomplete"
                  size="small"
                  value={formDetails.typeOfVendor || ""}
                  inputValue={formDetails.typeOfVendor || ""}
                  onChange={(event, values) => {
                    onInputChange({
                      target: {
                        value: values || "",
                        name: "typeOfVendor",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="Type Of Vendor *"
                      error={formErrors.typeOfVendor}
                      helperText={formErrors.typeOfVendor ? "Required" : ""}
                    />
                  )}
                />

                <Autocomplete
                  options={agreementAvailableOptions}
                  className="form-autocomplete"
                  size="small"
                  value={agreementAvailableOptions.find(
                    (item) => item.value === formDetails?.agreementAvailable
                  )}
                  onChange={(event, values) => {
                    onInputChange({
                      target: {
                        value: values || "",
                        name: "agreementAvailable",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="Agreement available"
                    />
                  )}
                />

                <TextField
                  label="Credit Period *"
                  name="creditPeriod"
                  size="small"
                  type="number"
                  fullWidth
                  value={formDetails.creditPeriod || ""}
                  onChange={onInputChange}
                  error={formErrors.creditPeriod}
                  helperText={formErrors.creditPeriod ? "Required" : ""}
                />
              </div>

              <div className="form-row">
                <Autocomplete
                  options={VENDOR_TENURE}
                  className="form-autocomplete"
                  size="small"
                  value={formDetails.vendorTenure || ""}
                  inputValue={formDetails.vendorTenure || ""}
                  onChange={(event, values) => {
                    onInputChange({
                      target: {
                        value: values || "",
                        name: "vendorTenure",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="Vendor Tenure *"
                      error={formErrors.vendorTenure}
                      helperText={formErrors.vendorTenure ? "Required" : ""}
                    />
                  )}
                />
              </div>

              <div className="form-row gst-status">
                <CustomRadioGroup
                  title="GST Status"
                  options={GST_STATUS}
                  value={formDetails.gstStatus || ""}
                  onChange={(event) => {
                    if (
                      formDetails.state &&
                      formDetails.state.code &&
                      formDetails.pan &&
                      formDetails.pan.length === 10
                    ) {
                      if (event.target.value === "Yes") {
                        setFormDetails({
                          ...formDetails,
                          gstStatus: event.target.value,
                          gst: `${
                            (formDetails.state && formDetails.state.code) || ""
                          }${formDetails.pan || ""}`,
                        });
                      } else {
                        setFormDetails({
                          ...formDetails,
                          gstStatus: event.target.value,
                          gst: "",
                        });
                      }
                    } else {
                      toast.error(
                        "Please state and enter valid PAN details to add GST"
                      );
                    }
                  }}
                />
                {formDetails.gstStatus === "Yes" && (
                  <TextField
                    label="GST NO"
                    name="gst"
                    size="small"
                    fullWidth
                    value={formDetails.gst || ""}
                    onChange={onGSTChange}
                    error={formErrors.gst}
                    helperText="Please enter last 3 digits"
                  />
                )}
                {formDetails.gstStatus === "Yes" && (
                  <div>
                    {formDetails.gstDoc ? (
                      <div>
                        <span>{formDetails.gstDoc.blobName}</span>
                        <IconButton
                          onClick={() => {
                            setFormDetails({
                              ...formDetails,
                              gstDoc: "",
                            });
                          }}
                        >
                          <CloseOutlined color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            window
                              .open(formDetails.gstDoc.url, "_blank")
                              .focus()
                          }
                        >
                          <RemoveRedEye color="primary" />
                        </IconButton>
                      </div>
                    ) : (
                      <SiFileUpload
                        id="gst-doc"
                        title="GST Document"
                        callBack={(value) => {
                          setFormDetails({
                            ...formDetails,
                            gstDoc: value[0],
                          });
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="form-row msme-status">
                <CustomRadioGroup
                  title="MSME available"
                  options={GST_STATUS}
                  value={formDetails.msme || ""}
                  onChange={(event) => {
                    setFormDetails({
                      ...formDetails,
                      msme: event.target.value,
                    });
                  }}
                />
                {formDetails.msme === "Yes" && (
                  <Autocomplete
                    options={TYPE_OF_MSME}
                    className="form-autocomplete"
                    size="small"
                    value={formDetails.msmeType || ""}
                    inputValue={formDetails.msmeType || ""}
                    onChange={(event, values) => {
                      onInputChange({
                        target: {
                          value: values || "",
                          name: "msmeType",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="Type Of MSME *"
                        error={formErrors.msmeType}
                        helperText={formErrors.msmeType ? "Required" : ""}
                      />
                    )}
                  />
                )}
                {formDetails.msme === "Yes" && (
                  <TextField
                    label="MSME NO"
                    name="msmeNumber"
                    size="small"
                    fullWidth
                    value={formDetails.msmeNumber || ""}
                    onChange={onPanCardChange}
                    error={formErrors.msmeNumber}
                    helperText={formErrors.msmeNumber ? "Required" : ""}
                  />
                )}
                {formDetails.msme === "Yes" && (
                  <div>
                    {formDetails.msmeDoc ? (
                      <div>
                        <span>{formDetails.msmeDoc.blobName}</span>
                        <IconButton
                          onClick={() => {
                            setFormDetails({
                              ...formDetails,
                              msmeDoc: "",
                            });
                          }}
                        >
                          <CloseOutlined color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            window
                              .open(formDetails.msmeDoc.url, "_blank")
                              .focus()
                          }
                        >
                          <RemoveRedEye color="primary" />
                        </IconButton>
                      </div>
                    ) : (
                      <SiFileUpload
                        id="gst-doc"
                        title="MSME Document"
                        clsName="relative"
                        inputclassName="absoluteinput"
                        callBack={(value) => {
                          setFormDetails({
                            ...formDetails,
                            msmeDoc: value[0],
                          });
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="sub-heading">Bank Details</div>
              <div className="form-row">
                <Autocomplete
                  options={APPROVED_BANK_LIST.map((b) => b.value)}
                  className="form-autocomplete"
                  size="small"
                  value={formDetails.bankName}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    if (event) {
                      setInputValue(newInputValue);
                    }
                  }}
                  onChange={(event, values) => {
                    onInputChange({
                      target: {
                        value: values || "",
                        name: "bankName",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="Bank Name *"
                      error={formErrors.bankName}
                      helperText={formErrors.bankName ? "Required" : ""}
                    />
                  )}
                />
                <TextField
                  label="Account Number *"
                  name="accountNumber"
                  size="small"
                  fullWidth
                  value={formDetails.accountNumber || ""}
                  onChange={onInputChangeAlphaNumeric}
                  error={formErrors.accountNumber}
                  helperText={
                    formErrors.accountNumber ? "Should be alpha numeric" : ""
                  }
                />
                <TextField
                  label="IFSC code *"
                  name="ifsc"
                  size="small"
                  fullWidth
                  value={formDetails.ifsc || ""}
                  onChange={(e) => {
                    if (e?.target?.value?.length > 11) {
                      return;
                    }
                    onInputChange(e);
                  }}
                  error={formErrors.ifsc}
                  helperText={
                    formErrors.ifsc
                      ? "Should be 11 digit and alpha numeric"
                      : ""
                  }
                />
              </div>
              <div className="form-row">
                <div>
                  {formDetails.cancelledCheque ? (
                    <div>
                      <span>{formDetails.cancelledCheque.blobName}</span>
                      <IconButton
                        onClick={() => {
                          setFormDetails({
                            ...formDetails,
                            cancelledCheque: "",
                          });
                        }}
                      >
                        <CloseOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          window
                            .open(formDetails.cancelledCheque.url, "_blank")
                            .focus()
                        }
                      >
                        <RemoveRedEye color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <SiFileUpload
                      title="Cancelled Cheque *"
                      id="Cancelled-cheque"
                      callBack={(value) => {
                        setFormDetails({
                          ...formDetails,
                          cancelledCheque: value[0],
                        });
                        delete formErrors.cancelledCheque;
                        setFormErrors({
                          ...formErrors,
                        });
                      }}
                    />
                  )}
                  <span style={{ color: "red" }}>
                    {formErrors.cancelledCheque ? "Cheque required" : ""}
                  </span>
                </div>
                <div>
                  {formDetails.panDoc ? (
                    <div>
                      <span>{formDetails.panDoc.blobName}</span>
                      <IconButton
                        onClick={() => {
                          setFormDetails({
                            ...formDetails,
                            panDoc: "",
                          });
                        }}
                      >
                        <CloseOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          window.open(formDetails.panDoc.url, "_blank").focus()
                        }
                      >
                        <RemoveRedEye color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <SiFileUpload
                      title="Pan Card *"
                      id="pan-card"
                      callBack={(value) => {
                        setFormDetails({
                          ...formDetails,
                          panDoc: value[0],
                        });
                        delete formErrors.panDoc;
                        setFormErrors({
                          ...formErrors,
                        });
                      }}
                    />
                  )}
                  <span style={{ color: "red" }}>
                    {formErrors.panDoc ? "Pan Card Required" : ""}
                  </span>
                </div>

                <div>
                  {formDetails.attachment ? (
                    <div>
                      <span>{formDetails.attachment.blobName}</span>
                      <IconButton
                        onClick={() => {
                          setFormDetails({
                            ...formDetails,
                            attachment: null,
                          });
                        }}
                      >
                        <CloseOutlined color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          window
                            .open(formDetails.attachment.url, "_blank")
                            .focus()
                        }
                      >
                        <RemoveRedEye color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <SiFileUpload
                      title="Attachment"
                      id="attachment"
                      callBack={(value) => {
                        setFormDetails({
                          ...formDetails,
                          attachment: value[0],
                        });
                        delete formErrors.attachment;
                        setFormErrors({
                          ...formErrors,
                        });
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="footer-buttons">
                <Button variant="contained" onClick={onSubmit}>
                  {status === "REOPEN" ? "Resubmit request" : "Submit Request"}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
};

export default VendorOnboardForm;
