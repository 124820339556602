import React, { useState } from "react";
import "./index.scss";
import Select from "react-select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { TextField } from "@mui/material";
import { TICKET_SEVERITY } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { invokeApi } from "../../utils/http-service";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import SiFileUpload from "../../core/file-uploader";
import CustomModal from "../../../common-utilities/core/modal";
import { toast } from "react-toastify";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import CommonBtn from "../../../common-utilities/core/common-button";
import SelectComponet from "../../../common-utilities/core/select-component";
import { useEffect } from "react";
import AutoCompleteMultiSelect from "../../../common-utilities/core/autocomplete-multiselect";
const FormDetails = ({ loadData, ticketDetails = {} }) => {
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const user = getOfflineData(tokenMainKeys.technet, "user");
  const [severity, setSeverity] = useState("");
  const [isExternalTicketClose, setIsExternalTicketClose] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [selectedTagsIds, setSelectedTagsIds] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (ticketDetails?.ticket?.severity) {
      setSeverity(ticketDetails?.ticket?.severity);
    }
  }, [ticketDetails?.ticket?.severity]);
  const updateTicketDetails = (payload) => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.UPDATETICKET}/${id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error("Update Failed");
        } else {
          toast.success("Ticket updated successfully");
          loadData();
        }
      })
      .catch((err) => console.log(err));
  };

  const addComments = () => {
    invokeApi(
      HTTP_METHODS.POST,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.COMMENT}`,
      {
        ticketId: id,
        comment,
      }
    )
      .then((response) => {
        if (response?.message) {
          toast.error("Update Failed");
        } else {
          setComment("");
          loadData();
        }
      })
      .catch((err) => console.log(err));
  };
  const getTagList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.GET_TAG_LIST}`
    )
      .then((res) => {
        if (!res.message) {
          const result = res?.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setTagList(result);
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (isExternalTicketClose) {
      getTagList();
    }
  }, [isExternalTicketClose]);
  return (
    <div className="form-details-container">
      {user && user.type !== "STORE_OWNER" && (
        <div className="severity">
          <div>Ticket Severity</div>
          <SelectComponet
            disabled={!ticketDetails?.updateRights}
            onChange={(e, val) => {
              updateTicketDetails({
                severity: e.target.value,
              });
            }}
            options={TICKET_SEVERITY}
            value={severity}
          />
          {/* <Select
            value={TICKET_SEVERITY.find((t) => {
              let finalTicketValue = null;
              if (
                ticketDetails &&
                ticketDetails.ticket &&
                ticketDetails.ticket.severity
              ) {
                finalTicketValue = ticketDetails.ticket.severity;
              }
              return t.value === finalTicketValue;
            })}
            classNamePrefix="si-select"
            options={TICKET_SEVERITY}
            onChange={(value) => {
             
            }}
          /> */}
          <div>
            <CommonBtn
              disabled={!ticketDetails?.updateRights}
              variant="contained"
              color="primary"
              onClick={() => updateTicketDetails({ holdEscalations: true })}
              size="small"
              text="Hold Escalation"
            />
          </div>
        </div>
      )}

      <div className="action-form">
        <div className="comments-section">
          <TextareaAutosize
            minRows={5}
            disabled={!ticketDetails?.updateRights}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="new-textfield"
            style={{
              width: "99%",
            }}
          />
          <div className="add-comment-bts">
            <CommonBtn
              variant="contained"
              color="primary"
              size="small"
              onClick={addComments}
              disabled={!comment}
              text="Add Comment"
            />
            {ticketDetails?.updateRights && (
              <SiFileUpload id={id} callBack={loadData} />
            )}
          </div>
        </div>

        {ticketDetails?.updateRights && (
          <div className="buttons-container">
            {((ticketDetails &&
              ticketDetails.ticket &&
              ticketDetails.ticket.status === "RESOLVED") ||
              (ticketDetails &&
                ticketDetails.ticket &&
                ticketDetails.ticket.status === "CLOSED")) && (
              <CommonBtn
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  updateTicketDetails({
                    status: "REOPENED",
                  })
                }
                text="Reopen ticket"
              />
            )}

            {user &&
              ticketDetails?.updateRights &&
              user.type !== "STORE_OWNER" && (
                <>
                  {((ticketDetails &&
                    ticketDetails.ticket &&
                    ticketDetails.ticket.status === "OPENED") ||
                    (ticketDetails &&
                      ticketDetails.ticket &&
                      ticketDetails.ticket.status === "REOPENED")) && (
                    <CommonBtn
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setOpenModal(true)}
                      text="Resolve Ticket"
                    />
                  )}
                  <CommonBtn
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={
                      // (ticketDetails
                      //   ? ticketDetails.ticket
                      //     ? ticketDetails.ticket.status === "RESOLVED"
                      //     : false
                      //   : false) ||
                      ticketDetails
                        ? ticketDetails.ticket
                          ? ticketDetails.ticket.status === "CLOSED"
                          : false
                        : false
                    }
                    onClick={() => {
                      if (ticketDetails?.ticket?.ticketType === "EXTERNAL") {
                        setIsExternalTicketClose(true);
                      } else {
                        updateTicketDetails({ status: "CLOSED" });
                      }
                    }}
                    text="Close ticket"
                  />
                </>
              )}
          </div>
        )}
      </div>
      {openModal && ticketDetails?.updateRights && (
        <CustomModal
          title="Resolve ticket"
          onClose={() => {
            setOpenModal(false);
            setMessage("");
          }}
        >
          <div className="resolve-msg">
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              size="small"
              multiline
              className="new-textfield"
              rows={4}
              placeholder="Resolve message"
            />

            <CommonBtn
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={() => {
                setMessage("");
                setOpenModal(false);
                updateTicketDetails({
                  status: "RESOLVED",
                  resolveMessage: message,
                });
              }}
              text={
                ticketDetails.status === "RESOLVED" ||
                ticketDetails.status === "CLOSED"
                  ? "Reopen ticket"
                  : "Resolve ticket"
              }
            />
          </div>
        </CustomModal>
      )}
      {isExternalTicketClose && (
        <CustomModal
          onClose={() => {
            setIsExternalTicketClose(false);
            setSelectedTagsIds([]);
          }}
          title="Add Tags"
          classesToPreventClosing={["MuiAutocomplete-option"]}
        >
          <AutoCompleteMultiSelect
            options={tagList}
            onChange={(e, val) => {
              if (val.length > 0) {
                const res = val.map((ele) => parseInt(ele.value));
                setSelectedTagsIds(res);
              }
            }}
            placeholder="Select Tags"
          />
          <CommonBtn
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
            sx={{ mt: 3 }}
            onClick={() => {
              if (selectedTagsIds.length > 0) {
                updateTicketDetails({
                  status: "CLOSED",
                  tags: selectedTagsIds,
                });
                setSelectedTagsIds([]);
                setIsExternalTicketClose(false);
              } else {
                updateTicketDetails({ status: "CLOSED" });
                setIsExternalTicketClose(false);
              }
            }}
            text="Close Ticket"
          />
        </CustomModal>
      )}
    </div>
  );
};

export default FormDetails;
