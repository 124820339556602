import React, { useState } from "react";
import { appUrlsAndImages } from "../../core/cards/config";
import CustomModal from "../../packages/common-utilities/core/modal";

const ViewAllowedApps = ({
  title,
  closeModal,
  data = [],
  customModalContentClass,
}) => {
  return (
    <CustomModal
      title={title}
      onClose={() => closeModal(null)}
      contentClassName={{
        headerBackgroundColor: "#1756f3",
        customClass: `${
          customModalContentClass ? customModalContentClass : ""
        }`,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {data.length > 0 ? (
          data.map((item, index) => {
            if (item?.app) {
              return <p>{item.app?.name}</p>;
            }
          })
        ) : (
          <p>No Apps Allowed</p>
        )}
      </div>
    </CustomModal>
  );
};

export default ViewAllowedApps;
