import {
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/invoice-tracking";

export const getVendorStateList = (setDataFunc, slug, token, addCity) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${INVOICE_TRACKING_HOSTNAME}${REST_URLS.VENDOR_LIST}`,
    null,
    {
      userVendors: true,
      mandatoryFields: JSON.stringify(["state"]),
    },
    null,
    slug,
    token
  )
    .then((res) => {
      const list =
        res?.map?.((item) => {
          const { state, city } = item;
          let label = state;
          if (addCity && city) {
            label = `${state} - ${city}`;
          }
          return {
            label,
            value: item.id,
            code: item.code,
            id: item.id,
          };
        }) || [];
      setDataFunc(list);
    })
    .catch((err) => {
      setDataFunc([]);
    });
};
