import CustomModal from "..";

export const listTypes = {
  ol: "Ordered List",
  ul: "Unordered List",
};
// contentClassName={{ headerBackgroundColor: "#1756f3" }}
export const ListModal = ({
  showModal,
  title,
  closeModal,
  data,
  listType = listTypes.ul,
}) => {
  return (
    <>
      {showModal && (
        <CustomModal title={title} onClose={closeModal}>
          {listType === listTypes.ul && (
            <ul>
              {data?.map?.((item, index) => {
                return <li key={index}>{typeof item !== "object" && item} </li>;
              })}
            </ul>
          )}
        </CustomModal>
      )}
    </>
  );
};
