import { toast } from "react-toastify";
import CustomModal from "../../../common-utilities/core/modal";
import { CF_APPROVAL_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { HTTP_METHODS } from "../../utils/http-service";
import { invokeApi } from "../../utils/http-service";
import { Button } from "@mui/material";
import CommonBtn from "../../../common-utilities/core/common-button";

export const DeleteModal = ({ showModal, data, closeModal, onSuccess }) => {
  const deleteLead = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${CF_APPROVAL_HOSTNAME}${REST_URLS.DELETE_APPROVAL}${data?.id}`
    )
      .then((res) => {
        if (res?.message) {
          toast.error(res?.message, { autoClose: 2000 });
          return;
        }

        toast.success("Approval delete successfully", { autoClose: 2000 });
        onSuccess && onSuccess();
        closeModal();
      })
      .catch((err) => {
        toast.error(err?.message, { autoClose: 2000 });
      });
  };
  return (
    <>
      {showModal && (
        <CustomModal title="Delete Appproval" onClose={closeModal}>
          <div className="delete-approval-modal">
            <div>Are you sure ?</div>
            <div>Do you want to delete</div>
            <div className="btn-container">
              <CommonBtn
                variant="outlined"
                size="medium"
                onClick={deleteLead}
                text="Yes"
              />
              <CommonBtn
                variant="contained"
                size="medium"
                color="error"
                onClick={closeModal}
                text="No"
              />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
