import React, { useState, useEffect } from "react";
import "./index.scss";
import LOGO from "../../resources/logo.png";
import { Drawer, Grid, Typography } from "@mui/material";
import ShowFullBlog from "./showFullBlog";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const BulletinComponent = (props) => {
  const [data, setData] = useState([]);
  const [blogFlag, setBlogFlag] = useState(false);
  const [blog, setBlog] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const blogCard = (blogData) => {
    const encodeBody = decodeURIComponent(blogData.body);
    const bodyText = document.createElement("div");
    bodyText.innerHTML = encodeBody;
    return (
      <div className="blog-card">
        <div className="creation-info">
          <Typography className="title">
            {`${blogData?.title} | ${moment(blogData?.publishedDate).format(
              "DD. MM. YYYY"
            )}`}
          </Typography>
        </div>

        <div className="blog-details">
          <Typography className="body">
            {blogData?.summary?.length > 180
              ? blogData.summary.slice(0, 180) + "..."
              : blogData?.summary}
          </Typography>
        </div>

        {/* <div className="read-more-con">
          <Typography
            className="read-more"
            onClick={() => {
              setBlog(blogData.body);
              setBlogFlag(true);
            }}
          >
            READ FULL BLOG
          </Typography>
        </div> */}
      </div>
    );
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (startIndex < data.length - 3) {
      setStartIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div>
      <div className="all-bulletin-con">
        <Grid
          container
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "-webkit-fill-available",
          }}
        >
          <Grid
            item
            md={1.5}
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRight: "2px solid green",
              height: "80%",
              alignItems: "center",
            }}
          >
            <ArrowBackIosIcon
              onClick={handlePrev}
              fontSize="large"
              sx={{ cursor: "pointer", color: startIndex > 0 && "#1d49ae" }}
              disabled={startIndex > 0}
            />
          </Grid>

          {data.slice(startIndex, startIndex + 3).map((blog, index) => {
            return (
              <Grid
                md={3}
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRight: "2px solid #1d49ae",
                  height: "80%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setBlog(blog.body);
                  setAttachment(blog?.attachment);
                  setBlogFlag(true);
                }}
              >
                <div key={index}>{blogCard(blog)}</div>
              </Grid>
            );
          })}
          <Grid
            md={1.5}
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "80%",
              alignItems: "center",
            }}
          >
            {startIndex < data.length - 3 && (
              <ArrowForwardIosIcon
                onClick={handleNext}
                fontSize="large"
                sx={{ cursor: "pointer", color: "#1d49ae" }}
              />
            )}
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          open={blogFlag}
          onClose={() => setBlogFlag(false)}
        >
          <ShowFullBlog blogData={blog} files={attachment} />
        </Drawer>
      </div>
    </div>
  );
};

export default BulletinComponent;
