import "./index.scss";
import { useState, useEffect, useCallback } from "react";
import { Button, Box, TextField, Tabs, Tab } from "@mui/material";

import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, INTRANETHOSTNAME, REST_URLS } from "../../utils/endpoints";

import { getLabelsAndValuesForSelect } from "../../utils";
import { TabPanel, a11yProps } from "../../../common-utilities/core/tabs";
import ManagerUsersTable from "./manage-users";
import ManageDistributionCenterTable from "./manage-distribution-centers";
import ManageSupplierPlantTable from "./manage-supplier-plants";
import SearchIcon from "@mui/icons-material/Search";
import CreateEditUserModal from "./user-modal";
import { getSelectDcList } from "../../utils/dc";
import { getSelectVendorList } from "../../utils/vendor";
import { checkForForUserSelectedTab } from "./user-modal-utils-2";
import { pick } from "../../../common-utilities/utils";
import { QNET_HOSTNAME } from "../../../common-utilities/utils/end-points";

const Users = () => {
  const [users, setUsers] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [modelDetails, setModalDetails] = useState({
    mainTitle: "",
    buttonTitle: "",
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 5,
    sortBy: "-createdAt",
    name: "",
  });
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userDetails, setuserDetails] = useState({});
  const [dcCumVendorCheckbox, setDcCumVendorCheckbox] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [dcList, setDCList] = useState([]);

  const [filtersAfterClickingOnApply, setFiltersAfterClickingOnApply] =
    useState({
      name: "",
    });

  const [value, setValue] = useState(0);

  const setModalTitleAndButtonTitle = useCallback(() => {
    if (addUser || editUser) {
      let buttonTitle = "Create";
      let mainTitle = "";

      if (addUser) {
        switch (value) {
          case 0:
            mainTitle = "Create User";
            setDcCumVendorCheckbox(false);
            break;
          case 1:
            mainTitle = "Create Distribution Center";
            setDcCumVendorCheckbox(true);
            break;
          case 2:
            mainTitle = "Create Supplier Plant";
            setDcCumVendorCheckbox(false);
            break;
          default:
            break;
        }
      }

      if (editUser) {
        buttonTitle = "Save";
        switch (value) {
          case 0:
            mainTitle = "Edit User";
            setDcCumVendorCheckbox(false);
            break;
          case 1:
            mainTitle = "Edit Distribution Center";
            setDcCumVendorCheckbox(true);
            break;
          case 2:
            mainTitle = "Edit Supplier Plant";
            setDcCumVendorCheckbox(false);
            break;
          default:
            break;
        }
      }
      setModalDetails({ mainTitle, buttonTitle });
    }
  }, [addUser, editUser, value]);

  useEffect(() => {
    setModalTitleAndButtonTitle();
  }, [addUser, editUser, value]);

  useEffect(() => {
    const isFirstTab = value === 0;
    if (isFirstTab) {
      (async () => {
        const dc = await getSelectDcList();
        setDCList(dc);
        const vendor = await getSelectVendorList();
        setVendorList(vendor);
      })();
    }
  }, [value]);

  const loadData = useCallback(
    (filter, userPrevFilters, value) => {
      const isFilterPresent = typeof filter === "object" && filter;
      const userFilter = isFilterPresent ? { ...filter } : { ...filters };
      const params = { ...userFilter };
      if (!userFilter.limit) {
        userFilter.limit = filters.limit;
      }
      if (!userFilter.page) {
        userFilter.page = filters.page;
      }
      if (!userFilter.sortBy) {
        userFilter.sortBy = filters.sortBy;
      }
      if (!userFilter.name) {
        userFilter.name = filters.name;
      }
      if (userPrevFilters) {
        params.name = filtersAfterClickingOnApply.name;
      }
      let url = `${QNET_HOSTNAME}${REST_URLS.USERS}`;
      switch (value) {
        case 0:
          break;
        case 1:
          url = `${INTRANETHOSTNAME}${REST_URLS.QUERY_DCS}`;
          break;
        default:
          url = `${INTRANETHOSTNAME}${REST_URLS.QUERY_VENDORS}`;
          break;
      }
      invokeApi(HTTP_METHODS.GET, `${url}`, null, params)
        .then((res) => {
          if (!res?.message) {
            setUsers(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [filters, value]
  );

  const handleChange = (_, newValue) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
    loadData({ ...filters, page: 1 }, true, newValue);
    setValue(newValue);
  };

  const getAllUserRoles = () => {
    invokeApi(HTTP_METHODS.GET, `${QNET_HOSTNAME}${REST_URLS.LIST_ROLES}`, null)
      .then((res) => {
        if (res?.message) {
          return;
        }
        const labels = [];
        const values = [];
        const additionalDetails = [];
        res?.forEach((item) => {
          const { type, belongsTo, id, roleName } = item;
          labels.push(roleName);
          values.push(id);
          additionalDetails.push({ type, belongsTo });
        });

        const results = getLabelsAndValuesForSelect(
          labels,
          values,
          "value",
          false,
          false,
          additionalDetails
        );
        setUserRoles(results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUserRoles();
  }, []);

  useEffect(() => {
    loadData(filters, null, 0);
  }, []);

  const closeCreateModal = () => {
    setuserDetails({});
    setAddUser(false);
    setEditUser(false);
    setDcCumVendorCheckbox(false);
  };

  const userTabs = [
    "Manage Users",
    "Manage Distribution Centers",
    "Manage Supplier Plants",
  ];

  const setEditModalData = (data) => {
    if (data) {
      const { name, email, role = {}, id, vendor, dc, distributors } = data;

      let allUserDetails = { name, email, id };

      const userSelectTab = checkForForUserSelectedTab(value);
      if (userSelectTab.allUsersFirstTab) {
        if (dc) {
          allUserDetails.dc = { label: dc.name, value: dc.id };
        }
        if (vendor) {
          allUserDetails.vendor = { label: vendor.name, value: vendor.id };
        }
        if (role) {
          const { type, belongsTo, roleName, id } = role;
          allUserDetails.role = { label: roleName, value: id, type, belongsTo };
        }
        if (distributors) {
          allUserDetails.distributors = distributors.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        }
      }
      const additionProperties = pick(data, [
        "dcCode",
        "city",
        "phone",
        "address",
        "isVegetableVendor",
        "dcCumVendor",
        "plantLocation",
        "manufacturerNumber",
        "splCode",
      ]);
      allUserDetails = { ...allUserDetails, ...additionProperties };
      if (userDetails) setuserDetails(allUserDetails);
      setEditUser(true);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {userTabs.map((item, index) => {
            return <Tab key={index} label={item} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      <div className="user-container">
        <div className="button-div">
          <div className="search-field">
            <TextField
              placeholder="Search by user name"
              variant="outlined"
              color="primary"
              size="small"
              value={filters.name}
              onChange={(event) => {
                event.persist();
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  name: event.target.value,
                }));
              }}
            />
            <SearchIcon
              className="main-search-icon"
              onClick={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                }));
                setFiltersAfterClickingOnApply((prevFilters) => ({
                  ...prevFilters,
                  name: filters.name,
                }));
                loadData({ ...filters, page: 1 }, false, value);
              }}
            />
          </div>
          <div className="button-container">
            <div>
              <Button
                size="medium"
                variant="contained"
                className="button"
                onClick={() => {
                  setAddUser(true);
                }}
              >
                {value === 0
                  ? "Create User"
                  : value === 1
                  ? "Create Distribution Center"
                  : "Create Supplier Plant"}
              </Button>
            </div>
          </div>
        </div>
        <TabPanel value={value} index={0}>
          <ManagerUsersTable
            filters={filters}
            users={users}
            getData={loadData}
            loadData={setEditModalData}
            userRoles={userRoles}
            setFilters={setFilters}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ManageDistributionCenterTable
            filters={filters}
            users={users}
            getData={loadData}
            loadData={setEditModalData}
            setFilters={setFilters}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ManageSupplierPlantTable
            filters={filters}
            users={users}
            getData={loadData}
            loadData={setEditModalData}
            setFilters={setFilters}
          />
        </TabPanel>
        <CreateEditUserModal
          addUser={addUser}
          editUser={editUser}
          modelDetails={modelDetails}
          closeCreateModal={closeCreateModal}
          value={value}
          userDetails={userDetails}
          setuserDetails={setuserDetails}
          loadData={loadData}
          dcCumVendorCheckbox={dcCumVendorCheckbox}
          setDcCumVendorCheckbox={setDcCumVendorCheckbox}
          vendorList={vendorList}
          dcList={dcList}
          userRoles={userRoles}
          filters={filters}
        />
      </div>
    </Box>
  );
};

export default Users;
