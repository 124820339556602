import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AdjustIcon from "@mui/icons-material/Adjust";
import Typography from "@mui/material/Typography";
import { convertToCamelCaseFromUnderScore } from "../../utils";
import {
  basicUpdateKeysToLabels,
  createCaseLabels,
  statusUpdateLabels,
} from "../../utils/keys-to-labels";
import { NestedTimeLineDetailsComponent } from "./nested-time-line-details";

const CaseTimeLine = ({ caseTimeLines, caseDetails }) => {
  const getStageDetails = (timeLine) => {
    const {
      stageDetails = "",
      stage = "",
      comment,
      attachments = [],
    } = timeLine;
    let stageObj = {};

    try {
      stageObj = JSON.parse(stageDetails);
    } catch (err) {
      stageObj = {};
    }
    const { reason, details = {} } = stageObj;
    switch (stage) {
      case "ATTACHMENT":
        if (attachments?.length > 0) {
          return (
            <>
              <Typography variant="h6" component="span">
                {reason}
              </Typography>

              <Typography>
                Uploaded By : {stageObj?.details?.uploadedBy}
              </Typography>

              <div>
                {attachments &&
                  attachments.map((item, index) => {
                    return (
                      <div>
                        <a key={index} href={item.url} download target="_blank">
                          {item.title}
                        </a>
                      </div>
                    );
                  })}
              </div>
            </>
          );
        } else {
          return <Typography>No Document attached</Typography>;
        }
      case "COMMENT":
        return (
          <>
            <Typography variant="h6" component="span">
              {convertToCamelCaseFromUnderScore(reason)}
            </Typography>
            <Typography>{comment}</Typography>
          </>
        );
      case "Severity Update":
        return (
          <>
            <Typography variant="h6" component="span">
              {timeLine.description}
            </Typography>
            <Typography>Updated by : {details["Updated By"]}</Typography>
            <Typography>Severity : {details.severity}</Typography>
          </>
        );
      case "UPDATE":
        return getUpdateStageTimeline(stageObj, timeLine, caseDetails);
      case "Status Update":
        return (
          <>
            <Typography variant="h6" component="span">
              Status Update
            </Typography>
            <Typography>{timeLine?.description}</Typography>
            <NestedTimeLineDetailsComponent
              details={stageObj.details || {}}
              detailLabels={statusUpdateLabels || {}}
              caseDetails={caseDetails}
            />
          </>
        );

      case "Vendor Reassigned":
        return (
          <>
            <Typography>{timeLine?.description}</Typography>
            <NestedTimeLineDetailsComponent
              details={stageObj.details || {}}
              detailLabels={statusUpdateLabels || {}}
              caseDetails={caseDetails}
            />
          </>
        );
      case "CREATE":
        return (
          <>
            <Typography variant="h6" component="span">
              {timeLine.description}
            </Typography>
            <NestedTimeLineDetailsComponent
              details={stageObj.details || {}}
              detailLabels={createCaseLabels || {}}
              caseDetails={caseDetails}
            />
          </>
        );
      case "Pending Research":
        return (
          <>
            <Typography variant="h6" component="span">
              {timeLine.description}
            </Typography>
            <Typography>Created By : {stageObj?.details?.createdBy}</Typography>
            <Typography>Notes : {stageObj?.details?.notes}</Typography>
          </>
        );
      case "Credit Confirmation":
        return (
          <>
            <Typography variant="h6" component="span">
              {timeLine.description}
            </Typography>
          </>
        );
      default:
        return (
          <>
            <Typography variant="h6" component="span">
              {timeLine.description}
            </Typography>
            <Typography>{timeLine.description}</Typography>
          </>
        );
    }
  };

  const getUpdateStageTimeline = (stageObj, timeLine, caseDetails) => {
    const { details = {} } = stageObj;
    return (
      <>
        <Typography variant="h6" component="span">
          {timeLine.description}
        </Typography>
        <NestedTimeLineDetailsComponent
          details={details || {}}
          detailLabels={basicUpdateKeysToLabels || {}}
          caseDetails={caseDetails}
        />
      </>
    );
  };

  const getTimeLineIcon = (timeLine) => {
    const { stage = "" } = timeLine;

    switch (stage) {
      case "ATTACHMENT":
        return <AttachFileIcon />;
      case "UPDATE":
        return <AdjustIcon />;
      default:
        return <AddCircleIcon />;
    }
  };

  return (
    <div className="case-time-line-container">
      <Timeline position="alternate">
        {caseTimeLines.map((timeLine) => {
          return (
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {timeLine?.createdAt
                  ? new Date(timeLine.createdAt).toLocaleString()
                  : "No Created Info"}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                  {getTimeLineIcon(timeLine)}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                {getStageDetails(timeLine)}
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
};

export default CaseTimeLine;
