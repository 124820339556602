import ActionIcons from "../actionIcons";
import { userTypes } from "../../utils/constants";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";

import Checkbox from "@mui/material/Checkbox";
import { tokenMainKeys } from "../../../../utils/constants";
export const getHeaderConfig = (loadData, departmentList) => {
  const headerConfig = [
    {
      label: "Name",
      id: "username",
    },
    {
      label: "Email",
      id: "useremail",
    },
    {
      label: "Type",
      id: "usertype",
    },
    {
      label: "On Call",
      id: "onCall",
      render: (data) => {
        return (
          <div>
            <div className="si-cell si-hide-mobile">
              <Checkbox
                checked={data.onCall === "true" ? true : false}
              ></Checkbox>
            </div>
            <div className="si-cell-mobile si-hide-web">
              <Checkbox
                checked={data.onCall === "true" ? true : false}
              ></Checkbox>
            </div>
          </div>
        );
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        return (
          <div className="user-action-icon">
            <ActionIcons
              type={data.type}
              loadData={loadData}
              departmentList={departmentList}
              id={data.id}
              name={data.name}
              email={data.email}
              onCall={data.onCall}
              modalType="USER"
              dept={data?.dept?.name}
              deptId={data.dept?.id}
            />
          </div>
        );
      },
    },
  ];

  let type = userTypes.STORE_OWNER;
  const usercredentails = getOfflineData(tokenMainKeys.technet, "user");
  if (usercredentails && usercredentails.type) {
    type = usercredentails.type;
  }
  if (type === userTypes.STORE_OWNER) {
    headerConfig.pop();
  }
  return headerConfig;
};
export const getMobileHeaderConfig = () => [
  {
    label: "Name",
    id: "username",
  },
  {
    label: "Email",
    id: "useremail",
  },
];
export const selectType = [
  { label: "Tech Net User", value: "TECH_NET_USER" },
  { label: "Store Owner", value: "STORE_OWNER" },
  { label: "Admin", value: "ADMIN" },
  {
    label: "Agent",
    value: "AGENT",
  },
];

export const convertedTypesToRequired = {
  "Store Owner": "STORE_OWNER",
  Admin: "ADMIN",
  "Tech Net User": "TECH_NET_USER",
  Agent: "AGENT",
};
