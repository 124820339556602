/** @format */
import React, { useState, useEffect } from "react";
import "./index.scss";
import SiTable from "../../../common-utilities/core/table";
import { useNavigate } from "react-router-dom";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { convertedTypes } from "../../utils/constants";
import { getHeaderConfig, getMobileHeaderConfig } from "./config";
import { invokeApi } from "../../utils/http-service";
import { SERVICE_REQUEST_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { tokenMainKeys } from "../../../../utils/constants";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";

const Users = () => {
  const [users, setUsers] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  const [departmentList, setDepartmentList] = useState([]);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const usercredentails = getOfflineData(tokenMainKeys.technet, "user");
    if (!usercredentails) {
      navigate("/");
    } else {
      loadData(filters);
    }
  }, [navigate, filters]);

  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`
    )
      .then((response) => {
        if (response) {
          response = response.map((dept) => ({
            label: dept.name,
            value: dept.id,
          }));
        }
        setDepartmentList(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const loadData = (params) => {
    const allFilters = params ? { ...params } : { ...filters };

    invokeApi(
      HTTP_METHODS.GET,
      `${SERVICE_REQUEST_HOSTNAME}${REST_URLS.GET_USERS}`,
      null,
      allFilters
    )
      .then((response) => {
        if (response) {
          const maxLen = 20;
          const results = response.results.map((ele) => {
            let type = null;
            if (ele.type) {
              type = convertedTypes[ele.type];
            }
            return {
              ...ele,
              onCall: ele.onCall ? "true" : "false",
              usertype: type,
              useremail:
                ele.email.length > maxLen
                  ? `${ele.email.substring(0, maxLen)}...`
                  : ele.email,
              username:
                ele?.name.length > maxLen
                  ? `${ele?.name.substring(0, maxLen)}...`
                  : ele.name,
            };
          });
          setUsers({ ...response, results });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="user-container">
        <div className="button-div">
          <div className="search-field">
            <TextField
              fullWidth
              sx={{ my: 1 }}
              label="Search by name"
              className="new-textfield"
              onChange={(e) => setUserName(e?.target?.value)}
              value={userName}
              size="small"
            />

            <SearchIcon
              className="main-search-icon"
              onClick={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                  userName,
                }));
              }}
            />
          </div>
        </div>

        <MuiTable
          columnsList={getHeaderConfig(loadData, departmentList)}
          dataList={users.results || []}
          filters={filters}
          pageCount={users.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            loadData({
              ...filters,
              page,
            });
          }}
        ></MuiTable>
        {/* <SiTable
            header={getHeaderConfig(loadData, departmentList)}
            data={users.results || []}
            pageCount={users.totalPages}
            filters={filters}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable> */}
      </div>
    </>
  );
};

export default Users;
