import { useState, useEffect } from "react";
import "./index.scss";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { checkOverall, showTable, startDashboard } from "./utils";
import DashboardFilters from "./sub-components/filters";
import { SelectFilters } from "./sub-components/select-filters";
import MuiTable from "../../../common-utilities/core/mui-table";
import { storeTypes } from "../../utils/constants";
import { cocoBuiltConfig } from "./config/coco-built";
import { setProperty } from "../../../common-utilities/utils/misc";
import { getData } from "./apis";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import { cocoAcquiredConfig } from "./config/coco-acquired";
import FileUpload from "../../../common-utilities/core/file-uploader";
import { CF_APPROVAL_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { getOfflineData } from "../../utils/offline-services";

const FinancialDashboard = () => {
  const [tableData, setTableData] = useState({});
  const user = getOfflineData("user");
  const [selectVals, setSelectVals] = useState({
    state: [],
    city: [],
  });

  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
    overall: true,
  });

  useEffect(() => {
    startDashboard(setSelectVals);
  }, []);

  useEffect(() => {
    const showTableData = showTable(filters);
    setProperty("showData", showTableData, setFilters);
  }, [filters?.storeType, filters?.cluster, filters?.storeDetails]);

  useEffect(() => {
    if (!filters?.showData) {
      return;
    }
    getData({ ...filters, page: 1 }, setTableData);
    setProperty("page", 1, setFilters);
  }, [
    filters?.showData,
    filters?.storeType,
    filters?.cluster,
    filters?.state,
    filters?.city,
    filters?.restaurantId,
  ]);

  return (
    <div className="cf-approval-financial-dashboard">
      <div className="filters">
        <DashboardFilters
          filters={filters}
          setFilters={setFilters}
          selectVals={selectVals}
          setSelectVals={setSelectVals}
        />
        <SelectFilters filters={filters} setFilters={setFilters} />
        <div className="end-filter">
          {filters?.storeType &&
            user?.properties?.cfApproval?.uploadFlatSheetRights && (
              <FileUpload
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                url={`${CF_APPROVAL_HOSTNAME}${REST_URLS.UPLOAD_FLATSHEET}${filters?.storeType}`}
                callBack={() => getData(filters, setTableData)}
                uploadText="Upload FlatSheet"
              />
            )}

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checkOverall(filters)} />}
              label="Overall"
            />
          </FormGroup>
          {tableData?.totalResults && filters?.showData && (
            <DownloadBtn
              onClick={() => {
                getData(
                  { ...filters, limit: tableData?.totalResults, page: 1 },
                  null,
                  true
                );
              }}
            />
          )}
        </div>
      </div>
      {filters?.showData && (
        <MuiTable
          columnsList={
            filters?.storeType === storeTypes.cocoBuild
              ? cocoBuiltConfig(filters)
              : cocoAcquiredConfig(filters)
          }
          dataList={tableData?.results || []}
          filters={filters}
          pageCount={tableData?.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            getData({ ...filters, page }, setTableData);
          }}
        />
      )}
    </div>
  );
};

export default FinancialDashboard;
