import React, { useState, useEffect } from "react";
import "./index.scss";
import CustomizedTimeline from "../resturants-uploads/time-line";
import { invokeApi, HTTP_METHODS, plainApi } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/docu-storage";
import DateRangePicker from "rsuite/DateRangePicker";

import {
  validDocumentTypes,
  StoreLabels,
  DOCUMENT_TYPES_ON_BASIS_OF_STORE_TYPE,
  validLicenseTypesOnTheBasisOfStoreAndDocumentType,
  validPreAgreementTypesOnTheBasisOfStoreAndDocumentType,
  validAgreementAndPostAgreementDocsTypesOnTheBasisOfStoreAndDocumentType,
} from "../../utils/document-constants";
import { toast } from "react-toastify";
import { DOCU_STORAGE_HOSTNAME } from "../../../common-utilities/utils/end-points";
import {
  getLabelsAndValuesForSelect,
  exportToExcel,
} from "../../../common-utilities/utils";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import CommonBtn from "../../../common-utilities/core/common-button";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";

const ChronologyDetails = ({ id, pageArray, setPageArray }) => {
  const [allDocumentTypes, setAllDocumentTypes] = useState([]);
  const [timeLineData, setTimeLineData] = useState([]);
  const [selectListForLicenseType, setselectListForLicenseType] = useState([]);
  const [selectListForPreAgreementDocs, setSelectListForPreAgreementDocs] =
    useState([]);
  const [selectListForPostAgreementDocs, setSelectListForPostAgreementDocs] =
    useState([]);
  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    storeType: { label: "Select store type", value: null },
    licenseType: { label: "Select License Type", value: null },
    preAgreementDocsType: { label: "Select Pre Agreement Type", value: null },
    agreementAndPostDocsType: {
      label: "Select Agreement And Post Agreement Type",
      value: null,
    },
  });

  useEffect(() => {
    if (filters.storeType && filters.storeType.value) {
      const allTypes =
        DOCUMENT_TYPES_ON_BASIS_OF_STORE_TYPE[filters.storeType.value];
      const documentLabelsAndValues = getLabelsAndValuesForSelect(
        allTypes,
        allTypes
      );
      setAllDocumentTypes(documentLabelsAndValues);
    } else {
      setAllDocumentTypes([]);
    }
  }, [filters.storeType]);

  useEffect(() => {
    if (
      filters.storeType &&
      filters.storeType.value &&
      filters.type &&
      filters.type.value
    ) {
      const allLicenseValues =
        validLicenseTypesOnTheBasisOfStoreAndDocumentType?.[
          filters.storeType.value
        ]?.[filters.type.value];

      const allPreAgreementDocuments =
        validPreAgreementTypesOnTheBasisOfStoreAndDocumentType?.[
          filters.storeType.value
        ]?.[filters.type.value];
      const allPostAgreementDocuments =
        validAgreementAndPostAgreementDocsTypesOnTheBasisOfStoreAndDocumentType?.[
          filters.storeType.value
        ]?.[filters.type.value];
      if (allLicenseValues) {
        const allLabels = getLabelsAndValuesForSelect(
          allLicenseValues,
          allLicenseValues
        );
        setselectListForLicenseType(allLabels);
      } else {
        setselectListForLicenseType([]);
      }
      if (allPreAgreementDocuments) {
        const allLabels = getLabelsAndValuesForSelect(
          allPreAgreementDocuments,
          allPreAgreementDocuments
        );

        setSelectListForPreAgreementDocs(allLabels);
      } else {
        setSelectListForPreAgreementDocs([]);
      }
      if (allPostAgreementDocuments) {
        const allLabels = getLabelsAndValuesForSelect(
          allPostAgreementDocuments,
          allPostAgreementDocuments
        );
        setSelectListForPostAgreementDocs(allLabels);
      } else {
        setSelectListForPostAgreementDocs([]);
      }
    } else {
      setselectListForLicenseType([]);
    }
  }, [filters.storeType, filters.type]);
  useEffect(() => {
    if (id) {
      setFilters((prevVal) => ({
        ...prevVal,
        storeType: { label: "Select store type", value: null },
        licenseType: { label: "Select License Type", value: null },
        preAgreementDocsType: {
          label: "Select Pre Agreement Type",
          value: null,
        },
        agreementAndPostDocsType: {
          label: "Select Agreement And Post Agreement Type",
          value: null,
        },
        startDate: null,
        endDate: null,
        type: null,
      }));
    }
  }, [id]);
  const [hasMore, setHasMore] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [pages, setPages] = React.useState(1);
  const loadData = (pg, downloadReport) => {
    let params = {
      page: pg,
      limit: 50,
      store: id,
      sortBy: filters.sortBy,
    };
    if (filters.type) {
      params.type = filters.type.value;
    }
    if (filters.startDate) {
      params.startDate = filters.startDate;
    }
    if (filters.endDate) {
      params.endDate = filters.endDate;
    }

    if (filters.licenseType.value) {
      params.licenseType = filters.licenseType.value;
    }

    if (filters.storeType.value) {
      params.storeType = filters.storeType.value;
    }
    if (filters.preAgreementDocsType.value) {
      params.documentType = filters.preAgreementDocsType.value;
    }
    if (filters.agreementAndPostDocsType.value) {
      params.documentType = filters.agreementAndPostDocsType.value;
    }
    addCorrectStartEndDate(params);
    if (downloadReport) {
      params.page = 1;
      params.limit = totalResults;
      params.download = true;
      invokeApi(
        HTTP_METHODS.GET,
        `${DOCU_STORAGE_HOSTNAME}${REST_URLS.DOCS_FETCH}`,
        null,
        params
      )
        .then((response) => {
          if (response?.message) {
            toast.error(response?.message, { autoClose: 2000 });
            return;
          }
          if (response?.results?.length === 0) {
            toast.error("No documents are currently uploaded", {
              autoClose: 2000,
            });
            return;
          }
          exportToExcel(response?.results || [], "Reports", true);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    if (!pageArray.includes(pg)) {
      plainApi(
        HTTP_METHODS.GET,
        `${DOCU_STORAGE_HOSTNAME}${REST_URLS.DOCS_FETCH}`,
        null,
        params
      ).then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        setTotalResults(response?.totalResults);
        setTotalPages(response.totalPages + 1);
        if (pg == 1) {
          setTimeLineData(response.results);
          setPageArray([1]);
        } else {
          setTimeLineData([...timeLineData, ...response.results]);
        }
        let array = [...pageArray];
        array.push(pg);
        setPageArray(array);
      });
    }
  };

  useEffect(() => {
    if (totalPages == null) {
      loadData(pages);
    } else {
      if (pages < totalPages) {
        setHasMore(true);
        loadData(pages);
      } else {
        setHasMore(false);
      }
    }
  }, [refresh, id]);
  useEffect(() => {
    setPageArray([]);
    setPages(1);
    setHasMore(true);
    loadData(1);
  }, [filters, id]);

  return (
    <div>
      <div className="back-container">
        <div className="filters-row">
          <div style={{ zIndex: 0 }}>
            <DateRangePicker
              value={[
                filters.startDate ? new Date(filters.startDate) : null,
                filters.endDate ? new Date(filters.endDate) : null,
              ]}
              onOk={(dates) => {
                setFilters({
                  ...filters,
                  startDate: dates[0].toISOString(),
                  endDate: dates[1].toISOString(),
                });
                setPageArray([]);
              }}
              onClean={() => {
                setFilters({
                  ...filters,
                  startDate: null,
                  endDate: null,
                });
              }}
            />
          </div>

          <AutocompleteComponent
            label="Store Type"
            options={StoreLabels}
            value={filters.storeType?.value ? filters.storeType : null}
            className="doc-type"
            onChange={(e, event) => {
              if (!event) {
                setFilters({
                  ...filters,
                  storeType: { label: "Select Store Type", value: null },
                  licenseType: { label: "Select License Type", value: null },
                  preAgreementDocsType: {
                    label: "Select Pre Agreement Type",
                    value: null,
                  },
                  agreementAndPostDocsType: {
                    label: "Select Agreement And Post Agreement Type",
                    value: null,
                  },
                  type: null,
                });
                setPageArray([]);
                return;
              }
              setPageArray([]);
              setFilters({
                ...filters,
                storeType: { label: event?.label, value: event?.value },
                licenseType: { label: "Select License Type", value: null },
                preAgreementDocsType: {
                  label: "Select Pre Agreement Type",
                  value: null,
                },
                agreementAndPostDocsType: {
                  label: "Select Agreement And Post Agreement Type",
                  value: null,
                },
                type: null,
              });
            }}
          />
          <AutocompleteComponent
            label="Document Type"
            options={allDocumentTypes}
            value={filters?.type?.value ? filters.type : null}
            className="doc-type"
            onChange={(e, value) => {
              setPageArray([]);
              setFilters({
                agreementAndPostDocsType: {
                  label: "Select Agreement And Post Agreement Type",
                  value: null,
                },
                preAgreementDocsType: {
                  label: "Select Pre Agreement Type",
                  value: null,
                },
                ...filters,
                type: value,
                licenseType: { label: "Select License Type", value: null },
              });
            }}
          />

          {filters.type &&
            filters.type.value === validDocumentTypes.license && (
              <AutocompleteComponent
                label="License Type"
                options={selectListForLicenseType}
                value={filters.licenseType?.value ? filters.licenseType : null}
                className="doc-type"
                onChange={(e, event) => {
                  if (!event) {
                    setFilters({
                      ...filters,
                      licenseType: {
                        label: "Select License Type",
                        value: null,
                      },
                    });
                    setPageArray([]);
                    return;
                  }
                  setPageArray([]);
                  setFilters({
                    ...filters,
                    licenseType: { label: event.label, value: event.value },
                  });
                }}
              />
            )}
          {filters.type &&
            filters.type.value === validDocumentTypes.preAgreementDocs && (
              <AutocompleteComponent
                label="Type"
                options={selectListForPreAgreementDocs}
                value={
                  filters.preAgreementDocsType?.value
                    ? filters.preAgreementDocsType
                    : null
                }
                className="doc-type"
                onChange={(e, event) => {
                  if (!event) {
                    setFilters({
                      ...filters,
                      preAgreementDocsType: {
                        label: "Select Pre Agreement Type",
                        value: null,
                      },
                    });
                    setPageArray([]);
                    return;
                  }
                  setPageArray([]);
                  setFilters({
                    ...filters,
                    preAgreementDocsType: {
                      label: event?.label,
                      value: event?.value,
                    },
                  });
                }}
              />
            )}
          {filters.type &&
            filters.type.value ===
              validDocumentTypes.agreementAndPostAgreementDocs && (
              <AutocompleteComponent
                label="Agreement And Post Agreement Type"
                options={selectListForPostAgreementDocs}
                value={
                  filters.agreementAndPostDocsType?.value
                    ? filters.agreementAndPostDocsType
                    : null
                }
                className="doc-type"
                onChange={(e, event) => {
                  if (!event) {
                    setFilters({
                      ...filters,
                      agreementAndPostDocsType: {
                        label: "Select Agreement And Post Agreement Type",
                        value: null,
                      },
                    });
                    setPageArray([]);
                    return;
                  }
                  setPageArray([]);
                  setFilters({
                    ...filters,
                    agreementAndPostDocsType: {
                      label: event?.label,
                      value: event?.value,
                    },
                  });
                }}
              />
            )}
          {filters.type && timeLineData && timeLineData.length > 0 && (
            <CommonBtn
              variant="contained"
              onClick={() => {
                loadData(pages, true);
              }}
            >
              Download records
            </CommonBtn>
          )}
        </div>
      </div>
      <div className="time-line-container">
        <CustomizedTimeline
          data={timeLineData}
          setPages={setPages}
          setRefresh={setRefresh}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};
export default ChronologyDetails;
